import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { ONBOARDING_ME } from "../../api/graphql";
import { Product, flattenObject } from "../../common/common";
import ContentLoader from "../Common/ContentLoader";
import { useSageLazyQuery } from "../Common/GqlHooks";
import NetworkErrorPage from "../Common/NetworkErrorPage";

export const OnboardingStoreContext = React.createContext({});

const OnboardingStore = ({ children }) => {
  const [state, setState] = useState({
    steps: [
      {
        title: "accCreation",
        steps: [
          {
            id: 1,
            title: "intro",
          },
          {
            id: 2,
            title: "pInfo",
          },
          {
            id: 3,
            title: "homeTaxes",
          },
        ],
      },
      {
        title: "invProfile",
        steps: [
          {
            id: 4,
            title: "yFinSit",
          },
          {
            id: 5,
            title: "invAmount",
          },
        ],
      },
      {
        title: "inv",
        steps: [
          {
            id: 6,
            title: "undInv",
          },
          {
            id: 7,
            title: "costsBD",
          },
        ],
      },
      {
        title: "finSteps",
        steps: [
          {
            id: 8,
            title: "bankAcc",
          },
          {
            id: 9,
            title: "TAC",
          },
          {
            id: 10,
            title: "IDVer",
          },
        ],
      },
    ],
    currentStep: 1,
    product: Product.STABILITY,
  });

  const [getOnboardingProgress, loading, data, refetch] =
    useSageLazyQuery(ONBOARDING_ME);

  useEffect(() => getOnboardingProgress(), [getOnboardingProgress]);

  useEffect(() => {
    if (!data) return;

    setState((s) => ({
      ...s,
      product: data.me.client.investment_plan.product,
      me: data.me,
    }));
  }, [data]);

  const setCurrentStep = (step) => {
    setState((s) => ({
      ...s,
      currentStep: step,
    }));
  };

  const next = () => {
    mixpanel.identify(state.me.user_id);
    mixpanel.people.set(flattenObject(state.me.client));
    setState((s) => ({
      ...s,
      currentStep: s.currentStep + 1,
    }));
  };

  const back = () => {
    setState((s) => ({
      ...s,
      currentStep: s.currentStep > 1 ? s.currentStep - 1 : s.currentStep,
    }));
  };

  return (
    <OnboardingStoreContext.Provider
      value={{
        ...state,
        setCurrentStep,
        next,
        back,
        refresh: refetch,
      }}
    >
      <ContentLoader isInline={false} loading={loading} />
      {state.me && children}
      {!data && !loading && <NetworkErrorPage />}
    </OnboardingStoreContext.Provider>
  );
};

export default OnboardingStore;
