import { Button, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { useState } from "react";
import mixpanel from "mixpanel-browser";

import { useSageMutation } from "../../components/Common/GqlHooks";
import { LOGIN, LOGIN_WITH_2FA } from "../../api/graphql";
import ContentLoader from "../../components/Common/ContentLoader";
import logo from "../../assets/img/logo_white2.png";
import { validateEmail } from "../../common/validations";

import TwoFactorAuth from "./TwoFactorAuth";
import AuthLayout from "./AuthLayout";

const Login = () => {
  const [formSent, setFormSent] = useState(false);
  const { t } = useTranslation(["login", "auth"]);

  const [showTwoFa, setShowTwoFa] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email || !validateEmail(values.email)) {
      errors.email = true;
    }

    if (!values.pass) {
      errors.pass = true;
    }

    return errors;
  };

  const [login, loading] = useSageMutation(LOGIN);

  const onSubmit = (values) => {
    setFormSent(true);

    login({
      variables: {
        email: values.email.trim(),
        pass: values.pass,
      },
    })
      .then((resp) => {
        if (resp.data.login.user.two_factor_auth) {
          setShowTwoFa(true);
          setFormSent(false);
          return;
        }

        mixpanel.identify(resp.data.login.user.id);
        loginAndRedirect(resp.data.login.token);
      })
      .catch((e) => {
        setFormSent(false);
        console.log(e);
      });
  };

  const [loginWith2Fa, loading2Fa] = useSageMutation(LOGIN_WITH_2FA);

  const handleTwoFa = (code) => {
    setFormSent(true);

    loginWith2Fa({
      variables: {
        email: formik.values.email.trim(),
        pass: formik.values.pass,
        code: code,
      },
    })
      .then((resp) => {
        console.log(resp);
        // mixpanel.identify(resp.data.login.user.id);
        loginAndRedirect(resp.data.loginWith2FA.token);
      })
      .catch((e) => {
        setFormSent(false);
        console.log(e);
      });
  };

  const loginAndRedirect = (token) => {
    // TODO Unstringify token
    localStorage.setItem("user-token", JSON.stringify(token));

    window.location.href = "/dashboard";
  };

  const handleReInitiate = () => {
    login({
      variables: {
        email: formik.values.email.trim(),
        pass: formik.values.pass,
      },
    })
      .then((resp) => {
        console.log(resp);
      })
      .catch((e) => {
        setFormSent(false);
        console.log(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      pass: "",
    },
    validate,
    onSubmit,
  });

  return (
    <AuthLayout>
      <Col
        className="right-pane d-flex flex-column align-items-center"
        id="auth-right-pane"
      >
        <Row className="justify-content-center w-100 mt-auto mb-auto">
          <Col xs={12} md={8} lg={8}>
            <div className="p-3">
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="text-center">
                  <div className="text-center">
                    <img src={logo} alt="SageWealth" width="200" />
                  </div>
                  <Form.Label className="mb-0 mt-4 auth-title">
                    {t("loginProceed")}
                  </Form.Label>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-4">
                    {t("auth:email")}
                  </Form.Label>
                  <Form.Control
                    className="lg"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={!formik.errors.email && formik.touched.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("auth:validEmail")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-1">
                    {t("auth:password")}
                  </Form.Label>
                  <Form.Control
                    className="lg"
                    name="pass"
                    type="password"
                    value={formik.values.pass}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={!formik.errors.pass && formik.touched.pass}
                    isInvalid={formik.errors.pass && formik.touched.pass}
                  />
                </Form.Group>

                <Button
                  size="lg"
                  type="submit"
                  disabled={!formik.isValid}
                  className="flat mt-1 w-100"
                >
                  {t("submit")}
                </Button>
                <div className="text-right forgot-pass-link">
                  <Link className="text-secondary" to="/forgot-password">
                    {t("forgotPass")}
                  </Link>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Col>

      <ContentLoader
        loading={loading || loading2Fa || formSent}
        isInline={false}
      />

      {showTwoFa && (
        <TwoFactorAuth
          onCanceled={() => setShowTwoFa(false)}
          onConfirm={handleTwoFa}
          onReInitiate={handleReInitiate}
          noInitiate
        />
      )}
    </AuthLayout>
  );
};

export default Login;
