import { useLazyQuery, useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";

import { NotificationStoreContext } from "./NotificationStore";

const useGqlWithErrorHandling = (notifications, actionResult) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (actionResult.loading) return;

    if (actionResult.error && actionResult.error.graphQLErrors) {
      if (!actionResult.error.graphQLErrors.forEach) return;

      const graphQLErrors = actionResult.error.graphQLErrors;
      const networkErrors = actionResult.error.networkError;

      graphQLErrors.forEach(({ message }) => {
        notifications.addError(message || "Something went wrong...");
      });

      if (graphQLErrors.length === 0) {
        const isServerError = networkErrors.result?.errors?.length > 0;
        let msg;

        if (isServerError) {
          const trace =
            networkErrors.result.errors[0]?.extensions?.exception?.stacktrace ||
            null;

          msg = trace && trace[0];
        } else {
          msg = networkErrors.message;
        }

        notifications.addError(msg || "Something went wrong...");
      }

      return;
    }

    setData(actionResult.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionResult.data, actionResult.error, actionResult.loading]);

  useEffect(() => {
    setLoading(actionResult.loading);
  }, [actionResult.loading]);

  return [loading, data];
};

export const useSageLazyQuery = (query, opts = {}) => {
  const notifications = useContext(NotificationStoreContext);
  const [action, gqlResult] = useLazyQuery(query, {
    ...opts,
    fetchPolicy: "no-cache",
  });

  const result = useGqlWithErrorHandling(notifications, gqlResult);

  return [action, ...result, gqlResult.refetch];
};

export const useSageMutation = (mutation, opts = {}) => {
  const notifications = useContext(NotificationStoreContext);
  const [action, gqlResult] = useMutation(mutation, opts);

  const result = useGqlWithErrorHandling(notifications, gqlResult);

  return [action, ...result];
};
