import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SageModal from "./SageModal";

const YesNoModal = ({
  title = "",
  show,
  onCanceled,
  onBack,
  onConfirm,
  image,
  children,
  variant = "primary",
}) => {
  const { t } = useTranslation("dashboard");

  const buttons = (
    <>
      <Button
        variant="transparent"
        size="lg"
        onClick={() => onBack()}
        style={{
          width: "auto",
        }}
      >
        {t("back")}
      </Button>
      <Button variant={variant} size="lg" onClick={onConfirm}>
        {t("confirm")}
      </Button>
    </>
  );

  return (
    <SageModal
      title={title}
      image={image}
      show={show}
      onHide={() => onCanceled()}
      buttons={buttons}
    >
      {children}
    </SageModal>
  );
};

export default YesNoModal;
