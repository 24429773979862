/* eslint-disable */
export default {
  NONE: "",
  DR: "Arzt/Ärztin",
  PROF: "Professor*in",

  MR: "Herr",
  MRS: "Frau",

  male: "Männlich",
  female: "Weiblich",
  OTHER: "Andere",

  MARRIED: "Verheiratet",
  SINGLE: "Nicht verheiratet",

  SELF_EMPLOYED: "Selbstständig",
  EMPLOYED: "Beschäftigt",
  UNEMPLOYED: "Arbeitslos",
  RETIRED: "Im Ruhestand",
  STUDENT: "Student*in",

  HIGH_SCHOOL: "Schulabschluss",
  VOCATIONAL_TRAINING: "Berufsausbildung",
  TECHNICAL_COLLEGE: "Fachhochschule",
  UNDERGRADUATE_DEGREE: "Bachelor",
  POSTGRADUATE_DEGREE: "Master/PhD",

  AGRICULTURE_FORESTRY_HUNTING: "Landwirtschaft, Forstwirtschaft, Jagd",
  MINING_QUARRYING: "Bergbau und Gewinnung von Steinen und Erden",
  GLASS_MANUFACTURE_STONE_PROCESSING:
    "Herstellung von Glas und Glaswaren, Verarbeitung von Steinen",
  MOTOR_VEHICLES_OR_PARTS_MANUFACTURING:
    "Herstellung von Kraftfahrzeugen und Kraftfahrzeugteilen",
  POWER_SUPPLY: "Netzteil",
  WATER_SUPPLY_SEWAGE_WASTE_DISPOSAL:
    "Wasserversorgung, Abwasser- und Abfallentsorgung und Beseitigung von Umweltverschmutzungen",
  BUILDING_CONSTRUCTION: "Hochbau, Bau von Gebäuden",
  MAINTENANCE_REPAIR_TRADE_MOTOR_VEHICLES_AND_SERVICE:
    "Instandhaltung/Reparatur/Handel mit Kraftfahrzeugen und Service",
  RETAIL_TRADE: "Einzelhandel (ohne Handel mit Kfz)",
  STORAGE_AND_PROVISION_TRANSPORT_SERVICES:
    "Lagerei sowie Erbringung von Dienstleistungen für Verkehr",
  GASTRONOMY: "Gastronomie",
  INFORMATION_SERVICES: "Informationsdienstleistungen",
  REAL_ESTATE_AND_HOUSING: "Grundstücks- u. Wohnungswesen",
  OTHER_FREELANCE_SCIENTIFIC_ACTIVITIES:
    "Sonstige freiberufliche/wissenschaftliche Tätigkeiten",
  PROVISION_OF_ECONOMIC_SERVICES:
    "Erbringung von wirtschaftlichen Dienstleistungen für Unternehmen & Privatpersonen",
  EDUCATION_AND_TEACHING: "Erziehung und Unterricht",
  HEALTHCARE: "Gesundheitswesen",
  CREATIVE_ACTIVITIES: "Kreative, künstlerische und unterhaltende Tätigkeiten",
  SPORTS_ENTERTAINMENT_RECREATION:
    "Erbringung von sonstigen Dienstleistungen – Dienstleistungen des Sports, der Unterhaltung und der Erholung",
};
