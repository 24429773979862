import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

const SelectOptions = ({ kind, tctx = "constants", children }) => {
  const { t } = useTranslation(tctx);

  return (
    <Dropdown.Menu>
      {children}
      {Object.keys(kind).map((k) => (
        <Dropdown.Item key={k} eventKey={k}>
          {t(`${kind[k]}`)}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};

export default SelectOptions;
