import { useContext } from "react";
import CurrencyFormat from "react-currency-format";

import { LanguageStoreContext } from "./LanguageStore";
import "./Money.scss";

export const toLocalePercentage = (value, lang) => {
  if (!value) value = 0.0;

  const decimalSep = lang === "en" ? "." : ",";

  return `${value}`.replace(".", decimalSep);
};

export const toFixed = (value) =>
  value === 0 ? 0 : Math.round(value * 100) / 100;

const Percentage = ({ size = "sm", value = 0 }) => {
  const lang = useContext(LanguageStoreContext);

  const thousandSep = lang.lang === "en" ? "," : ".";
  const decimalSep = lang.lang === "en" ? "." : ",";

  return (
    <span className={`percentage ${size}`}>
      <CurrencyFormat
        decimalScale={2}
        fixedDecimalScale={true}
        displayType="text"
        value={toFixed(value)}
        prefix=""
        suffix="%"
        thousandSeparator={thousandSep}
        decimalSeparator={decimalSep}
      />
    </span>
  );
};

export default Percentage;
