import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import mixpanel from "mixpanel-browser";

import { logout, MixpanelEvents } from "../../../common/common";
import { OnboardingStoreContext } from "../OnboardingStore";
import Money from "../../Common/Money";
import { Currency } from "../../../common/money";
import InvestmentCard from "../InvestmentCard";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";

const InvestmentAmountPathFour = ({
  minimumInvestment,
  twoMonths,
  twoMonthsAndMinimum,
  spendingMore,
}) => {
  const obStore = useContext(OnboardingStoreContext);
  const { t } = useTranslation(["steps", "obdNav", "constants", "common"]);

  return (
    <>
      <StepHeader title={t(`obdNav:invAmount`)}>
        {t("invAmountSubNeg")}
      </StepHeader>
      <StepContent>
        <h5>{t("ourAdvice")}</h5>
        <InvestmentCard variant="accent">
          {t("adviceMinInvest")}
          <b>
            <Money
              decimals={0}
              value={{
                currency: Currency.EUR,
                amount: minimumInvestment,
              }}
            />
          </b>
          .{t("adviceHowever")}
          (
          <Money
            decimals={0}
            value={{
              currency: Currency.EUR,
              amount: twoMonths,
            }}
          />
          ){t("adviceAcumm")}
          <b>
            <Money
              decimals={0}
              value={{
                currency: Currency.EUR,
                amount: twoMonthsAndMinimum,
              }}
            />
          </b>
          {t("adviceCanStartInvest")}
          .
          <br />
          <br />
          {spendingMore && t("adviceSpendingMore")}
          {!spendingMore && t("adviceGoodNews")}
          {t("adviceCanStartInvest")}
        </InvestmentCard>
        <br />
        <p className="">{t("pGoBack")}</p>

        <h5>{t("letsPrepare")}</h5>
        <ALink
          label="https://www.marieforleo.com/2013/12/money-mindset/"
          href="https://www.marieforleo.com/2013/12/money-mindset/"
        />
        <br />

        <ALink
          label="https://www.daveramsey.com/blog/the-secret-to-saving-money"
          href="https://www.daveramsey.com/blog/the-secret-to-saving-money"
        />
        <br />

        <ALink
          label="https://www.moneyunder30.com/earn-more-money"
          href="https://www.moneyunder30.com/earn-more-money"
        />
        <br />
      </StepContent>

      <StepButtons
        nextLabel="saveProgress"
        nextVariant="accent"
        onPrev={() => {
          mixpanel.track(
            MixpanelEvents.onboarding.INVESTMENT_AMOUNT_NOT_ELIGIBLE
          );
          obStore.back();
        }}
        onNext={() => logout()}
      />
    </>
  );
};

const ALink = ({ label, href }) => (
  <Button
    href={href}
    target="_blank"
    className="inline text-secondary text-left my-1 my-md-0"
    variant="transparent"
  >
    {label}
  </Button>
);

export default InvestmentAmountPathFour;
