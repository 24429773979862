/* eslint-disable */

export default {
  amazingWork: "Du bist fertig!",
  note1:
    "Deine Daten wurden nun übermittelt und wir sind dabei, dein Konto zu eröffnen.",
  h1: "Was passiert jetzt?",
  note2:
    "Es dauert 6-8 Arbeitstage, bis dein Konto eröffnet und dein Geld angelegt ist.",
  note3: "Wenn alles fertig ist, werden wir dich per E-Mail benachrichtigen.",
  closeWindow: "Du kannst dieses Fenster nun schließen.",
};
