/* eslint-disable */

export default {
  settings: "Einstellungen",
  myInfo: "Meine Angaben",
  editPassword: "Passwort ändern",
  toEdit:
    "Zum <strong>Bearbeiten</strong>, kontaktiere uns bitte im <strong>Chat</strong>.",
  passwordChanged: "Das Passwort wurde erfolgreich geändert!",
  setNewPass: "Wähle ein neues Passwort",
  currentPassword: "Aktuelles Passwort",
  newPassword: "Neues Passwort",
  repeatNewPassword: "Passwort wiederholen",
  updatePassword: "Passwort aktualisieren",
  validPassword: "Das Passwort sollte mindestens 4 Zeichen lang sein",
  validSamePasswords: "'Passwörter müssen identisch sein'",
  password: "Passwort",
  mobile: "Handynummer",
  address: "Addresse",
  refBankAcc: "Referenzbankkonto",
  passwordValidationMessage:
    "Das Passwort muss eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern, Sonderzeichen und mindestens 8 Zeichen bestehen",
  newPassConfirmValidationMessage: "Passwörter müssen identisch sein",
  MONTHLY_DEPOSIT: "Monatl. Sparplan",
  DEPOSIT: "Einzahlung",
  WITHDRAW: "Auszahlung",
  RETURN_FEE: "Gebühren",
  MONTHLY_FEE: "Monatliche Gebühr",
  MANAGEMENT_FEE: "Verwaltungskosten",
  TRADING_FEE: "Handelsgebühren",
  txAmount: "Betrag",
  txDate: "Datum",
  txType: "Transaktionsart",
  myInfoTab: "Meine Information",
  transactionsTab: "Transaktionen",
  documentsTab: "Dokumente",
  twoFA: "Zwei-Faktor-Authentifizierung",
  sixDigitCode:
    "Gib bitte den sechsstelligen Code ein, den du über die Zwei-Faktor-Authentifizierung erhalten hast.",
  twoFATermsTitle: "Nutzungsbedingungen",
  twoFATermsIntro:
    "Zur Aktivierung der Zwei-Faktor-Authentifizierung akzeptiere bitte die folgenden Nutzungsbedingungen:",
  twoFATerms:
    "Ich bestätige, die beiden Faktoren nicht am gleichen Ort oder Medium zu speichern bzw. aufzubewahren. Beide Faktoren werde ich nicht an Dritte weitergegeben werden oder für Dritte zugänglich aufbewahren.",
  twoFATermsAccept: "I accept",
  didntRec: "Du hast den Code nicht erhalten?",
  resend2Fa: "Klicke hier, um es erneut zu senden",
  submit: "Senden",
  changeMobile: "Mobiltelefonnummer ändern",
  chooseNewMobile: "Wähle bitte eine neue Mobiltelefonnummer",
  dontForgetCC: "Bitte die Ländervorwahl nicht vergessen.",
  req2FaOne: "Diese Aktion erfordert eine Zwei-Faktor-Authentifizierung.",
  req2FaTwo:
    "Stelle bitte sicher, dass du noch Zugriff auf dein aktuelles Mobiltelefon hast.",
  phone2FaMsg:
    "Gib bitte den Code ein, den du auf deinem aktuellen Mobiltelefon erhalten hast.",
  phoneVerifyTitle: "Neue Mobiltelefonnummer bestätigen",
  phoneVerifyMsg:
    "Bestätige bitte die Änderung, indem du den Code eingibst, den du über deine neue Mobiltelefonnummer erhalten hast.",
  // Edit email
  changeEmail: "Wähle eine neue E-Mail-Adresse",
  chooseNewEmail: "Neue Email-Adresse",
  emailVerifyTitle: "Verifizierung der neuen E-Mail-Adresse",
  emailVerifyMsg:
    "Bestätige bitte die Änderung, indem du den Code eingibst, den du über deine neue E-Mail-Adresse erhalten hast.",
  // Change iban
  changeIban: "Referenzkonto ändern (IBAN)",
  chooseNewIban: "Wähle ein neues Referenzkonto (IBAN)",
  makeSureIban: "Bitte sicherstellen, dass die neue IBAN im Namen von ",
  makeSureIbanSuff: " ist",
  // Change addr
  changeAddr: "Wohnanschirft ändern",
};
