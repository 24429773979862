import { Form } from "react-bootstrap";

import { useSessionStorage } from "./CommonHooks";

const FormikFormControl = (props) => {
  const formik = props.formik;
  const field = props.field;

  // eslint-disable-next-line no-unused-vars
  const [sGet, sSet] = useSessionStorage("ob");

  const newProps = Object.assign({}, props);

  delete newProps.onChange;

  const handleChange = (e) => {
    sSet(field, e.target.value);
    formik.handleChange(e);
    if (props.onChange) props.onChange(e);
  };

  const handleBlur = (e) => {
    if (props.onBlur) props.onBlur(e);
    formik.handleBlur(e);
  };

  return (
    <Form.Control
      name={field}
      value={formik.values[field]}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={formik.errors[field] && formik.touched[field]}
      {...newProps}
    >
      {props.children}
    </Form.Control>
  );
};

export default FormikFormControl;
