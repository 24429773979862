// eslint-disable-next-line import/no-anonymous-default-export
export default {
  date: "Datum",
  document: "Dokument",
  TAX_REPORT: "Steuerbescheid",
  DAILY_ACCOUNT_STATEMENT: "Tageskontoauszug",
  SECURITY_SETTLEMENT: "ETF Handel",
  CHANGE_OF_INTEREST_RATE: "Zinsänderung",
  FINANCIAL_STATUS: "Finanzstatus",
  CUSTODIAN_CONTRACT: "Kontoeröffnungvertrag",
  LIABILITY_PARTNER_CONTRACT: "Vermögensverwaltungvertrag",
  LIABILITY_PARTNER_CLIENT_INFO: "AGB Informationspaket",
  SUITABILITY_DOCUMENT: "Geeignetheitserklärung",
  PRELIMINARY_SUITABILITY_DOCUMENT: "Vorläufige Geeignetheitserklärung",
  PRELIMINARY_LIABILITY_PARTNER_CONTRACT:
    "Vorläufiger Vermögensverwaltungvertrag",
  PRELIMINARY_LIABILITY_PARTNER_CLIENT_INFO: "Vorläufige AGB Informationspaket",
  PRELIMINARY_CUSTODIAN_CONTRACT: "Vorläufiger Kontoeröffnungvertrag",
  ANNUAL_TAX_REPORT: "Jährliche Steuerbescheinigung",
  SECURITY_EVENT: "Dividendenausschüttung",
  TAX_OPTIMIZATION: "Steuerausgleichsrechnung",
};
