// eslint-disable-next-line import/no-anonymous-default-export
export default {
  date: "Date",
  document: "Document",
  TAX_REPORT: "Tax Report",
  DAILY_ACCOUNT_STATEMENT: "Daily Account Statement",
  SECURITY_SETTLEMENT: "ETF Trade",
  CHANGE_OF_INTEREST_RATE: "Change of Interest Rate",
  FINANCIAL_STATUS: "Financial Status",
  CUSTODIAN_CONTRACT: "Account Opening Contract",
  LIABILITY_PARTNER_CONTRACT: "Wealth Management Contract",
  LIABILITY_PARTNER_CLIENT_INFO: "T&C Information Packet",
  SUITABILITY_DOCUMENT: "Suitability Statement",
  PRELIMINARY_SUITABILITY_DOCUMENT: "Preliminary Suitability Statement",
  PRELIMINARY_LIABILITY_PARTNER_CONTRACT:
    "Preliminary Wealth Management Contract",
  PRELIMINARY_LIABILITY_PARTNER_CLIENT_INFO:
    "Preliminary T&C Information Packet",
  PRELIMINARY_CUSTODIAN_CONTRACT: "Preliminary Account Opening Contract",
  ANNUAL_TAX_REPORT: "Annual Tax Report",
  SECURITY_EVENT: "Dividend Distribution",
  TAX_OPTIMIZATION: "Tax Optimization",
};
