import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { INITIATE_2FA } from "../../api/graphql";
import CodeVerifyModal from "../../components/Common/CodeVerifyModal";
import ContentLoader from "../../components/Common/ContentLoader";
import { useSageMutation } from "../../components/Common/GqlHooks";

const TwoFactorAuth = ({
  onCanceled,
  onConfirm,
  forAction,
  noInitiate = false,
  onReInitiate,
  title,
  message,
}) => {
  const { t } = useTranslation(["settings"]);
  const [initiate, loading] = useSageMutation(INITIATE_2FA);

  useEffect(() => {
    if (noInitiate) return;

    initiate({
      variables: {
        action: forAction,
      },
    }).catch(console.log);
  }, [initiate, forAction, noInitiate]);

  const handleResend = () => {
    if (noInitiate) {
      onReInitiate(forAction);
      return;
    }

    initiate({
      variables: {
        action: forAction,
      },
    }).catch(console.log);
  };

  return (
    <>
      <CodeVerifyModal
        show={true}
        onCanceled={onCanceled}
        onConfirm={(code) => onConfirm(code, forAction)}
        title={title || t("twoFA")}
        message={message || t("sixDigitCode")}
      >
        <div className="mt-4 text-center">
          <small className="d-block">{t("didntRec")}</small>

          <Button
            alt="resend"
            className="text-accent flat text-left p-0"
            variant="link"
            size="sm"
            onClick={handleResend}
          >
            <small>{t("resend2Fa")}</small>
          </Button>
        </div>
      </CodeVerifyModal>

      <ContentLoader loading={loading} isInline={false} />
    </>
  );
};

export default TwoFactorAuth;
