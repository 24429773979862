import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OnboardingStoreContext } from "./OnboardingStore";

const belongsToStep = (step, currStep) =>
  step.steps.find((x) => x.id === currStep) !== undefined;

const isStepCompleted = (step, currStep) =>
  step.steps.reduce((prev, curr) => curr.id, 0) < currStep;

const NavigationItem = ({ step, isActive, children, badge = null }) => {
  const { t } = useTranslation("obdNav");
  const obStore = useContext(OnboardingStoreContext);
  const [childrenVisible, setChildrenVisible] = useState(false);
  const [completed, setCompleted] = useState(false);

  // children visible when parent step is still active
  useEffect(() => {
    if (children) {
      if (belongsToStep(step, obStore.currentStep)) {
        setChildrenVisible(true);
      } else {
        setChildrenVisible(false);
      }

      if (isStepCompleted(step, obStore.currentStep)) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }
  }, [children, obStore.currentStep, step]);

  let classes = "";

  if (completed) {
    classes = "completed";
  }

  if (isActive) {
    classes = classes + " active";
  }

  return (
    <li className={`${classes}`}>
      {completed && <CompletedBadge />}
      {!completed && badge}
      {t(step.title)}
      {childrenVisible && <span>{children}</span>}
    </li>
  );
};

const CompletedBadge = () => (
  <span className="step-number completed">
    <i className="fa fa-check-circle"></i>
  </span>
);

export default NavigationItem;
