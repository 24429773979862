import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as moment from "moment";

import SageDatePicker from "../../../components/Common/SageDatePicker";

const DateFilter = (props) => {
  const { initialDateFrom, initialDateTo, onFilter } = props;

  const currentDate = moment();

  const validate = (values) => {
    const errors = {};

    if (moment(values.dateFrom).isAfter(values.dateTo)) {
      errors.dateFrom = true;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      dateFrom: initialDateFrom,
      dateTo: initialDateTo,
    },
    validate,
    onSubmit: (values) => {
      onFilter(values.dateFrom, values.dateTo);
    },
  });

  const handleDateChange = (value, field) => {
    const date = moment(value).format("YYYY-MM-DD");

    formik.setFieldValue(field, date);
    formik.setFieldTouched(field, true);
  };

  return (
    <Form
      noValidate
      onSubmit={formik.handleSubmit}
      className="d-flex flex-grow-1 bg-white p-2 flex-column flex-md-row"
    >
      <Form.Group className="mx-4">
        <Form.Label className="my-0 mx-1">From:</Form.Label>
        <SageDatePicker
          handleChange={(value) => handleDateChange(value, "DateFrom")}
          defaultValue={moment(formik.values.dateFrom)}
          maxDate={currentDate}
          formik={formik}
          field="dateFrom"
        />
        <Form.Control.Feedback type="invalid">
          Date from can't be after date to!
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mx-4">
        <Form.Label className="my-0 mx-1">To:</Form.Label>
        <SageDatePicker
          handleChange={(value) => handleDateChange(value, "DateTo")}
          defaultValue={moment(formik.values.dateTo)}
          maxDate={currentDate}
          formik={formik}
          field="dateTo"
        />
      </Form.Group>
      <Form.Group className="mx-4 my-0 d-flex justify-content-center align-items-center">
        <Button type="submit" className="w" disabled={!formik.isValid}>
          Search
        </Button>
      </Form.Group>
    </Form>
  );
};

export default DateFilter;
