import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Button } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { OnboardingStoreContext } from "../OnboardingStore";
import { useSageMutation } from "../../Common/GqlHooks";
import { OB_GENERATE_ADDR_UPLOAD_LINK } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { MixpanelEvents } from "../../../common/common";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";

const ProofOfAddress = ({ onPrev, onNext }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const [uploadedProof, setUploadedProof] = useState(null);
  const obStore = useContext(OnboardingStoreContext);

  useEffect(() => {
    setUploadedProof(obStore.me.client.proof_of_address_url);
  }, [obStore]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: "image/jpeg, image/png, application/pdf",
  });

  useEffect(() => {
    acceptedFiles.forEach((file) => {
      setUploadedProof(URL.createObjectURL(file));
    });
  }, [acceptedFiles]);

  const [getUploadLink, loadingLink] = useSageMutation(
    OB_GENERATE_ADDR_UPLOAD_LINK
  );

  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleNext = () => {
    mixpanel.track(MixpanelEvents.onboarding.PROOF_OF_ADDRESS_COMPLETED);

    if (acceptedFiles?.length === 0) {
      onNext();
      return;
    }

    getUploadLink()
      .then((res) => {
        setLoadingUpload(true);

        fetch(
          res.data.generateMyProofOfAddressUploadLink
            .proof_of_address_upload_link,
          {
            method: "PUT",
            body: acceptedFiles[0],
          }
        )
          .then(() => {
            obStore.refresh();
            onNext();
          })
          .catch(console.log)
          .finally(() => setLoadingUpload(false));
      })
      .catch(console.log);
  };

  return (
    <>
      <StepHeader title={t(`vAddress`)}>{t("vAddrSub")}</StepHeader>
      <StepContent>
        <h5>{t("proofAddr")}</h5>
        <h5>{t("preferred")}</h5>

        <ul className="paragraph pl-3 pl-md-4">
          <li>{t("pref1")}</li>
          <li>{t("pref2")}</li>
          <li>{t("pref3")}</li>
        </ul>

        <h5>{t("lessPreferred")}</h5>
        <ul className="paragraph pl-3 pl-md-4">
          <li>{t("lPref1")}</li>
          <li>{t("lPref2")}</li>
          <li>{t("lPref3")}</li>
          <li>{t("lPref4")}</li>
          <li>{t("lPref5")}</li>
          <li>{t("lPref6")}</li>
        </ul>

        <div className="text-center mt-5">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>{t("dropFiles")}</p>
            <p>({t("fileTypes")})</p>
            <p>{t("filesOr")}</p>
            <Button
              variant="outline-secondary"
              className="flat mt-2"
              onClick={open}
            >
              {t("labelAdd")}
            </Button>
            {(acceptedFiles.length > 0 || uploadedProof) && (
              <Button
                href={uploadedProof}
                target="_blank"
                className="mt-3 inline text-accent d-block"
                variant="transparent"
              >
                <b>1 {t("uploadedFiles")}</b>
              </Button>
            )}
          </div>
        </div>

        <StepButtons
          onPrev={onPrev}
          onNext={handleNext}
          canContinue={acceptedFiles.length > 0 || uploadedProof}
        />
        <ContentLoader
          loading={loadingLink || loadingUpload}
          isInline={false}
        />
      </StepContent>
    </>
  );
};

export default ProofOfAddress;
