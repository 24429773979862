import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Col, Form } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import { OnboardingStoreContext } from "../OnboardingStore";
import { useSageMutation } from "../../Common/GqlHooks";
import { OB_SAVE_FINANCIAL_SITUATION } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import FormikMoneyInput from "../../Common/FormikMoneyInput";
import { MixpanelEvents } from "../../../common/common";

import Step from "./Step";
import StepButtons from "./StepButtons";
import StepContent from "./StepContent";
import StepHeader from "./StepHeader";

const YourFinancialSituation = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants", "common"]);
  const obStore = useContext(OnboardingStoreContext);
  const [sGet] = useSessionStorage("ob");

  const handlePrev = () => {
    obStore.back();
  };

  const validate = (values) => {
    const errors = {};

    const isNullOrEmpty = (val) => val === null || val === "";

    if (isNullOrEmpty(values.post_tax_monthly_earnings))
      errors.post_tax_monthly_earnings = true;

    if (isNullOrEmpty(values.monthly_spending)) errors.monthly_spending = true;

    if (isNullOrEmpty(values.savings)) errors.savings = true;

    return errors;
  };

  const [submit, loading] = useSageMutation(OB_SAVE_FINANCIAL_SITUATION);

  const onSubmit = (values) => {
    const variables = {
      financial_situation: {
        monthly_spending: {
          currency: "EUR",
          unit: "CENT",
          amount: values.monthly_spending * 100,
        },
        post_tax_monthly_earnings: {
          currency: "EUR",
          unit: "CENT",
          amount: values.post_tax_monthly_earnings * 100,
        },
        savings: {
          currency: "EUR",
          unit: "CENT",
          amount: values.savings * 100,
        },
      },
    };

    if (
      obStore.me.client.financial_situation?.savings.amount &&
      variables.financial_situation.savings.amount !==
        obStore.me.client.financial_situation?.savings.amount
    ) {
      sessionStorage.clear();
      variables.investment_plan = null;
    }

    submit({
      variables,
    })
      .then(() => {
        obStore.refresh();
        mixpanel.track(
          MixpanelEvents.onboarding.YOUR_FINANCIAL_SITUATION_COMPLETED
        );
        obStore.next();
      })
      .catch((e) => console.log(e));
  };

  const convertAmount = (amount) => (amount ? amount / 100 : amount);

  const formik = useFormik({
    initialValues: {
      post_tax_monthly_earnings: convertAmount(
        cachedVal(
          sGet,
          obStore.me.client.financial_situation?.post_tax_monthly_earnings,
          "amount"
        )
      ),
      monthly_spending: convertAmount(
        cachedVal(
          sGet,
          obStore.me.client.financial_situation?.monthly_spending,
          "amount"
        )
      ),
      savings: convertAmount(
        cachedVal(
          sGet,
          obStore.me.client.financial_situation?.savings,
          "amount"
        )
      ),
    },
    validateOnBlur: false,
    validate,
    onSubmit,
  });

  const handleNext = () => {
    formik.submitForm();
  };

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>
        {t("finSitSub")}
      </StepHeader>

      <StepContent>
        <Form noValidate>
          <Form.Row className="mb-0">
            <Col xs={12} md={9}>
              <h5>{t("howMuchEarn")}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("howMuchEarnSub"),
                }}
              ></p>
            </Col>
            <Col>
              <Form.Group className="mt-3 mt-md-5">
                <FormikMoneyInput
                  size="lg"
                  allowNegative={false}
                  formik={formik}
                  field="post_tax_monthly_earnings"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mb-0">
            <Col xs={12} md={9}>
              <h5 className="mt-0">{t("howMuchSpend")}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("howMuchSpendSub"),
                }}
              ></p>
            </Col>
            <Col>
              <Form.Group className="mt-3 mt-md-5">
                <FormikMoneyInput
                  size="lg"
                  allowNegative={false}
                  formik={formik}
                  field="monthly_spending"
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row className="mb-0">
            <Col xs={12} md={9}>
              <h5 className="mt-0">{t("howMuchHave")}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("howMuchHaveSub"),
                }}
              ></p>
            </Col>
            <Col>
              <Form.Group className="mt-3 mt-md-5">
                <FormikMoneyInput
                  size="lg"
                  allowNegative={false}
                  formik={formik}
                  field="savings"
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </StepContent>

      <StepButtons
        nextLabel="startAss"
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <ContentLoader loading={loading} isInline={false} />
    </Step>
  );
};

export default YourFinancialSituation;
