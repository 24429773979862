// eslint-disable-next-line import/no-anonymous-default-export
export default {
  yes: "Ja",
  no: "Nein",
  page: "Seite",
  next: "Weiter",
  previous: "Zurück",
  core: "Global Core",
  thematic: "Innovatoren",
  fixed: "Anleihen",
  disruptTech: "Disruptive Technologien",
  usaLargeComp: "USA Großkonzerne",
  futureFood: "Zukunft der Ernährung",
  usaSmallComp: "Kleine und mittelgroße Unternehmen",
  japan: "Japan",
  economyTomorrow: "Die Wirtschaft von Morgen",
  susRealEstate: "Nachhaltige Immobilien",
  futureHealthcare: "Das Gesundheitssystem der Zukunft",
  leadDiversity: "Führend in Diversität",
  envInnovation: "Umweltinnovation",
  developBonds: "Entwicklungsbank-Anleihen",
  globalBonds: "Weltweite grüne Anleihen",
  europaBonds: "Europäische grüne Anleihen",
  corpBonds: "Grüne Unternehmensanleihen",
};
