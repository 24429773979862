import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StepButtons = ({
  back = true,
  next = true,
  nextLabel = "next",
  nextVariant = "primary",
  canContinue = true,
  onNext,
  onPrev,
  children,
}) => {
  const { t } = useTranslation("steps");

  return (
    <>
      <div className="step-btns px-2 px-md-3 py-2 py-lg-0 pb-1">
        <div>{children}</div>
        <div className="d-flex justify-content-between">
          {back && (
            <Button
              variant="lighter"
              className="flat  text-uppercase"
              onClick={onPrev}
            >
              {t("prev")}
            </Button>
          )}
          {next && (
            <Button
              variant={nextVariant}
              disabled={!canContinue}
              className="flat ml-auto text-uppercase"
              onClick={onNext}
            >
              {t(nextLabel)}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default StepButtons;
