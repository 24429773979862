import { useEffect, useContext } from "react";

import { UserStoreContext } from "../components/Common/UserStore";

const usePendoDashboard = () => {
  const user = useContext(UserStoreContext);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    pendo?.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.firstName + " " + user.lastName,
        status: user.status,
        is_email_verified: user.is_email_verified,
        monthly_spending: user.monthly_spending,
        post_tax_earnings: user.post_tax_earnings,
        savings: user.savings,
        initial_investment: user.initial_investment,
        monthly_amount: user.monthly_amount,
      },

      account: {
        id: "SAGEWEALTH", // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePendoDashboard;
