export default {
  investmentTitle: "Deine Investition:",
  perfectTitle: "Perfekt für:",
  average1: "Durchschnitts-",
  average2: "Rendite*",
  average3: "(nach Gebühren)",
  years1: "10-Jahres-",
  years2: "Rendite",
  horizon1: "Investitions-",
  horizon2: "horizont",
  volatility: "Wertschwankung",
  emotional1: "Emotionale",
  emotional2: "Herausforderung",
  high: "Hoch",
  low: "Niedrig",
  light: "Leicht",
  adventurous: "Abenteuerlich",
  pastPerformance:
    "*Der vergangene Ertrag sagt nichts über das zukünftige Wachstum aus. Zielrenditen und geschätzte Gewinne sind nur eine plausible Schätzung.",
  year: "Jahr",
  years: "Jahre",
  performanceTitle: "Wertentwicklung",
  performanceSubtitle:
    "Dein Investitionssaldo wird im Laufe der Zeit leicht schwanken. Manchmal nach unten, aber meistens nach oben.",
  year5Return: "5-Jahres-Ertrag",
  year10Return: "10-Jahres-Ertrag",
  drawdown1: "Maximaler",
  drawdown2: "Verlust",
  portfolioBreakdown: "Aufschlüsselung des Portfolios",
  bonds: "Anleihen",
  globalCore: "Global Core",
  innovators: "Innovatoren",
  largeUS: "US Großkonzerne",
  smallMediumUS: "Kleine und mittelgroße US-Unternehmen",
  japan: "Japan",
  economiesTomorrow: "Die Wirtschaft von Morgen",
  developmentBonds: "Entwicklungsbank-Anleihen",
  greenBonds: "Weltweite grüne Anleihen",
  europeanBonds: "Europäische grüne Anleihen",
  corporateBonds: "Grüne Unternehmensanleihen",
  sustainRealEstate: "Nachhaltige Immobilien",
  futureHealthcare: "Das Gesundheitssystem der Zukunft",
  leadersDiversity: "Führend in Diversität",
  futureFood: "Zukunft der Ernährung",
  environmentInnovation: "Umweltinnovation",
  disruptiveTechnology: "Disruptive Technologien",
  stabilitySubtitle:
    "Erstinvestoren und für den Schutz deines Geldes vor Inflation",
  growthSubtitle:
    "Investoren, die mit Volatilität umgehen können und ihr Vermögen langfristig vermehren wollen",
  stabilityText1:
    "Stability besteht aus einer Mischung aus nachhaltigen ETF-Anleihen, Aktien, Immobilien und Innovatoren (z.B. Diversität, Ernährung oder Gesundheit).",
  stabilityText2:
    "Jeder ETF erfüllt die EU-Nachhaltigkeitsstandards (Artikel 8 und 9) und weist starke risikobereinigte Erträge auf.",
  stabilityText3:
    "Der hohe Anteil an Anleihen sorgt für Stabilität, die Aktien für Wachstum und der Rest für eine gute Diversifizierung.",
  stabilityText4:
    "Um eine optimale Ausgewogenheit und Diversifizierung im Laufe der Zeit zu gewährleisten, wird deine Anlage jährlich neu gewichtet und deine Dividenden werden automatisch reinvestiert.",
  growthText1:
    "Das Wachstum setzt sich hauptsächlich aus globalen Aktien zusammen, mit einer gesunden Dosis an Innovatoren (z.B. nachhaltige Immobilien, Diversität und Ernährung).",
  growthText2:
    "Jeder ETF erfüllt die EU-Nachhaltigkeitsstandards (Artikel 8 und 9) und weist starke risikobereinigte Erträge auf.",
  growthText3:
    "Der große Anteil an Aktien sorgt für hohe Renditen, während Rohstoffe und Immobilien für eine gute Diversifizierung sorgen.",
  growthText4:
    "Um eine optimale Ausgewogenheit und Diversifizierung im Laufe der Zeit zu gewährleisten, wird deine Anlage jährlich neu gewichtet und deine Dividenden werden automatisch reinvestiert.",
};
