import { useContext } from "react";

import { Product } from "../../../common/common";
import { UserStoreContext } from "../../../components/Common/UserStore";

import GrowthExplore from "./components/GrowthExplore";
import StabilityExplore from "./components/StabilityExplore";
import styles from "./explore.module.scss";

const DashboardExplore = () => {
  const { product_type } = useContext(UserStoreContext);

  return (
    <div className={styles.page}>
      {product_type === Product.STABILITY ? (
        <StabilityExplore />
      ) : (
        <GrowthExplore />
      )}
    </div>
  );
};

export default DashboardExplore;
