import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import stabilityLogo from "../../../../assets/img/products/stability-icon.png";
import stabilityChart from "../../../../assets/img/charts/stability-chart.png";
import stabilityChartDe from "../../../../assets/img/charts/stability_chart_de.webp";
import styles from "../explore.module.scss";
import useFormatPercent from "../../../../hooks/useFormatPercent";

const StabilityExplore = () => {
  const { t, i18n } = useTranslation("explore");

  const formatPercent = useFormatPercent();

  return (
    <>
      <div className={styles.mainInfo}>
        <div className={styles.productInfoHeader}>
          <p className={styles.mainTitle}>{t("investmentTitle")}</p>
          <div className={styles.titleBox}>
            <img src={stabilityLogo} className={styles.icon} />
            <p className={styles.title}>
              SageWealth <br /> Stability
            </p>
          </div>
          <div className={styles.descriptionBox}>
            <p className={styles.descriptionTitle}>{t("perfectTitle")}</p>
            <p className={styles.descriptionSubtitle}>
              {t("stabilitySubtitle")}
            </p>
          </div>
        </div>

        <div className={styles.productInfoList}>
          <div className={styles.productInfoItem}>
            <p className={styles.itemText}>
              {t("average1")} <br /> {t("average2")} <br /> {t("average3")}
            </p>
            <div className={styles.itemNumberDiv}>
              <p className={styles.itemNumber}>
                {formatPercent(3.5)}{" "}
                <span className={styles.smallText}>/{t("year")}</span>
              </p>
            </div>
          </div>

          <div className={styles.productInfoItem}>
            <p className={styles.itemText}>
              {t("years1")}
              <br />
              {t("years2")}
            </p>
            <div className={styles.itemNumberDiv}>
              <p className={styles.itemNumber2}>+{formatPercent(97)}</p>
            </div>
          </div>

          <div className={styles.productInfoItem}>
            <p className={styles.itemText}>
              {t("horizon1")}
              <br />
              {t("horizon2")}
            </p>
            <div className={styles.itemNumberDiv}>
              <p className={styles.itemNumber2}>3-7 {t("years")}</p>
            </div>
          </div>

          <div className={styles.productInfoItem}>
            <p className={styles.itemText}>{t("volatility")}</p>

            <div className={styles.itemNumberDiv}>
              <p className={styles.itemNumber2}>{t("low")}</p>
            </div>
          </div>

          <div className={styles.productInfoItem}>
            <p className={styles.itemText}>
              {t("emotional1")}
              <br />
              {t("emotional2")}
            </p>
            <div className={styles.itemNumberDiv}>
              <p className={styles.itemNumber2}>{t("light")}</p>
            </div>
          </div>
          <p className={styles.productDisclaimer}>{t("pastPerformance")}</p>
        </div>
      </div>

      <div className={styles.productInfoDiv}>
        <div className={styles.productInfo}>
          <div className={styles.productInfoBox1}>
            <img
              src={i18n.language === "en" ? stabilityChart : stabilityChartDe}
              className={styles.chart}
              alt="Ten years chart"
            />
          </div>
          <div className={styles.productInfoBox2}>
            <h2 className={`${styles.productTitle} ${styles.center}`}>
              {t("performanceTitle")}
            </h2>

            <p className={styles.productInfoSubtitle}>
              {t("performanceSubtitle")}
            </p>

            <div className={styles.performanceBox}>
              <div className={styles.performanceItem}>
                <p className={styles.yellowSmallText}>{t("year5Return")}</p>
                <div className={styles.greyBox}>
                  <p className={styles.greyBoxText}>+{formatPercent(55)}</p>
                </div>
              </div>

              <div className={styles.performanceItem}>
                <p className={styles.yellowSmallText}>{t("year10Return")}</p>
                <div className={styles.greyBox}>
                  <p className={styles.greyBoxText}>+{formatPercent(97)}</p>
                </div>
              </div>

              <div className={styles.performanceItem}>
                <p className={styles.yellowSmallText}>
                  {t("drawdown1")}
                  <br />
                  {t("drawdown2")}
                </p>
                <div className={styles.greyBox}>
                  <p className={styles.greyBoxText}>-{formatPercent(14.9)}</p>
                </div>
              </div>
            </div>
            <p className={styles.disclaimerText}>{t("pastPerformance")}</p>
          </div>
        </div>
        <div className={styles.productInfo2}>
          <div className={styles.productInfoBox3}>
            <h2 className={styles.productTitle}>{t("portfolioBreakdown")}</h2>

            <p className={styles.whatsInsideText}>{t("stabilityText1")}</p>
            <p className={styles.whatsInsideText}>{t("stabilityText2")}</p>
            <p className={styles.whatsInsideText}> {t("stabilityText3")}</p>
            <p className={styles.whatsInsideText}> {t("stabilityText4")}</p>
          </div>

          <div className={styles.productInfoBox4}>
            <Carousel
              className={styles.carousel}
              indicators={false}
              interval={null}
            >
              <Carousel.Item>
                <div className={styles.carouselHeader}>
                  <div className={styles.headerBlock1}>
                    <p className={styles.yellowTextLarge}>{t("bonds")}</p>
                    <div className={styles.greyBox2}>
                      <p className={styles.greyBoxText2}>
                        {formatPercent(72.5)}
                      </p>
                    </div>
                  </div>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("globalCore")}</p>
                    <p className={styles.percentageText}>{formatPercent(14)}</p>
                  </div>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("innovators")}</p>
                    <p className={styles.percentageText}>
                      {formatPercent(13.5)}
                    </p>
                  </div>
                </div>
                <div className={styles.carouselBody}>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("developmentBonds")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(20)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("greenBonds")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(17)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("europeanBonds")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(15.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("corporateBonds")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(20)}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.carouselHeader}>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("bonds")}</p>
                    <p className={styles.percentageText}>
                      {formatPercent(72.5)}
                    </p>
                  </div>
                  <div className={styles.headerBlock1}>
                    <p className={styles.yellowTextLarge}>{t("globalCore")}</p>
                    <div className={styles.greyBox2}>
                      <p className={styles.greyBoxText2}>{formatPercent(14)}</p>
                    </div>
                  </div>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("innovators")}</p>
                    <p className={styles.percentageText}>
                      {formatPercent(13.5)}
                    </p>
                  </div>
                </div>
                <div className={styles.carouselBody}>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>{t("largeUS")}</p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("smallMediumUS")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}> {t("japan")}n</p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(2)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("economiesTomorrow")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(2)}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className={styles.carouselHeader}>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("bonds")}</p>
                    <p className={styles.percentageText}>
                      {formatPercent(72.5)}
                    </p>
                  </div>
                  <div className={styles.headerBlock2}>
                    <p className={styles.yellowText}>{t("globalCore")}</p>
                    <p className={styles.percentageText}>{formatPercent(14)}</p>
                  </div>
                  <div className={styles.headerBlock1}>
                    <p className={styles.yellowTextLarge}>{t("innovators")}</p>
                    <div className={styles.greyBox2}>
                      <p className={styles.greyBoxText2}>
                        {formatPercent(13.5)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.carouselBody}>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("sustainRealEstate")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(2.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("futureHealthcare")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(1.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("leadersDiversity")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(1.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("futureFood")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(2.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("environmentInnovation")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(2.5)}
                    </p>
                  </div>
                  <div className={styles.carouselBodyItem}>
                    <p className={styles.carouselItemTitle}>
                      {t("disruptiveTechnology")}
                    </p>
                    <p className={styles.carouselItemTitle}>
                      {formatPercent(3)}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default StabilityExplore;
