import { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Form, Figure } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ContentLoader from "../../Common/ContentLoader";
import detailsImage from "../../../assets/img/my-info.png";
import { useSageLazyQuery, useSageMutation } from "../../Common/GqlHooks";
import {
  SETTINGS_PERSONAL_INFO,
  SETTINGS_ENABLE_2FA,
  SETTINGS_DISABLE_2FA,
  SETTINGS_REQ_CHANGE_MOBILE,
  SETTINGS_CHANGE_MOBILE,
  SETTINGS_REQ_CHANGE_EMAIL,
  SETTINGS_CHANGE_EMAIL,
  SETTINGS_CHANGE_IBAN,
  CHANGE_PASSWORD,
} from "../../../api/graphql";
import { NotificationStoreContext } from "../../Common/NotificationStore";
import "./Settings.scss";
import TwoFactorAuth from "../../Auth/TwoFactorAuth";
import { TwoFactorKind } from "../../../common/2fa";
import SageModal from "../../Common/SageModal";

import EditEmail from "./EditEmail";
import EditIban from "./EditIban";
import EditAddress from "./EditAddress";
import EditPassword from "./EditPassword";
import EditPhone from "./EditPhone";

let newPhone = "";
let newEmail = "";
let newIban = "";
let newBank = null;

const TwoFactorAuthTerms = ({ onCanceled, onConfirm }) => {
  const { t } = useTranslation(["settings"]);

  useEffect(() => {}, []);

  return (
    <>
      <SageModal
        show={true}
        title={t("twoFATermsTitle")}
        onHide={onCanceled}
        className="rounded"
      >
        <div className="verify-container d-flex flex-column align-items-left ml-auto mr-auto mb-4">
          <p>{t("twoFATermsIntro")}</p>
        </div>
        <div className="verify-container d-flex flex-column align-items-left ml-auto mr-auto mb-4">
          <p>"{t("twoFATerms")}"</p>
        </div>

        <Button
          type="submit"
          size="lg"
          className="flat w-100"
          onClick={onConfirm}
        >
          {t("twoFATermsAccept")}
        </Button>
      </SageModal>
    </>
  );
};

const MyInformation = () => {
  const notifications = useContext(NotificationStoreContext);
  const { t } = useTranslation(["settings", "dashboardLayout"]);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditIban, setShowEditIban] = useState(false);
  const [showEditAddr, setShowEditAddr] = useState(false);
  const [showCodeVerification, setShowCodeVerification] = useState(false);
  const [codeVerifyTitle, setCodeVerifyTitle] = useState("");
  const [codeVerifyMsg, setCodeVerifyMsg] = useState("");
  const [getUserProfile, loading, data, refetch] = useSageLazyQuery(
    SETTINGS_PERSONAL_INFO
  );

  const [last2Fa, setLast2Fa] = useState("");
  const [twoFaAction, setTwoFaAction] = useState("");
  const [showTwoFa, setShowTwoFa] = useState(false);
  const [showTwoFaTerms, setShowTwoFaTerms] = useState(false);
  const [twoFaMsg, setTwoFaMsg] = useState("");

  useEffect(() => setTwoFaEnabled(!!data?.me.two_factor_auth), [data]);
  const [twoFaEnabled, setTwoFaEnabled] = useState(true);

  const handleTwoFaChange = () => {
    if (twoFaEnabled) {
      disableTwoFa()
        .then(() => {
          setTwoFaEnabled(false);
          setShowTwoFa(false);
        })
        .catch(console.log);
      return;
    }

    setShowTwoFaTerms(true);
  };

  const [enableTwoFa, loadingEnableTwoFa] =
    useSageMutation(SETTINGS_ENABLE_2FA);

  const [disableTwoFa, loadingDisableTwoFa] =
    useSageMutation(SETTINGS_DISABLE_2FA);

  const [reqChangeMobile, loadingReqChangeMobile] = useSageMutation(
    SETTINGS_REQ_CHANGE_MOBILE
  );

  const [changeMobile, loadingchangeMobile] = useSageMutation(
    SETTINGS_CHANGE_MOBILE
  );

  const [reqChangeEmail, loadingReqChangeEmail] = useSageMutation(
    SETTINGS_REQ_CHANGE_EMAIL
  );

  const [changeEmail, loadingchangeEmail] = useSageMutation(
    SETTINGS_CHANGE_EMAIL
  );

  const [changeIban, loadingchangeIban] = useSageMutation(SETTINGS_CHANGE_IBAN);

  const handleNewPhoneSubmit = (values) => {
    newPhone = values.mobile_phone;

    // TODO - async update issues?
    setTwoFaAction(TwoFactorKind.MOBILE_PHONE_CHANGE);
    setTwoFaMsg(t("phone2FaMsg"));
    setShowTwoFa(true);
  };

  const handleNewEmailSubmit = (values) => {
    newEmail = values.email;

    setTwoFaAction(TwoFactorKind.EMAIL_CHANGE);
    setTwoFaMsg(t("sixDigitCode"));
    setShowTwoFa(true);
  };

  const handleNewIbanSubmit = (values, bank) => {
    newIban = values.reference_bank_account;
    newBank = bank;

    setTwoFaAction(TwoFactorKind.REFERENCE_BANK_ACCOUNT_CHANGE);
    setTwoFaMsg(t("sixDigitCode"));
    setShowTwoFa(true);
  };

  const handleTwoFaTerms = () => {
    setShowTwoFaTerms(false);
    setTwoFaAction(TwoFactorKind.ENABLE_2FA);
    setShowTwoFa(true);
  };

  const handleTwoFa = (code, action) => {
    switch (action) {
      case TwoFactorKind.ENABLE_2FA:
        enableTwoFa({
          variables: {
            code,
          },
        })
          .then(() => {
            setTwoFaEnabled(true);
            setShowTwoFa(false);
          })
          .catch(console.log);
        break;

      case TwoFactorKind.MOBILE_PHONE_CHANGE:
        setCodeVerifyTitle(t("phoneVerifyTitle"));
        setCodeVerifyMsg(t("phoneVerifyMsg"));
        requestPhoneChange(code);
        setLast2Fa(code);
        break;

      case TwoFactorKind.EMAIL_CHANGE:
        setCodeVerifyTitle(t("emailVerifyTitle"));
        setCodeVerifyMsg(t("emailVerifyMsg"));
        requestEmailChange(code);
        setLast2Fa(code);
        break;

      case TwoFactorKind.REFERENCE_BANK_ACCOUNT_CHANGE:
        changeIban({
          variables: {
            code: code,
            ref_acc: {
              iban: newIban,
              bic_if_not_sepa: newBank?.bic ?? null,
            },
          },
        })
          .then(() => {
            setShowTwoFa(false);
            setShowEditIban(false);
            refetch();
          })
          .catch(console.log);
        break;

      default:
        console.log("invalid action: ", action);
    }
  };

  const handleCodeVerification = (code, action) => {
    switch (action) {
      case TwoFactorKind.MOBILE_PHONE_CHANGE:
        changeMobile({
          variables: {
            code: code,
          },
        })
          .then(() => {
            setShowCodeVerification(false);
            setShowEditPhone(false);
            refetch();
          })
          .catch(console.log);
        break;

      case TwoFactorKind.EMAIL_CHANGE:
        changeEmail({
          variables: {
            code: code,
          },
        })
          .then(() => {
            setShowCodeVerification(false);
            setShowEditEmail(false);
            refetch();
          })
          .catch(console.log);
        break;

      default:
        console.log("invalid action: ", action);
    }
  };

  const handleReInitiate = (action) => {
    switch (action) {
      case TwoFactorKind.MOBILE_PHONE_CHANGE:
        requestPhoneChange(last2Fa);
        break;

      case TwoFactorKind.EMAIL_CHANGE:
        requestEmailChange(last2Fa);
        break;

      default:
        console.log("invalid action: ", action);
    }
  };

  const requestPhoneChange = (code) => {
    reqChangeMobile({
      variables: {
        tfa_code: code,
        new_phone: "+" + newPhone,
      },
    })
      .then(() => {
        setShowTwoFa(false);
        setShowCodeVerification(true);
      })
      .catch(console.log);
  };

  const requestEmailChange = (code) => {
    reqChangeEmail({
      variables: {
        tfa_code: code,
        new_email: newEmail,
      },
    })
      .then(() => {
        setShowTwoFa(false);
        setShowCodeVerification(true);
      })
      .catch(console.log);
  };

  useEffect(() => getUserProfile(), [getUserProfile]);

  const [changePassword, loadingPasswordChange] =
    useSageMutation(CHANGE_PASSWORD);

  const handleSubmit = (values) => {
    changePassword({
      variables: {
        old_pass: values.oldPass,
        new_pass: values.newPass,
      },
    })
      .then(() => {
        setShowEditPassword(false);
        notifications.addMessage(t("passwordChanged"));
      })
      .catch((e) => console.log(e));
  };

  const fullName = () =>
    `${data?.me?.client?.first_name || ""} ${
      data?.me?.client?.last_name || ""
    }`;

  return (
    <>
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex p-3">
            <Figure
              className="pr-4 mr-4 d-none d-sm-none d-md-block"
              style={{
                minWidth: "190px",
                maxWidth: "190px",
              }}
            >
              <Figure.Image src={detailsImage} />
            </Figure>

            <Container fluid className="p-0">
              <ContentLoader size={2} loading={loading}>
                <ListItem label={t("name")}>{fullName()}</ListItem>
                <ListItem label={t("email")}>
                  <div className="d-flex justify-content-md-between align-items-baseline">
                    <div>{data?.me?.email || ""}</div>
                    <EditButton onClick={() => setShowEditEmail(true)} />
                  </div>
                </ListItem>
                <ListItem label={t("password")}>
                  <div className="d-flex justify-content-md-between align-items-baseline">
                    <div>**********</div>
                    <EditButton onClick={() => setShowEditPassword(true)} />
                  </div>
                </ListItem>
                <ListItem label={t("mobile")}>
                  <div className="d-flex justify-content-md-between align-items-baseline">
                    <div>{data?.me?.client?.mobile_phone || ""}</div>
                    <EditButton onClick={() => setShowEditPhone(true)} />
                  </div>
                </ListItem>
                <ListItem label={t("address")}>
                  <div className="d-flex justify-content-md-between align-items-baseline">
                    <div>
                      {data?.me?.client?.home_addresses?.map((x, i) => (
                        <div key={i}>
                          {x.street || ""} {x.street_number || ""}
                          <br />
                          {x.postal_code || ""} <br />
                          {x.state || ""} {x.country || ""}
                        </div>
                      )) || ""}
                    </div>
                    <EditButton onClick={() => setShowEditAddr(true)} />
                  </div>
                </ListItem>
                <ListItem label={t("refBankAcc")}>
                  <div className="d-flex justify-content-md-between align-items-baseline">
                    <div>
                      {data?.me?.client?.reference_bank_account?.iban || ""}
                    </div>
                    <EditButton onClick={() => setShowEditIban(true)} />
                  </div>
                </ListItem>
                <ListItem label={t("twoFA")}>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={twoFaEnabled}
                    onChange={handleTwoFaChange}
                  />
                </ListItem>
              </ContentLoader>
            </Container>
          </div>
        </Card.Body>
      </Card>

      {showEditPassword && (
        <EditPassword
          show={showEditPassword}
          onClose={() => setShowEditPassword(false)}
          onSubmit={handleSubmit}
        />
      )}

      {showEditPhone && (
        <EditPhone
          onClose={() => setShowEditPhone(false)}
          onSubmit={handleNewPhoneSubmit}
        />
      )}

      {showEditEmail && (
        <EditEmail
          onClose={() => setShowEditEmail(false)}
          onSubmit={handleNewEmailSubmit}
        />
      )}

      {showEditIban && (
        <EditIban
          name={fullName()}
          onClose={() => setShowEditIban(false)}
          onSubmit={handleNewIbanSubmit}
        />
      )}

      {showEditAddr && <EditAddress onClose={() => setShowEditAddr(false)} />}

      <ContentLoader
        loading={
          loadingPasswordChange ||
          loadingEnableTwoFa ||
          loadingDisableTwoFa ||
          loadingReqChangeMobile ||
          loadingchangeMobile ||
          loadingReqChangeEmail ||
          loadingchangeEmail ||
          loadingchangeIban
        }
        isInline={false}
      />

      {showTwoFaTerms && (
        <TwoFactorAuthTerms
          onCanceled={() => setShowTwoFaTerms(false)}
          onConfirm={handleTwoFaTerms}
        />
      )}

      {showTwoFa && (
        <TwoFactorAuth
          onCanceled={() => setShowTwoFa(false)}
          onConfirm={handleTwoFa}
          forAction={twoFaAction}
          message={twoFaMsg}
        />
      )}

      {showCodeVerification && (
        <TwoFactorAuth
          onCanceled={() => setShowCodeVerification(false)}
          onConfirm={handleCodeVerification}
          onReInitiate={handleReInitiate}
          forAction={twoFaAction}
          title={codeVerifyTitle}
          message={codeVerifyMsg}
          noInitiate
        />
      )}
    </>
  );
};

const ListItem = ({ label, children }) => {
  return (
    <div className="list-item d-flex flex-column flex-md-row align-items-md-center">
      <span className="d-block">{label}</span>
      <div className="pl-0 pl-md-1 flex-grow-1">{children}</div>
    </div>
  );
};

const EditButton = (props) => (
  <Button
    className="inline text-secondary ml-2"
    variant="transparent"
    size="lg"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pencil-fill"
      viewBox="0 0 16 16"
    >
      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
    </svg>
  </Button>
);

export default MyInformation;
