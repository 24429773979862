import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";

const redirectToLogin = () => {
  localStorage.removeItem("user-token");
  window.location.href = "/login";
};

const AuthLink = new ApolloLink((operation, forward) => {
  let token = localStorage.getItem("user-token");

  if (token) {
    token = token.replaceAll('"', "");

    operation.setContext(({ headers }) => ({
      headers: {
        authorization: token ?? "",
        ...headers,
      },
    }));
  }

  return forward(operation);
});

const ErrorLink = onError(({ graphQLErrors = {}, networkError = {} }) => {
  const status =
    networkError && networkError.statusCode ? networkError.statusCode : null;

  if (status) {
    if (status >= 401 && status < 500) {
      redirectToLogin();
    }
  }

  if (graphQLErrors) {
    if (graphQLErrors.forEach) {
      graphQLErrors.forEach((error) => {
        if (error.extensions?.code === "UNAUTHENTICATED") {
          redirectToLogin();
          return;
        }

        console.log(
          `[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`
        );
        Sentry.captureException(error);
      });
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
});

const UriLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const link = from([AuthLink, ErrorLink, UriLink]);

const client = new ApolloClient({
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  },
  cache: new InMemoryCache({
    resultCaching: false,
  }),
  link: link,
});

export const newApolloClient = () => client;
