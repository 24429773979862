import { useTranslation } from "react-i18next";

import doneIcon from "../../assets/img/icons/done_icon.svg";
import closeIcon from "../../assets/img/icons/close_icon.svg";

const PasswordMatcher = (props) => {
  const { password = "" } = props;
  const { t } = useTranslation("register");

  const minLengthCheck = password.length >= 8;
  const uppercaseCheck = password.toLowerCase() !== password;
  const lowerCaseCheck = password.toUpperCase() !== password;
  const numberCheck = /\d/.test(password);
  const specialCharCheck = /[^a-zA-Z0-9 ]/.test(password);

  const renderTestCase = (testValue, translationText) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={testValue ? doneIcon : closeIcon}
          width="14px"
          height="14px"
          className="mx-1"
        />
        <p className={`my-0 ${testValue ? "correct" : ""}`}>
          {t(translationText)}
        </p>
      </div>
    );
  };

  return (
    <div className="pass-hint mt-2 text-gray">
      {renderTestCase(minLengthCheck, "minLength")}
      {renderTestCase(uppercaseCheck && lowerCaseCheck, "characters")}
      {renderTestCase(numberCheck, "numbers")}
      {renderTestCase(specialCharCheck, "specialCharacters")}
    </div>
  );
};
export default PasswordMatcher;
