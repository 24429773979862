import { Container, Navbar } from "react-bootstrap";

import "./Header.scss";
import Logo from "./Logo";

const Header = ({ children, linkTo = "/dashboard", variant }) => {
  return (
    <Navbar
      expand="lg"
      fixed="top"
      variant="secondary"
      bg="secondary"
      className={`header-main p-0 ${variant}`}
      collapseOnSelect
    >
      <Container fluid="lg" className="align-items-stretch">
        <a className="navbar-brand ml-3 ml-lg-0" href={linkTo}>
          <Logo />
        </a>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <i className="fa fa-bars"></i>
        </Navbar.Toggle>
        <Navbar.Collapse className="bg-secondary" id="basic-navbar-nav">
          {children}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
