/* eslint-disable */
export default {
  /* Introduction Start */
  intro: "Introduction",
  welcome: "Welcome to the SageWealth onboarding!",
  selectProduct: "Please pick your investment:",
  sfStab:
    "Perfect for first-time investors and protecting your money against inflation.",
  sfStabGrowth:
    "Perfect for investors who can handle volatility and growing wealth long-term.",
  visitProducts:
    "If you are not sure what to chose, please see our product comparison page ",
  here: "here",
  minInitInv: "Minimum Initial Investment",
  minMonthInv: "Minimum Monthly Investment",
  opt: "Optional",
  hpg: "Historic Portfolio Growth",
  bFees: "Before Fees",
  whatKind: "What kind of account am I opening?",
  whatKindA:
    "You will manage your investment through the SageWealth platform, but in the background you will open a securities account with our partner, Baader Bank AG. Moreover, SageWealth will act as your financial advisors, operating as a tied agent of Bank für Vermögen AG.",
  cantFin: "One last thing!",
  cantFinA:
    "If you can’t finish onboarding in one sitting, don’t worry! Your progress is saved automatically. You can log out on the sidebar and come back anytime. ",
  next: "Next",
  prev: "Previous",
  pleaseNote:
    "Please note that past performance doesn't predict future growth, and should only be treated as a reasonable estimate.",
  /* Introduction End */

  /* Personal Info Start */
  pInfo: "Your Personal Information",
  pInfoSub:
    "Let’s get started! In order to open your account, we are legally obliged to collect your personal details.",
  title: "Title",
  salutation: "Salutation",
  gender: "Gender",
  fName: "First Name(s) (Legal)",
  mName: "Middle Name (Legal)",
  lName: "Last Name(s) (Legal)",
  dOB: "Date of Birth",
  mStatus: "Marital Status",
  emStatus: "Employment Status",
  countryOB: "Country of Birth",
  cs: "Nationality (as in your passport)",
  ind: "Industry",
  cityOB: "City of Birth",
  mob: "Mobile Phone (with country code)",
  edu: "Education",
  validIsReq: "Field is required",
  validMinAmount: "Minimum amount is {min}",
  /* Personal Info End */

  /* Address & Taxes Start */
  EstoniaTIN: "Personal Identification Number<br />(Isikukood)",
  SpainTIN: "Tax Identifiaction Number<br />(Numero DNI / NIF)",
  ItalyTIN: "Tax Identifiaction Number<br />(Codice fiscale)",
  IcelandPID: "Personal Identification Number<br />(Kennitala)",
  PolandPID:
    "Personal Identification Number<br />(Numer identyfikacji podatkowej)",
  addressAndTaxes: "Address & Taxes",
  addressAndTaxesSub:
    "Opening an investment account with SageWealth is just like opening any bank account. For this we are legally obliged to collect your personal details to get started.",
  hAddr: "Home address",
  street: "Street",
  number: "Number",
  additionalAddrInfo:
    "Additional Address information<br />(c/o, housing block, etc)",
  pCode: "Postal Code",
  city: "City",
  country: "Country",
  fState: "Federal State",
  taxes: "Taxes",
  countryTaxes: "Country in which you are liable to pay taxes",
  liableTaxes: "Are you liable to pay US taxes?",
  taxID: "Tax Identification Number (TIN)",
  polExp: "Are you a politically exposed person?",
  incCode: "Incorrect Code",
  tooltip1:
    "Due to US international tax regulations, we are unable to open accounts for US nationals or individuals liable to pay US taxes. We are very sorry for the inconvenience.",
  tooltip2:
    "A politically exposed person is an individual with a high profile (e.g. politicians, senior judges, high ranking military officials, public religious figures, ambassadors, etc). Unfortunately we are unable to take clients of this profile at this time.",
  findTIN: "Where can I find this number?",
  /* Address & Taxes End */

  finSit: "Your Financial Situation",
  finSitSub:
    "As wealth advisors, we are legally obliged to assess your current finances before you invest. Based on this we will make investment advice for you on the next slide.",
  howMuchEarn: "How much do you earn monthly, after taxes?",
  howMuchEarnSub:
    "This includes all money coming into of your accounts<br />(salary, pension, passive income, etc )",
  howMuchSpend: "How much do you spend each month?",
  howMuchSpendSub:
    "This includes all money going out of your accounts <br/>(Rent, groceries, recurring costs, debt payments, etc)",
  howMuchHave: "How much do you have in savings?",
  howMuchHaveSub:
    "This includes money saved across all your bank accounts that is immediately accessible <br/>(Cash accounts, savings accounts, sparbuch, etc)",
  startAss: "Start Assessment",

  /* Investment Amount Start */
  invMoreThanSave:
    "You are trying to invest more than you reported in savings. As regulated wealth advisors, we have to ensure that your data is correct. Please change your investment amount, or go back and correct your savings amount.",
  invAmount: "Investment Amount",
  invAmountSub:
    "Good news! You are in a good position to start investing. Please see our investment advice, and let us know how much you would like to invest initially and monthly. ",
  invAmountSubNeg:
    "Unfortunately, you don't meet the requirements to start investing with SageWealth. Please see our advice so you can start in the near future",
  initInv: " 1. Initial investment",
  weAdviseYouKeep: "We advise you keep two months of expenses ",
  forEmergencies: " at your bank for emergencies.",
  thisMeans: "This means you have ",
  inSavings:
    " in savings that you can start investing today, but it’s up to you!",
  toDepo: "I would like to initially deposit",
  toDepo2: "I would like to initially deposit",
  monthInv: "Monthly investment",
  monthInvSub:
    "You are earning more than you are spending and are in a good position to setup a monthly investment. You can afford to invest ",
  monthInvSubMonthly: " monthly.",
  monthInvSub2: "Because you are spending ",
  monthInvSub2Suffix:
    " more than you are earning, we recommend reducing your expenses before you setup a monthly investment plan. But it’s up to you.",
  monthInvSub3:
    "Because you are spending as much as you earn, we recommend reducing...",
  everyMonth1: "Every month on the",
  likeToDeposit: "I would like to deposit",
  likeToDepositSuffix: "",
  everyMonth: "Every month on the {d}",
  firstMonthly: "Your first monthly investment would be scheduled for ",
  firstMonthlySub:
    "Note: monthly deposits can be changed or cancelled at any time.",
  ourAdvice: "1. Our advice:",
  adviceMinInvest: "SageWealth has a minimum investment amount of ",
  adviceHowever: " However, we recommend you have 2 months of expenses ",
  adviceAcumm: " saved for emergencies. So once you have accumulated ",
  adviceCanStartInvest: " you will be in a great plate to start investing.",
  adviceGoodNews:
    "The good news is that you are earning more than you are spending, which puts you in a good position to start investing in the near future!",
  adviceSpendingMore:
    "Unfortunately, you are also spending more than you are earning, which will make it difficult to accumulate enough savings to start investing.",
  pGoBack:
    "Please go back to change your financial situation if our assessment is incorrect",
  letsPrepare:
    "2. Let’s prepare you to start investing with some great reading material:",
  saveProgress: "Save progress",
  minimum: "minimum",
  /* Investment Amount End */

  /* Investment Horizon Start */
  invHoriz: "Investment Horizon",
  invHorizSub:
    "SageWealth Stability is a low-volatility investment, so in most market situations it will be safe to take out your money. However, to benefit the most from the growth of your investment, it’s important to keep your money invested for longer time periods. ",
  howLong: "How long do you plan to hold the investment for?",
  howLongNote:
    "Please note that no matter what you pick, you can withdraw your money at any time",
  startTerm: "Start Term",
  shortTerm: "Short Term",
  midTerm: "Mid Term",
  longTerm: "Long Term",
  year: "Years",
  estEff: "Let’s see how this affects your estimated returns...",
  est10Year: "Your estimated results after {poi} years",
  totalInv: "Total Invested",
  overCourse: "Over course of horizon",
  futBalance: "Future Balance",
  profit: "Profit",
  afterFees: "pa after fees",
  howCalc: "How is this calculated?",
  /* Investment Horizon Start */

  /* Your Investment Start */
  undInv: "Understanding Your Investment",
  undInvSub:
    "As wealth advisors, we need to make sure that our clients understand their investments, so we put together a short summary for you. Please read carefully!",
  undInvDetail: "SageWealth Stability in detail",
  undInvDetailGrowth: "SageWealth Growth in detail",
  undInvDetailSub:
    "We used Markowitz’ Nobel prize winning portfolio-building strategy to create one of the most sustainable ETF-portfolios on the market with strong risk-adjusted performance.",
  sfStabGlob:
    "SageWealth Stability is a globally diversified investment portfolio, made of a mixture of bond, stock and commodity ETFs. The portfolio has been put together with the intent of minimising volatility while still providing returns. All assets have been selected to have a high sustainability rating.",
  sfStabGlobGrowth:
    "SageWealth Growth is a globally diversified investment portfolio, made primarily of stock ETFs. The portfolio has been put together with the goal of maximising returns. All assets have been selected to have a high sustainability rating.",
  seeAllPort: "See all portfolio",
  seeAllPortBtn: "holdings in detail",
  moreAdv:
    'For more advanced information on SageWealth Stability, see the <span class="text-primary">Key Investor Document (KID)</span>',
  thisInvStrat:
    "This investment strategy is for private clients looking to protect their savings from inflation and to build their wealth. It is suitable for clients who have at least a basic knowledge of how investing works. The strategy is not suitable as a retirement provision, is not aimed at investors with a short-term investment horizon (shorter than 3 years) and is not aimed at investors who cannot bear any or only minor losses",
  thisInvStratGrowth:
    "This investment strategy is for private clients looking to build their wealth over the long-term. It is suitable for clients who have at least a basic knowledge of how investing works. The strategy is aimed at investors with a long-term investment horizon (over 7 years) and is not aimed at investors who cannot bear any or only minor losses.",
  thisInvStratSub: "To brush up on the basics of investing, see",
  thisInvStratSubBtn: "our Introductory guide",
  theVal:
    "The value of your investment will fluctuate over time.<br/>Investments are typically given a risk score between 1 (security-oriented, low returns) to 7 (willing to take risks, highest return), this investment is given a 4, meaning it's somewhat conservative while still achieving steady growth.  This strategy is suitable for investors who can bear financial losses (up to the complete loss of the invested money).",
  theValGrowth:
    "The value of your investment will fluctuate over time.<br/>Investments are typically given a risk score between 1 (security-oriented, low returns) to 7 (willing to take risks, highest return), this investment is given a 6, meaning it's designed around achieving high returns and high volatility. This strategy is suitable for investors who can bear financial losses (up to the complete loss of the invested money).",
  pleaseRead: "Please read and accept the following terms",
  iConfirm:
    "I confirm that SageWealth %p is suitable for my investment goals and that I sufficiently understand the basics of investing to appreciate the oportunities and risks involved",
  labWhatIsSfG: "What is SageWealth Growth?",
  labWhatIsSfS: "What is SageWealth Stability?",
  labWhoIsFor: "Who is it for? ",
  labWhatAreRisks: "What are the risks",
  accept: "ACCEPT",
  resumeOnboarding: "Resume Onboarding",
  fundName: "Fund name",
  type: "Type",
  allocation: "Allocation",
  close: "Close",
  /* Your Investment End */

  /* Costs Start */
  costs: "Costs",
  costsSub:
    "Your SageWealth account is charged monthy on the 5th working day of the month. Your fees are calculated as a percentage of your total investment size, and adjusts to changes in your investment size. All fees are inclusive of tax.",
  youPay: "At your current investment size, you pay monthly:",
  advisoryAcc: "Advisory & transaction costs",
  monthly: "Monthly",
  perYear: " Yearly",
  fundCosts: "Fund costs (TER of ETFs)",
  passiveDrawn:
    "Passively drawn from your ETFs. You will not see this billed directly. ",
  total: "Total Monthly Costs",
  noAddFees: "No additional or hidden fees!",
  invTier1: "Your investment size currently puts you in ",
  invTier1Suff:
    "As your investment size changes, you may move to other tiers.  You will be notified if this happens, and will automatically be charged the new tier amount.",
  invTier1Link: "cost calculator",
  estReturn: "Estimated Returns",
  returnsAlrdy: "Returns already include fund costs",
  advTransCost: "Total Costs",
  over10years: "in {poi} years",
  yProfit: "Your Profit",
  labelSeeCosts: "See cost breakdown & Tiers",
  item: "ITEM",
  servprov: "SERVICE PROVIDER",
  ctier1: "TIER 1",
  ctier2: "TIER 2",
  ctier3: "TIER 3",
  regcompl: "Regulatory compliance",
  transfeeacc: "Transaction fees & account custody",
  portfmngmt: "Portfolio management & advisory platform",
  prodcost: "Fund costs",
  entexfees: "Entry & exit fees",
  ETFter: "ETF fees",
  labelSeeHowCosts: "Impact on profits",
  /* Costs End */

  /* Connect Bank Account Start */
  cBankAcc: "Checkout: Your bank details ",
  cBankAccSub:
    "We use SEPA to manage your transactions. All you have to do is provide your IBAN, and we make sure your deposits and withdraws happen automatically.",
  nameBankAcc: "Name on your Bank Account",
  iban: "IBAN",
  permission:
    'I authorize "Bank für Vermögen (BfV)" on behalf of "SageWealth" to collect funds from my account via SEPA. At the same time, I instruct my credit institution to honor the direct debits drawn from my account. The SEPA ID of "BfV" is DE79ZZ00000666392.',
  notice:
    "Note: I can request a refund of the debited amount within eight weeks of the debit date. The conditions agreed with my credit institution apply.",
  IAccept: "I ACCEPT",
  iniInv: "Your Investment",
  invToday: "Invested Today",
  initialInvestment: "Initial Investment",
  monthlyAdd: "Monthly Additions",
  accountOwner: "Account Owner",
  bank: "Bank",
  bic: "BIC",
  sepaWarning:
    "We could not verify that your bank supports SEPA. If you have access to another account, we recommend you try that one instead.",
  /* Connect Bank Account End */

  /* Terms & Conditions Start */
  TAC: "Checkout: Terms and Conditions",
  tacSub:
    'Please review the following terms and conditions, and the hyperlinked legal documents (which have also been sent to your email). By clicking "SUBMIT" you are legally signing these documents, which will open your account and transfer your funds.',
  pReadLabel: "Please read and accept the following terms",
  baaderTerms: `I hereby request the opening of a securities account (with a clearing account) at "Baader Bank AG" and confirm that the information I have provided is correct. I confirm that I have received and stored the `,
  baaderTermsBtn: "account opening contract",
  baaderTermsSuff: ` (and attached pre-contractual information), and understand that this account opening application is made under the terms of the account opening agreement. Should my details change, I am obliged to inform "Sagefund Digital Wealth Management GmbH" immediately and, if necessary, to provide appropriate documentation. I hereby expressly agree that "Baader Bank AG" may start carrying out the service owed under the account opening contract before the expiry of the statutory revocation period. I hereby confirm that "Baader Bank AG" may execute orders outside a regulated market or a multilateral trading system ("over-the-counter").`,
  vermogenTerms: `I agree to the validity of the contractual conditions. I have taken note of the general terms and conditions and information of the "Bank für Vermögen AG". I acknowledge receiving and storing the `,
  vermogenTermsBtn1: "suitability statement",
  vermogenTermsBtn2: "asset management contract",
  vermogenTermsAnd: " and ",
  vermogenTermsBtn3: "customer information package",
  vermogenTermsSuff: ` of "Bank für Vermögen AG", and confirm that my information is correct.`,
  telephoneTerms: `l agree and understand that there is a legal requirement to record telephone and electronic communication with "Sagefund Digital Wealth Management GmbH", "Baader Bank AG" and "Bank für Vermögen AG". Records are stored for up to 7 years. I release “Baader Bank AG” from the obligations of banking secrecy with respect to “Bank für Vermögen AG” and "Sagefund Digital Wealth Management GmbH" and consent to the transfer of my data as described in section 10-a of the account opening contract. I know that I can revoke this consent at any time with effect for the future by notifying service@baaderbank.de.`,
  ownTerms: "I am acting on my own and not on behalf of others.",
  ownTermsSub: "(Section 10 paragraph 1 No.2 of the Money Laundering Act)",
  acceptAndSubmit: "Submit",
  downloadPSD: "Download PSD",
  /* Terms & Conditions Start */

  /* Verify Identity Start */
  vID: "Verify your identity",
  vAddress: "Verify your Address",
  vIDSub:
    "German regulations require us to initiate a live video call with all new customers to verify your identity.",
  vAddrSub:
    "We are required to verify your address. Please upload one of the following (PDF & image formats supported).",
  vIdInstructions: "Instructions:",
  vIdInstr1:
    "Please prepare your national ID or passport (driving licences are not eligible)",
  vIdInstr2:
    "Click on green button below to initiate a live identification call with our partner, POSTIDENT. This will open a new tab",
  vIdInstr3:
    "When prompted you will have the choice between downloading their app, or conducting the identification call in your browser. We advise you use the device with the best camera",
  inOrder:
    "In order to successfully complete this video call,  you will need the following documents:",
  IDPass: "ID or Passport",
  tooltipIdPass: "Add me",
  note1: "Note: A drivers license does not count as an ID!",
  note2:
    "Note: If you are not in a situation to do this quick video call you can continue the process whenever you come back",
  labelPID: "Click to upload Passport",
  letsGo: "LET'S GO",
  clickHereToStart: "Click here to start",
  clickHereToContinue: "Click here to continue",
  verFailed:
    "Verification failed, please follow the instructions and try again",
  proofAddr: "Valid Documents:",
  preferred: "Preferred",
  pref1: "Government issued registration certificate",
  pref2: "Government issued confirmation of residence",
  pref3: "Government issued confirmation of stay",
  lessPreferred: "Less preferred (must not be older than 6 months)",
  lPref1: "Bank, Insurance or building society statement",
  lPref2: "Utility bill (electricity, water, gas, telephone or internet bill)",
  lPref3: "Tax assessment",
  lPref4: "Tax certificate",
  lPref5: "Social security notice",
  lPref6: "Pension insurance",
  dropFiles: "Drop your file here",
  fileTypes: "PDF and image formats",
  filesOr: "or",
  labelAdd: "Upload Proof of Address",
  uploadedFiles: "Uploaded File",
  /* Verify Identity End */
};
