import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import InfoCard from "../InfoCard";
import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import ReqAsterisk from "../../Common/ReqAsterisk";
import FormikFormControl from "../../Common/FormikFormControl";
import Money from "../../Common/Money";
import DayOfMonth from "../../Common/DayOfMonth";
import FormikRadioCard from "../../Common/FormikRadioCard";
import { useSageLazyQuery, useSageMutation } from "../../Common/GqlHooks";
import { OB_SAVE_REF_BANK_ACC, VALIDATE_IBAN } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { ibanInfo } from "../../../api/iban";
import { MixpanelEvents } from "../../../common/common";
import { OnboardingStoreContext } from "../OnboardingStore";

import StepButtons from "./StepButtons";
import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import Step from "./Step";

const Checkout = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const [sGet] = useSessionStorage("ob");
  const [bank, setBank] = useState(null);
  const [sepaValid, setSepaValid] = useState(true);

  useEffect(() => {
    const iban = cachedVal(
      sGet,
      obStore.me.client?.reference_bank_account,
      "iban"
    );

    if (iban) {
      ibanInfo({ reference_bank_account: iban }).then((resp) => {
        setBank(resp?.bankData);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (values) => {
    return ibanInfo(values)
      .then((resp) => {
        const errors = {};

        setBank(resp?.bankData);

        if (!resp?.valid) errors.reference_bank_account = true;
        if (!values.bank_terms_accepted) errors.bank_terms_accepted = true;

        return errors;
      })
      .catch(console.log);
  };

  const [submit, loading] = useSageMutation(OB_SAVE_REF_BANK_ACC);

  const onSubmit = (values) => {
    submit({
      variables: {
        reference_bank_account: {
          iban: values.reference_bank_account,
          bic_if_not_sepa: bank?.bic ?? null,
        },
      },
    })
      .then(() => {
        mixpanel.track(MixpanelEvents.onboarding.BANK_DETAILS_COMPLETED);
        obStore.refresh();
        obStore.next();
      })
      .catch(console.log);
  };

  const formik = useFormik({
    initialValues: {
      reference_bank_account: cachedVal(
        sGet,
        obStore.me.client?.reference_bank_account,
        "iban"
      ),
      bank_terms_accepted:
        cachedVal(sGet, null, "bank_terms_accepted") === "yes",
    },
    validate,
    onSubmit,
  });

  const [validateIban] = useSageLazyQuery(VALIDATE_IBAN, {
    onCompleted: (data) => {
      if (data.validateIBAN.iban_valid) {
        // TODO: replace old iban check
      }

      if (data.validateIBAN.sepa_support) {
        setSepaValid(true);
      } else {
        setSepaValid(false);
      }
    },
  });

  useEffect(() => {
    if (formik.isValid && formik.values.reference_bank_account) {
      validateIban({
        variables: {
          iban: formik.values.reference_bank_account,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid]);

  const handlePrev = () => {
    obStore.back();
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const monthlyAmount = () =>
    obStore.me.client.investment_plan.monthly_amount
      ? obStore.me.client.investment_plan.monthly_amount.amount / 100
      : 0;

  const handleChange = () => {
    formik.setFieldValue(
      "bank_terms_accepted",
      !formik.values.bank_terms_accepted
    );
  };

  const accHolder = () =>
    `${obStore.me.client.first_name} ${obStore.me.client.last_name}`;

  return (
    <Step variant="extra padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>
        {t("cBankAccSub")}
      </StepHeader>
      <StepContent>
        <Col xs={12} md={6} className="px-0">
          <h5>{t("iniInv")}</h5>
          <InfoCard variant="mt-0">
            <Card.Body>
              <Card.Title className="fw-600">
                {t("initialInvestment")}
              </Card.Title>
              <Card.Text className="">
                <Money
                  size="lg"
                  value={{
                    currency: "EUR",
                    amount:
                      obStore.me.client.investment_plan.initial_investment
                        .amount / 100,
                  }}
                />
              </Card.Text>
            </Card.Body>
            <Card.Body className="">
              <Card.Title className="fw-600">{t("monthlyAdd")}</Card.Title>
              <Card.Text className="">
                <Money
                  size="lg"
                  value={{
                    currency: "EUR",
                    amount: monthlyAmount(),
                  }}
                />
              </Card.Text>
              {obStore.me.client.investment_plan.monthly_amount && (
                <small className="d-block text-accent">
                  {t("everyMonth1")}{" "}
                  <DayOfMonth
                    value={obStore.me.client.investment_plan.day_of_month}
                  />
                </small>
              )}
            </Card.Body>
          </InfoCard>
        </Col>

        <Col className="px-1 pt-5">
          <Form noValidate>
            <Form.Row className="align-items-end mb-0">
              <Form.Group>
                <Form.Label className="required">
                  {t("iban")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl
                  formik={formik}
                  field="reference_bank_account"
                  style={{
                    minWidth: "260px",
                  }}
                />
              </Form.Group>
            </Form.Row>
          </Form>
          {!sepaValid && <p className="py-1">{t("sepaWarning")}</p>}
          <p>
            <span className="d-block py-1">
              {t("accountOwner")}: <b>{accHolder()}</b>
            </span>

            {bank?.name && (
              <span className="d-block py-1">
                {t("bank")}: <b>{bank.name}</b>
              </span>
            )}
            {bank?.bic && (
              <span className="d-block py-1">
                {t("bic")}: <b>{bank.bic}</b>
              </span>
            )}
          </p>
        </Col>
        <div className="d-block d-md-none">
          <h5>{t("pleaseRead")}</h5>
          <FormikRadioCard
            field="bank_terms_accepted"
            formik={formik}
            value="yes"
            checked={formik.values.bank_terms_accepted}
            id="10yrs"
            type="checkbox"
            onChange={handleChange}
          >
            <Card.Text>
              {t("permission")}
              <br />
              <br />
              {t("notice")}
            </Card.Text>
          </FormikRadioCard>
        </div>

        <StepButtons
          onPrev={handlePrev}
          onNext={handleNext}
          canContinue={formik.isValid}
        >
          <div className="mb-2 mb-lg-4 d-none d-md-block">
            <FormikRadioCard
              field="bank_terms_accepted"
              formik={formik}
              value="yes"
              checked={formik.values.bank_terms_accepted}
              id="10yrs"
              type="checkbox"
              onChange={handleChange}
            >
              <Card.Text>
                {t("permission")}
                <br />
                <br />
                {t("notice")}
              </Card.Text>
            </FormikRadioCard>
          </div>
        </StepButtons>
        <ContentLoader loading={loading} isInline={false} />
      </StepContent>
    </Step>
  );
};

export default Checkout;
