import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import SageModal from "../../Common/SageModal";
import FormikFormControl from "../../Common/FormikFormControl";
import { ibanInfo } from "../../../api/iban";

const EditIban = ({ onClose, onSubmit, name }) => {
  const { t } = useTranslation(["settings", ["steps"]]);
  const [bank, setBank] = useState(null);

  const validate = (values) => {
    return ibanInfo(values)
      .then((resp) => {
        const errors = {};

        setBank(resp?.bankData);

        if (!resp?.valid) errors.reference_bank_account = true;

        return errors;
      })
      .catch(console.log);
  };

  const handleSubmit = (values) => {
    onSubmit(values, bank);
  };

  const formik = useFormik({
    initialValues: {
      reference_bank_account: "",
    },
    onSubmit: handleSubmit,
    validate,
  });

  return (
    <SageModal
      className="edit-pass-modal"
      show={true}
      title={t("changeIban")}
      onHide={onClose}
      buttons={
        <Button
          size="lg"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          {t("submit")}
        </Button>
      }
    >
      <h5>{t("chooseNewIban")}</h5>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group>
          <FormikFormControl
            formik={formik}
            field="reference_bank_account"
            style={{
              minWidth: "260px",
            }}
          />
        </Form.Group>
        {bank && (
          <div className="p-2 bg-success-light rounded mb-3">
            {bank?.name && (
              <small className="d-block p-0">
                {t("steps:bank")}: <b>{bank.name}</b>
              </small>
            )}
            {bank?.bic && (
              <small className="d-block p-0">
                {t("steps:bic")}: <b>{bank.bic}</b>
              </small>
            )}
            <small className="d-block p-0 mt-1">
              {t("makeSureIban")}
              <b>{name}</b>
              {t("makeSureIbanSuff")}
            </small>
          </div>
        )}
      </Form>

      <small className="text-accent d-block">{t("req2FaOne")}</small>
      <small className="text-accent d-block">{t("req2FaTwo")}</small>
    </SageModal>
  );
};

export default EditIban;
