import React, { useState, useEffect } from "react";

import { USER_PROFILE_INFO } from "../../api/graphql";
import { Product } from "../../common/common";

import { useSageLazyQuery } from "./GqlHooks";
import ContentLoader from "./ContentLoader";
import NetworkErrorPage from "./NetworkErrorPage";

export const UserStoreContext = React.createContext(null);

const UserStore = ({ children }) => {
  const [user, setUser] = useState(null);
  const [getUserProfileInfo, loading, data] =
    useSageLazyQuery(USER_PROFILE_INFO);

  useEffect(() => getUserProfileInfo(), [getUserProfileInfo]);

  useEffect(() => {
    if (!data) return;

    // eslint-disable-next-line no-undef
    FS.identify(data.me.id, {
      displayName: `${data.me.client.first_name} ${data.me.client.last_name}`,
      email: data.me.email,
    });

    const productType =
      (Array.isArray(data.me.client.accounts) &&
        data.me.client.accounts[0].product) ||
      Product.STABILITY;

    setUser({
      id: data.me.id,
      firstName: data.me.client?.first_name,
      lastName: data.me.client?.last_name,
      status: data.me.client?.status,
      email: data.me.email,
      is_email_verified: data.me.is_email_verified,
      product_type: productType,
      monthly_spending:
        data.me.client?.financial_situation?.monthly_spending?.amount ?? null,
      post_tax_earnings:
        data.me.client?.financial_situation?.post_tax_monthly_earnings
          ?.amount ?? null,
      savings: data.me.client?.financial_situation?.savings?.amount ?? null,
      initial_investment:
        data.me.client?.investment_plan?.initial_investment?.amount ?? null,
      monthly_amount:
        data.me.client?.investment_plan?.monthly_amount?.amount ?? null,
    });
  }, [data]);

  return (
    <UserStoreContext.Provider value={user}>
      <ContentLoader isInline={false} loading={loading} />
      {user && children}
      {!user && !loading && <NetworkErrorPage />}
    </UserStoreContext.Provider>
  );
};

export default UserStore;
