import "./i18n";

import ReactDOM from "react-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LanguageStore from "./components/Common/LanguageStore";
import "font-awesome/css/font-awesome.min.css";
import { newApolloClient } from "./api/apollo";
import NotificationStore from "./components/Common/NotificationStore";

import "./index.scss";

const isLocalHost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

if (!isLocalHost) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENVIRONMENT,

    tracesSampleRate:
      process.env.REACT_APP_ENVIRONMENT === "production" ? 0.4 : 0.05,
  });
}

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ApolloProvider client={newApolloClient()}>
      <NotificationStore>
        <LanguageStore>
          <App />
        </LanguageStore>
      </NotificationStore>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
