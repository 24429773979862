import axios from "axios";
import { useState } from "react";

const apiBase = (path) => `${process.env.REACT_APP_BOFFICE_URI}${path}`;

export const useBOfficePost = (options = {}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const doRequest = (url, request) => {
    setLoading(true);

    return axios({
      url: apiBase(url),
      method: "POST",
      ...options,
      withCredentials: true,
      data: request,
    })
      .catch((error) => {
        setErrors(error);
      })
      .finally(() => setLoading(false));
  };

  return [doRequest, loading, errors];
};

export const useBOfficeGet = (options = {}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const doRequest = (url) => {
    setLoading(true);

    return axios({
      url: apiBase(url),
      method: "GET",
      withCredentials: true,
      ...options,
    })
      .catch((error) => {
        setErrors(error);

        if (error.response.status === 401) {
          // window.location.href = "/backoffice/login";
          return;
        }
      })
      .finally(() => setLoading(false));
  };

  return [doRequest, loading, errors];
};
