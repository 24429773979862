import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";

import { OnboardingStoreContext } from "../OnboardingStore";
import ContentLoader from "../../Common/ContentLoader";

import Step from "./Step";
import ProofOfAddress from "./ProofOfAddress";
import VerifyYourIdentity from "./VerifyYourIdentity";

const IdentityVerification = () => {
  const obStore = useContext(OnboardingStoreContext);
  const [addrActive, setAddrActive] = useState(true);

  useEffect(() => {
    document.querySelector("body").scrollTop = 0;
  }, [addrActive]);

  useEffect(() => {
    if (obStore.me.client.citizenship === "DE") setAddrActive(false);
  }, [obStore]);

  const handleIdBack = () => {
    if (obStore.me.client.citizenship === "DE") {
      obStore.back();
      return;
    }

    setAddrActive(true);
  };

  const handlePrev = () => {
    obStore.back();
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const validate = () => {
    const errors = {};

    return errors;
  };

  const onSubmit = () => {
    setAddrActive(false);
  };

  const formik = useFormik({
    initialValues: {},
    validate,
    onSubmit,
  });

  return (
    <Step variant="padding-bottom">
      {addrActive && (
        <ProofOfAddress
          onNext={handleNext}
          onPrev={handlePrev}
          canContinue={true}
        />
      )}

      {!addrActive && <VerifyYourIdentity onPrev={handleIdBack} />}
      <ContentLoader loading={false} isInline={false} />
    </Step>
  );
};

export default IdentityVerification;
