import { Button, Form, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import SageModal from "../../Common/SageModal";
import FormikNumberInput from "../../Common/FormikNumberInput";

const validate = (values) => {
  const errors = {};

  if (!values.mobile_phone || String(values.mobile_phone).length < 11)
    errors.mobile_phone = true;

  return errors;
};

const EditPhone = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(["settings"]);

  const handleSubmit = (values) => {
    values.mobile_phone = String(values.mobile_phone).replace(/^0|[^0-9]/g, "");
    onSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      mobile_phone: "",
    },
    onSubmit: handleSubmit,
    validate,
  });

  return (
    <SageModal
      className="edit-pass-modal"
      show={true}
      title={t("changeMobile")}
      onHide={onClose}
      buttons={
        <Button
          size="lg"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          {t("submit")}
        </Button>
      }
    >
      <h5>{t("chooseNewMobile")}</h5>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group>
          <InputGroup.Prepend>
            <InputGroup.Text>+</InputGroup.Text>
          </InputGroup.Prepend>
          <FormikNumberInput formik={formik} field="mobile_phone" />
          <small className="text-gray">{t("dontForgetCC")}</small>
        </Form.Group>
      </Form>

      <small className="text-accent d-block">{t("req2FaOne")}</small>
      <small className="text-accent d-block">{t("req2FaTwo")}</small>
    </SageModal>
  );
};

export default EditPhone;
