/* Auth Pages */
import login from "./auth/login";
import register from "./auth/register";
import forgotPassword from "./auth/forgot-password";
import error404 from "./error-404";
/* Dashboard Pages */
import dashboardLayout from "./dashboard/dashboard-layout";
import dashboard from "./dashboard/dashboard";
import settings from "./dashboard/settings";
import settingsDocuments from "./dashboard/settings-documents";
import explore from "./dashboard/explore";
import advice from "./dashboard/advice";
/* Onboarding */
import obdNav from "./onboarding/navigation";
import obdLayout from "./onboarding/obdLayout";
import steps from "./onboarding/steps";
import obdCompleted from "./onboarding/obdCompleted";
import constants from "./onboarding/constants";
import common from "./common";
import auth from "./auth/auth";

const de = {
  auth,
  common,
  login,
  register,
  forgotPassword,
  error404,
  dashboardLayout,
  dashboard,
  settings,
  settingsDocuments,
  obdNav,
  obdLayout,
  steps,
  obdCompleted,
  constants,
  explore,
  advice,
};

export default de;
