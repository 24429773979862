import { useContext, useState, useEffect } from "react";

import { OnboardingStoreContext } from "../OnboardingStore";
import { globals } from "../../../common/common";

import Step from "./Step";
import InvestmentAmountPathFour from "./InvestmentAmountPathFour";
import InvestmentAmountPathOne from "./InvestmentAmountPathOne";

const minimumInvestment = globals.minimumInvestment;
const minimumMonthlyInvestment = globals.minimumMonthlyInvestment;

const InvestmentAmountLayout = () => {
  const [state, setState] = useState(null);
  const obStore = useContext(OnboardingStoreContext);

  useEffect(() => {
    if (!obStore.me.client.financial_situation) return;

    const finSit = {
      monthlyEarnings:
        obStore.me.client.financial_situation.post_tax_monthly_earnings.amount /
        100,
      monthlySpending:
        obStore.me.client.financial_situation.monthly_spending.amount / 100,
      savings: obStore.me.client.financial_situation.savings.amount / 100,
    };

    setState({
      ...finSit,
      earnSpendDiff: finSit.monthlyEarnings - finSit.monthlySpending,
      twoTimesSpending: finSit.monthlySpending * 2,
      twoTimesSpendingAndMin: finSit.monthlySpending * 2 + minimumInvestment,
      effectiveSavings: finSit.savings - finSit.monthlySpending * 2,
    });
  }, [obStore.me]);

  const isPathFour = () => {
    return state?.savings < minimumInvestment;
  };

  return (
    <Step variant="padding-bottom">
      {state && !isPathFour() && (
        <InvestmentAmountPathOne
          minimumInvestment={minimumInvestment}
          minimumMonthlyInvestment={minimumMonthlyInvestment}
          twoMonths={state.twoTimesSpending}
          effectiveSavings={state.effectiveSavings}
          earnSpendDiff={state.earnSpendDiff}
          savings={state.savings}
        />
      )}
      {state && isPathFour() && (
        <InvestmentAmountPathFour
          minimumInvestment={minimumInvestment}
          twoMonths={state.twoTimesSpending}
          twoMonthsAndMinimum={state.twoTimesSpendingAndMin}
          spendingMore={state.earnSpendDiff <= 0}
        />
      )}
    </Step>
  );
};

export default InvestmentAmountLayout;
