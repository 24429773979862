import * as moment from "moment";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import ContentLoader from "../../../components/Common/ContentLoader";
import { useBOfficeGet } from "../BackofficeHooks";
import MoneyAmount from "../MoneyAmount/MoneyAmount";

const Dashboard = () => {
  const [info, setInfo] = useState(null);
  const [getInfo, loading] = useBOfficeGet();

  useEffect(() => {
    getInfo("/backoffice/overview").then((resp) => {
      if (!resp) return;

      setInfo(resp.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!!info && (
        <ContentLoader loading={loading}>
          <div className="d-flex flex-wrap p-5">
            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Total AUM</Card.Title>
                <Card.Text>
                  <MoneyAmount
                    variant="small-decimals"
                    size="lg"
                    amount={info.totalAUM}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small>On:</small> <ADate value={info.aumDate} />
              </Card.Footer>
            </Card>

            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Average AUM</Card.Title>
                <Card.Text>
                  <MoneyAmount
                    variant="small-decimals"
                    size="lg"
                    amount={info.averageAUM}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small>On:</small> <ADate value={info.aumDate} />
              </Card.Footer>
            </Card>

            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Total Monthlies</Card.Title>
                <Card.Text>
                  <MoneyAmount
                    variant="small-decimals"
                    size="lg"
                    amount={info.totalMonthliesAmount}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small>Number of users: </small>
                <strong>{info.totalMonthliesUsers}</strong>
              </Card.Footer>
            </Card>

            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Total Users</Card.Title>
                <Card.Text className="text-center">
                  <span className="headingBackoffice">{info.totalUsers}</span>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Stability Users</Card.Title>
                <Card.Text className="text-center">
                  <span className="headingBackoffice">
                    {info.stabilityUsersCount}
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="ml-3">
              <Card.Body>
                <Card.Title>Growth Users</Card.Title>
                <Card.Text className="text-center">
                  <span className="headingBackoffice">
                    {info.growthUsersCount}
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </ContentLoader>
      )}
    </>
  );
};

const ADate = ({ value }) => {
  if (!value) return <span>---</span>;
  return <span>{moment(value).format("DD.MM.YYYY")}</span>;
};

export default Dashboard;
