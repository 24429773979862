/* eslint-disable */

export default {
  accCreation: "Account Creation",
  intro: "Introduction",
  pInfo: "Personal Information",
  homeTaxes: "Home & taxes information",
  invProfile: "Investor Profile",
  yFinSit: "Your financial situation",
  invAmount: "Investment amount",
  invHoriz: "Investment horizon",
  inv: "INVESTMENT",
  undInv: "Understanding your investment",
  costsBD: "Costs breakdown",
  finSteps: "FINAL STEPS",
  bankAcc: "Your Bank details",
  TAC: "Checkout",
  IDVer: "Identity verification",
  cantFin: "Can't finish now?",
  saveLog: "Save your progress and logout",
  imprint: "Imprint",
  pPolicy: "Privacy Policy",
  cRight: "© Sagefund Digital Wealth Management GmbH 2023",
  cMisc: "Vermittler nach § 2 Abs. 10 KWG der BfV Bank für Vermögen AG",
  iniInv: "Initial Investment",
  monthlyInv: "Monthly Investment",
};
