import { useContext, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

import withdrawImage from "../../../assets/img/dialog/withdraw-icon.png";
import withdrawConfirmImage from "../../../assets/img/dialog/withdraw-confirm-icon.png";
import MoneyInput from "../../Common/MoneyInput";
import SageModal from "../../Common/SageModal";
import YesNoModal from "../../Common/YesNoModal";
import { Currency } from "../../../common/money";
import { useSageMutation } from "../../Common/GqlHooks";
import { WITHDRAW } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { NotificationStoreContext } from "../../Common/NotificationStore";
import Money from "../../Common/Money";
import { MixpanelEvents } from "../../../common/common";

const WithdrawFunds = ({ investmentValue, iban, disabled, children }) => {
  const currency = Currency.EUR;

  const notifications = useContext(NotificationStoreContext);
  const { t } = useTranslation("dashboard");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [isValid, setIsValid] = useState(false);
  const minWithdraw = 25;

  const withdrawAmountChanged = (values) => {
    const { value } = values;

    if (value === "" || value < minWithdraw) {
      setWithdrawAmount(undefined);
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setWithdrawAmount(value);
  };

  const handleDeposit = () => {
    mixpanel.track(MixpanelEvents.dashboard.WITHDRAW_AMOUNT_ENTERED, {
      amount: withdrawAmount,
    });
    setShow(false);
    setShowConfirm(true);
  };

  const handleBack = () => {
    setShow(true);
    setShowConfirm(false);
  };

  const handleCancel = () => {
    setWithdrawAmount(undefined);
    setIsValid(false);
    setShow(false);
    setShowConfirm(false);
  };

  const [withdraw, loading] = useSageMutation(WITHDRAW);

  const handleConfirm = () => {
    withdraw({
      variables: {
        amount: {
          unit: "CENT",
          currency: currency,
          amount: Math.round(withdrawAmount * 100),
        },
        description: "Standard Withdraw",
      },
    })
      .then(() => {
        handleCancel();
        notifications.addMessage(t("withdrawalInitiated"));
        mixpanel.track(MixpanelEvents.dashboard.WITHDRAW_INITIATED, {
          amount: withdrawAmount,
        });
      })
      .catch((e) => console.log(e));
  };

  const showModal = () => {
    mixpanel.track(MixpanelEvents.dashboard.WITHDRAW_MODAL_OPENED);
    setShow(true);
  };

  return (
    <>
      <Button
        className="mb-2"
        variant="accent"
        size="lg"
        disabled={disabled}
        onClick={showModal}
      >
        {children}
      </Button>

      <SageModal
        show={show}
        title={t("withdrawFrom")}
        onHide={handleCancel}
        image={withdrawImage}
        buttons={
          <Button
            size="lg"
            variant="accent"
            onClick={handleDeposit}
            disabled={!isValid}
          >
            {t("confirm")}
          </Button>
        }
      >
        <Form validated={false}>
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("wouldWithdraw")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
            <Col xs="auto">
              <MoneyInput
                currency={currency}
                value={withdrawAmount}
                onValueChange={withdrawAmountChanged}
                decimalScale={0}
                allowNegative={false}
                className={isValid ? "narrow" : "narrow invalid"}
              />
              <small className="d-block p-0 pt-1 text-secondary text-center">
                <Money
                  variant="text-secondary"
                  value={{
                    amount: minWithdraw,
                    currency: Currency.EUR,
                  }}
                />{" "}
                {t("validateWithdraw")}
              </small>
            </Col>
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("fromSagefund")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
          </Form.Row>
        </Form>
        <p className="mt-4 mb-1">
          {t("currentBalance")}{" "}
          <strong>
            <Money value={investmentValue} />
          </strong>{" "}
          {t("invested")}
        </p>
        <p>{t("balanceTransferred")}</p>
        <p>
          <strong className="text-numbers">{iban}</strong>
        </p>
      </SageModal>

      <YesNoModal
        title={t("securityInfo")}
        show={showConfirm}
        image={withdrawConfirmImage}
        onCanceled={handleCancel}
        onBack={handleBack}
        onConfirm={handleConfirm}
        variant="accent"
      >
        <h5 className="text-dark">
          {t("youAreSetToWith")}{" "}
          <span className="text-accent">
            {t("withdraw")}{" "}
            <Money
              variant="text-accent"
              value={{
                amount: withdrawAmount,
                currency: Currency.EUR,
              }}
            />
          </span>{" "}
          {t("fromSagefund")}
        </h5>
        <p className="mt-4 mb-1">{t("withdrawConfirmFirstNote")}</p>
        <p>{t("withdrawConfirmSecondNote")}</p>
      </YesNoModal>

      <ContentLoader loading={loading} isInline={false} />
    </>
  );
};

export default WithdrawFunds;
