import { Container, Form, Nav, NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import Header from "../Common/Header";
import LanguageSwitch from "../Common/LanguageSwitch";
import { UserStoreContext } from "../Common/UserStore";
import { ClientStatus } from "../../common/client-status";
import useAuthTimeout from "../Common/AuthTimeoutHook";
import { flattenObject } from "../../common/common";
import usePendoDashboard from "../../hooks/usePendoDashboard";

const DashboardLayout = ({ children, removeContainerStyle = false }) => {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const { t } = useTranslation("dashboardLayout");
  const user = useContext(UserStoreContext);

  usePendoDashboard();

  useAuthTimeout();

  useEffect(() => {
    if (!user) return;

    if (user.status !== ClientStatus.ONBOARDED) {
      window.location.href = "/onboarding";
    }

    if (user.status === ClientStatus.ONBOARDED) {
      setIsOnboarded(true);
    }

    mixpanel.identify(user.id);
    mixpanel.people.set(flattenObject(user.client));
  }, [user]);

  const logout = () => {
    mixpanel.reset();
    localStorage.removeItem("user-token");
    window.location.href = "/login";
  };

  return (
    <>
      {isOnboarded && (
        <>
          <Header linkTo="/dashboard">
            <Nav className="ml-auto">
              <Nav.Link
                as={NavLink}
                activeClassName="active"
                exact={true}
                to="/dashboard"
              >
                {t("dashboard")}
              </Nav.Link>
              <Nav.Link as={NavLink} exact={true} to="/dashboard/explore">
                {t("explore")}
              </Nav.Link>

              <Nav.Link as={NavLink} exact={true} to="/dashboard/advice">
                {t("advice")}
              </Nav.Link>

              <NavDropdown title={`${user.firstName}`} className="flat mr-lg-4">
                <NavDropdown.Item
                  as={NavLink}
                  activeClassName="active"
                  to="/dashboard/settings"
                >
                  {t("settings")}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout}>
                  {t("logout")}
                </NavDropdown.Item>
              </NavDropdown>
              <Form inline>
                <LanguageSwitch />
              </Form>
            </Nav>
          </Header>

          {removeContainerStyle ? (
            <div>{children}</div>
          ) : (
            <Container
              id="dash-page"
              className="page dashboard-page px-0"
              fluid
            >
              {children}
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default DashboardLayout;
