import * as moment from "moment";
import { useContext } from "react";

import { LanguageStoreContext } from "./LanguageStore";

const LocalizedDate = ({ date, className = "", short = false }) => {
  const lang = useContext(LanguageStoreContext);

  const long = (date) => {
    date = new moment(date).locale(lang.lang);

    return lang.lang === "de"
      ? date.format("dddd, D[.] MMMM YYYY")
      : date.format("dddd, MMMM D, YYYY");
  };

  const toShort = (date) => {
    date = new moment(date).locale(lang.lang);

    return date.format("DD[.]MM[.]YYYY");
  };

  return (
    <>
      {short && <span className={className}>{toShort(date)}</span>}
      {!short && <span className={className}>{long(date)}</span>}
    </>
  );
};

export default LocalizedDate;
