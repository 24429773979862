import { useEffect, useContext } from "react";

import { OnboardingStoreContext } from "../components/Onboarding/OnboardingStore";

const usePendoOnboarding = () => {
  const state = useContext(OnboardingStoreContext);

  const { me } = state;

  useEffect(() => {
    // eslint-disable-next-line no-undef
    pendo?.initialize({
      visitor: {
        id: me.id,
        email: me.email,
        full_name: me.client?.firstName + " " + me.client?.lastName,
        status: me.client?.status,
        is_email_verified: me.is_email_verified,
        monthly_spending:
          me.client?.financial_situation?.monthly_spending?.amount ?? null,
        post_tax_earnings:
          me.client?.financial_situation?.post_tax_monthly_earnings?.amount ??
          null,
        savings: me.client?.financial_situation?.savings?.amount ?? null,
        initial_investment:
          me.client?.investment_plan?.initial_investment?.amount ?? null,
        monthly_amount:
          me.client?.investment_plan?.monthly_amount?.amount ?? null,
      },

      account: {
        id: "SAGEWEALTH", // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default usePendoOnboarding;
