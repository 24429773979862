import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import {
  Col,
  Form,
  Dropdown,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import { OnboardingStoreContext } from "../OnboardingStore";
import ReqAsterisk from "../../Common/ReqAsterisk";
import FormikFormControl from "../../Common/FormikFormControl";
import FormikCustomSelect from "../../Common/FormikCustomSelect";
import FormikFormCheck from "../../Common/FormikFormCheck";
import { useSageLazyQuery, useSageMutation } from "../../Common/GqlHooks";
import { OB_VALIDATE_TIN, OB_SAVE_TAX_INFO } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { TaxRecordKind } from "../../../common/client";
import {
  taxLiableCountryCodes,
  nationalityCountryCodes,
  mapCountriesAndSort,
  MixpanelEvents,
} from "../../../common/common";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";
import Step from "./Step";

const countries = require("country-list");

const tinPidLabels = {
  EE: "EstoniaTIN",
  ES: "SpainTIN",
  IT: "ItalyTIN",
  IS: "IcelandPID",
  PL: "PolandPID",
};

const HomeAndTaxesInformation = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants", "common"]);
  const obStore = useContext(OnboardingStoreContext);
  const [sGet] = useSessionStorage("ob");

  const taxLiableCountries = () =>
    mapCountriesAndSort(countries, taxLiableCountryCodes);

  const nationalityCountries = () =>
    mapCountriesAndSort(countries, nationalityCountryCodes);

  const addressCountries = () =>
    mapCountriesAndSort(countries, countries.getCodes());

  const handlePrev = () => {
    obStore.back();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.street) errors.street = true;
    if (!values.street_number) errors.street_number = true;
    if (!values.postal_code) errors.postal_code = true;
    if (!values.town) errors.town = true;
    if (!values.state) errors.state = true;
    if (!values.country) errors.country = true;
    if (!values.citizenship) errors.citizenship = true;

    if (!values.tax_country) errors.tax_country = true;
    else if (values.tax_country !== "DE" && !values.tax_number) {
      errors.tax_number = true;
    }

    if (nationalTinVisible() && !values.national_tin)
      errors.national_tin = true;
    if (nationalPidVisible() && !values.national_pid)
      errors.national_pid = true;

    return errors;
  };

  const [submit, loading] = useSageMutation(OB_SAVE_TAX_INFO);

  const onSubmit = (values) => {
    const pid = nationalPidVisible() ? values.national_pid : null;

    const variables = {
      citizenship: values.citizenship,
      pid: pid,
      home_addresses: [
        {
          street: values.street,
          street_number: values.street_number,
          postal_code: values.postal_code,
          town: values.town,
          state: values.state,
          country: values.country,
          additional_address_info: values.additional_address_info,
        },
      ],
    };

    const tax_information = {
      is_church_tax_deductible: false,
      tax_records: [
        {
          country: values.tax_country,
          tax_number: values.tax_country === "DE" ? null : values.tax_number,
          church_tax_deduction: false,
          type: TaxRecordKind.RESIDENTIAL,
        },
      ],
    };

    if (nationalTinVisible()) {
      tax_information.tax_records.push({
        country: values.citizenship,
        tax_number: values.national_tin,
        church_tax_deduction: false,
        type: TaxRecordKind.NATIONAL,
      });
    }

    submit({
      variables: {
        ...variables,
        tax_information,
      },
    })
      .then(() => {
        obStore.refresh();
        mixpanel.track(
          MixpanelEvents.onboarding.HOME_AND_TAXES_INFORMATION_COMPLETED
        );
        obStore.next();
      })
      .catch((e) => console.log(e));
  };

  const handleTinValidation = (data) => {
    if (!data?.validateTIN) {
      formik.setFieldError("tax_number", "invalidTIN");
      formik.setFieldTouched("tax_number", true);
    }
  };

  const handleNationalTinValidation = (data) => {
    if (!data?.validateTIN) {
      formik.setFieldError("national_tin", "invalidTIN");
      formik.setFieldTouched("national_tin", true);
    }
  };

  const [validateTIN, loadingValidateTin] = useSageLazyQuery(OB_VALIDATE_TIN, {
    onCompleted: handleTinValidation,
  });

  const [validateNationalTIN, loadingValidateNationalTin] = useSageLazyQuery(
    OB_VALIDATE_TIN,
    {
      onCompleted: handleNationalTinValidation,
    }
  );

  const handleTaxCountryChange = () => {
    formik.setFieldValue("tax_number", "");
  };

  const handleCitizenshipChange = () => {
    formik.setFieldValue("national_tin", "");
    formik.setFieldValue("national_pid", "");
  };

  const handleTinBlur = () => {
    if (process.env.REACT_APP_DISABLE_TIN_VALIDATION === "true") return;

    if (!formik.values.tax_number || !formik.values.tax_country) return;

    validateTIN({
      variables: {
        tin: formik.values.tax_number,
        country: formik.values.tax_country,
      },
    });
  };

  const handleNationalTinBlur = () => {
    if (process.env.REACT_APP_DISABLE_TIN_VALIDATION === "true") return;

    if (!formik.values.national_tin || !formik.values.citizenship) return;

    validateNationalTIN({
      variables: {
        tin: formik.values.national_tin,
        country: formik.values.citizenship,
      },
    });
  };

  const countryRenderer = ({ value }) => {
    return <span>{countries.getName(value)}</span>;
  };

  const addresses =
    obStore.me.client.home_addresses && obStore.me.client.home_addresses.length
      ? obStore.me.client.home_addresses[0]
      : null;

  const initialTaxCountry = () => {
    const taxes = obStore.me.client.tax_information;

    if (!taxes) return cachedVal(sGet, null, "tax_country");

    return taxes.tax_records.find((x) => x.type === TaxRecordKind.RESIDENTIAL)
      .country;
  };

  const initialTaxNumber = () => {
    const taxes = obStore.me.client.tax_information;

    if (!taxes) return cachedVal(sGet, null, "tax_number");

    return taxes.tax_records.find((x) => x.type === TaxRecordKind.RESIDENTIAL)
      .tax_number;
  };

  const initialCitizenshipTaxNumber = () => {
    const taxes = obStore.me.client.tax_information;

    if (!taxes || taxes.tax_records.length <= 1)
      return cachedVal(sGet, null, "national_tin");

    return taxes.tax_records.find((x) => x.type === TaxRecordKind.NATIONAL)
      .tax_number;
  };

  const radioInitial = (key) => {
    const cached = cachedVal(sGet, null, key);
    return cached === "" ? (addresses ? "no" : "") : cached;
  };

  const formik = useFormik({
    initialValues: {
      street: cachedVal(sGet, addresses, "street"),
      street_number: cachedVal(sGet, addresses, "street_number"),
      additional_address_info: cachedVal(
        sGet,
        addresses,
        "additional_address_info"
      ),
      postal_code: cachedVal(sGet, addresses, "postal_code"),
      town: cachedVal(sGet, addresses, "town"),
      state: cachedVal(sGet, addresses, "state"),
      country: cachedVal(sGet, addresses, "country"),
      citizenship: cachedVal(sGet, obStore.me.client, "citizenship"),
      politicallyActive: radioInitial("politicallyActive"),
      usTaxEligible: radioInitial("usTaxEligible"),
      // Tax numbers and pids special cases
      tax_country: initialTaxCountry(),
      tax_number: initialTaxNumber(),
      national_tin: initialCitizenshipTaxNumber(),
      national_pid: obStore.me.client.personal_identity_number ?? "",
    },
    validateOnBlur: false,
    validate,
    onSubmit,
  });

  const handleNext = () => {
    formik.submitForm();
  };

  const nationalTinVisible = () => {
    const taxCountry = formik.values.citizenship;

    const isTinLiable =
      taxCountry !== "" &&
      (taxCountry === "EE" || taxCountry === "ES" || taxCountry === "IT");

    if (taxCountry === formik.values.tax_country && isTinLiable) {
      return false;
    }

    return isTinLiable;
  };

  const nationalPidVisible = () => {
    const taxCountry = formik.values.citizenship;

    return taxCountry !== "" && (taxCountry === "IS" || taxCountry === "PL");
  };

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>{t("pInfoSub")}</StepHeader>

      <StepContent>
        <Form noValidate>
          <h6 className="form-subtitle">{t("hAddr")}</h6>
          <Form.Row className="align-items-end">
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label className="required">
                  {t("street")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="street" />
              </Form.Group>
            </Col>
            <Col xs={12} md={2}>
              <Form.Group>
                <Form.Label className="required">
                  {t("number")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="street_number" />
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label className="required">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("additionalAddrInfo"),
                    }}
                  ></span>
                </Form.Label>
                <FormikFormControl
                  formik={formik}
                  field="additional_address_info"
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row className="align-items-end">
            <Col xs={12} md={2}>
              <Form.Group>
                <Form.Label className="required">
                  {t("pCode")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="postal_code" />
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label className="required">
                  {t("city")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="town" />
              </Form.Group>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Label className="required">
                  {t("fState")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="state" />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label className="required">
                  {t("country")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect
                  selectedRenderer={countryRenderer}
                  formik={formik}
                  field="country"
                >
                  <Dropdown.Menu>
                    {addressCountries().map((c) => (
                      <Dropdown.Item key={c.code} eventKey={c.code}>
                        {c.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </FormikCustomSelect>
              </Form.Group>
            </Col>
          </Form.Row>

          <h6 className="form-subtitle">{t("taxes")}</h6>
          <Form.Row className="align-items-end mb-1">
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label className="required">
                  {t("countryTaxes")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect
                  selectedRenderer={countryRenderer}
                  onChange={handleTaxCountryChange}
                  formik={formik}
                  field="tax_country"
                >
                  <Dropdown.Menu>
                    {taxLiableCountries().map((c) => (
                      <Dropdown.Item key={c.code} eventKey={c.code}>
                        {c.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </FormikCustomSelect>
                &nbsp;
              </Form.Group>
            </Col>
            {formik.values.tax_country !== "DE" &&
              formik.values.tax_country !== "" && (
                <Col xs={12} md={5}>
                  <Form.Group>
                    <Form.Label className="required">
                      {t("taxID")}
                      <ReqAsterisk />
                    </Form.Label>
                    <FormikFormControl
                      onBlur={handleTinBlur}
                      formik={formik}
                      field="tax_number"
                    />
                    <WhereIsMyTin />
                  </Form.Group>
                </Col>
              )}
          </Form.Row>

          <Form.Row className="align-items-end mb-2">
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label className="required">
                  {t("cs")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect
                  selectedRenderer={countryRenderer}
                  onChange={handleCitizenshipChange}
                  formik={formik}
                  field="citizenship"
                >
                  <Dropdown.Menu>
                    {nationalityCountries().map((c) => (
                      <Dropdown.Item key={c.code} eventKey={c.code}>
                        {c.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </FormikCustomSelect>
                &nbsp;
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              {nationalTinVisible() && (
                <Form.Group>
                  <Form.Label className="required">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(tinPidLabels[formik.values.citizenship]),
                      }}
                    ></span>
                    <ReqAsterisk />
                  </Form.Label>
                  <FormikFormControl
                    onBlur={handleNationalTinBlur}
                    formik={formik}
                    field="national_tin"
                  />
                  <WhereIsMyTin />
                </Form.Group>
              )}
              {nationalPidVisible() && (
                <Form.Group>
                  <Form.Label className="required">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(tinPidLabels[formik.values.citizenship]),
                      }}
                    ></span>
                    <ReqAsterisk />
                  </Form.Label>
                  <FormikFormControl formik={formik} field="national_pid" />
                  &nbsp;
                </Form.Group>
              )}
            </Col>
          </Form.Row>

          <Form.Row className="align-items-end">
            <Col xs={12} md={5}>
              <Form.Group className="">
                <Form.Label className="required">
                  <span className="mr-2">
                    {t("polExp")}
                    <ReqAsterisk />
                  </span>
                  <OverlayTrigger overlay={<Tooltip>{t("tooltip2")}</Tooltip>}>
                    <i className="fa fa-question-circle"></i>
                  </OverlayTrigger>
                </Form.Label>
                <div className="d-flex" role="group">
                  <FormikFormCheck
                    custom
                    type="radio"
                    label={t("common:yes")}
                    id="payes"
                    formik={formik}
                    field="politicallyActive"
                    value="yes"
                    checked={formik.values.politicallyActive === "yes"}
                  />
                  <FormikFormCheck
                    custom
                    className="ml-3"
                    type="radio"
                    label={t("common:no")}
                    id="pano"
                    formik={formik}
                    field="politicallyActive"
                    value="no"
                    checked={formik.values.politicallyActive === "no"}
                  />
                </div>
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group className="">
                <Form.Label className="required">
                  <span className="mr-2">
                    {t("liableTaxes")}
                    <ReqAsterisk />
                  </span>
                  <OverlayTrigger overlay={<Tooltip>{t("tooltip1")}</Tooltip>}>
                    <i className="fa fa-question-circle"></i>
                  </OverlayTrigger>
                </Form.Label>

                <div className="d-flex" role="group">
                  <FormikFormCheck
                    custom
                    type="radio"
                    label={t("common:yes")}
                    id="tayes"
                    formik={formik}
                    field="usTaxEligible"
                    value="yes"
                    checked={formik.values.usTaxEligible === "yes"}
                  />
                  <FormikFormCheck
                    custom
                    className="ml-3"
                    type="radio"
                    label={t("common:no")}
                    id="tano"
                    formik={formik}
                    field="usTaxEligible"
                    value="no"
                    checked={formik.values.usTaxEligible === "no"}
                  />
                </div>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </StepContent>

      <StepButtons
        canContinue={
          formik.values.usTaxEligible === "no" &&
          formik.values.politicallyActive === "no"
        }
        onPrev={handlePrev}
        onNext={handleNext}
      />
      <ContentLoader
        loading={loading || loadingValidateTin || loadingValidateNationalTin}
        isInline={false}
      />
    </Step>
  );
};

const WhereIsMyTin = () => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);

  return (
    <Button
      href="https://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-identification-numbers/"
      target="_blank"
      className="inline text-primary"
      variant="transparent"
    >
      {t("findTIN")}
    </Button>
  );
};

export default HomeAndTaxesInformation;
