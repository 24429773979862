import "./Money.scss";
import CurrencyFormat from "react-currency-format";
import { useContext } from "react";

import { Currency, Unit } from "../../common/money";

import { LanguageStoreContext } from "./LanguageStore";

const Money = ({
  size = "sm",
  variant = "",
  value = {},
  decimals = 2,
  markPositive = false,
}) => {
  const lang = useContext(LanguageStoreContext);

  let amount = 0;
  let currency = null;

  if (value.amount) {
    amount = value.amount;
  }

  if (value.currency) {
    currency = Currency.parse(value.currency);
  }

  let prefix = Currency.toString(currency);
  const unit = Unit.parse(value.unit);

  amount = Unit.amountFrom(unit, amount);

  const thousandSep = lang.lang === "en" ? "," : ".";
  const decimalSep = lang.lang === "en" ? "." : ",";

  const formatValue = (value) => {
    const parts = `${value}`.split(decimalSep);

    return (
      <>
        {parts[0]}
        {decimals !== 0 && <span className="curr-separator">{decimalSep}</span>}
        {decimals !== 0 && <span className="curr-decimals">{parts[1]}</span>}
      </>
    );
  };

  prefix = markPositive && amount > 0 ? `+${prefix}` : prefix;

  return (
    <span className={`money ${size} ${variant}`}>
      <CurrencyFormat
        decimalScale={decimals}
        fixedDecimalScale={true}
        displayType="text"
        value={amount}
        prefix={prefix}
        thousandSeparator={thousandSep}
        decimalSeparator={decimalSep}
        renderText={formatValue}
      />
    </span>
  );
};

export default Money;
