import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ContentLoader from "../../Common/ContentLoader";
import { useSageLazyQuery } from "../../Common/GqlHooks";
import { SETTINGS_PERSONAL_DOCUMENTS } from "../../../api/graphql";
import LocalizedDate from "../../Common/LocalizedDate";
import Paginator from "../../Common/Paginator";

import "./Settings.scss";

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const MyDocuments = () => {
  const { t } = useTranslation(["settingsDocuments"]);
  const [getUserProfile, loading, data] = useSageLazyQuery(
    SETTINGS_PERSONAL_DOCUMENTS
  );

  useEffect(() => getUserProfile(), [getUserProfile]);

  const download = (url) => window.open(url, "_blank");

  /* Mock Pagination */
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const size = 11;
  useEffect(() => {
    if (!data) return;
    setItems(paginate(data?.me.client.accounts[0].documents, size, 1));
  }, [data]);

  const handleNext = (page, pageSize) => {
    setItems(paginate(data?.me.client.accounts[0].documents, pageSize, page));

    if (
      paginate(data?.me.client.accounts[0].documents, pageSize, page + 1)
        .length === 0
    ) {
      setHasMore(false);
    }
  };

  const handlePrev = (page, pageSize) => {
    setHasMore(true);

    setItems(paginate(data?.me.client.accounts[0].documents, pageSize, page));
  };
  /* Mock Pagination */

  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t("date")}</th>
          <th>{t("document")}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((x, i) => (
          <tr key={i}>
            <td width="40%">
              <LocalizedDate date={x.date} short className="d-md-none" />
              <LocalizedDate date={x.date} className="d-none d-md-inline" />
            </td>
            <td width="60%">
              <Button
                alt="adf"
                className="text-primary flat text-left p-0"
                variant="link"
                size="sm"
                onClick={() => download(x.url)}
              >
                <i className="fa fa-download pr-2 d-none d-md-inline"></i>
                {t(x.type)}
              </Button>
            </td>
          </tr>
        ))}
        {loading && (
          <tr>
            <td colSpan={4}>
              <ContentLoader loading={true} />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td className="p-2" colSpan={4}>
            <div className="d-flex align-items-center">
              <Paginator
                hasMore={hasMore}
                pageSize={size}
                onPrev={handlePrev}
                onNext={handleNext}
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default MyDocuments;
