import { useEffect, useRef } from "react";

export const useSessionStorage = (prefix = "ob") => {
  return [
    (key) => sessionStorage.getItem(`${prefix}_${key}`),
    (key, val) => {
      sessionStorage.setItem(`${prefix}_${key}`, val);
    },
  ];
};

export const cachedVal = (get, obj, key, def = "", cacheKey = null) => {
  key = cacheKey === null ? key : cacheKey;

  if (obj) {
    return obj[key] === "" || obj[key] === null ? get(key) ?? def : obj[key];
  } else return get(key) ?? def;
};

export const useWindowHeight = (offset = 100) => {
  const ref = useRef();

  const setPaneHeight = () => {
    if (!ref.current) return;

    ref.current.style.setProperty(
      "max-height",
      `${window.innerHeight - offset}px`
    );
    ref.current.style.setProperty(
      "min-height",
      `${window.innerHeight - offset}px`
    );
  };

  useEffect(() => {
    window.addEventListener("resize", setPaneHeight, true);

    return () => window.removeEventListener("resize", setPaneHeight, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return [ref, setPaneHeight];
};
