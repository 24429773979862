import { useContext } from "react";

import NavigationItem from "./NavigationItem";
import { OnboardingStoreContext } from "./OnboardingStore";

const Navigation = ({ children, variant = "" }) => {
  const state = useContext(OnboardingStoreContext);

  const renderNavItems = (step, i) => {
    return (
      <NavigationItem
        key={`step_${i}`}
        step={step}
        badge={<span className="step-number">{i + 1}</span>}
        isActive={i < state.currentStep}
      >
        <ul>
          {step.steps &&
            step.steps.map((s) => (
              <NavigationItem
                key={s.id}
                step={s}
                isActive={state.currentStep === s.id}
              />
            ))}
        </ul>
      </NavigationItem>
    );
  };

  const navItems = state.steps.map(renderNavItems);

  return (
    <div
      id="ob-nav-container"
      className={`h-100 d-flex flex-column ${variant}`}
    >
      <ol>{navItems}</ol>
      <div className="mt-auto nav-footer">{children}</div>
    </div>
  );
};

export default Navigation;
