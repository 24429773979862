/* eslint-disable */

export default {
  accCreation: "KONTO",
  intro: "Einführung",
  pInfo: "Persönliche Angaben",
  homeTaxes: "Addresse und Steuern",
  invProfile: "Investor Profil",
  yFinSit: "Finanzielle Situation",
  invAmount: "Anlagebetrag",
  invHoriz: "Anlagehorizont",
  inv: "INVESTMENT",
  undInv: "Investment im Überblick",
  costsBD: "Kosten",
  finSteps: "ABSCHLUSS",
  bankAcc: "Deine Bankverbindung",
  TAC: "Checkout",
  IDVer: "Identitätsprüfung",
  cantFin: "Später fortfahren?",
  saveLog: "Fortschritt speichern und abmelden",
  imprint: "Impressum",
  pPolicy: "Datenschutzrichtlinien",
  cRight: "© Sagefund Digital Wealth Management GmbH 2023",
  cMisc: "Vermittler nach § 2 Abs. 10 KWG der BfV Bank für Vermögen AG",
  iniInv: "Anlagebetrag",
  monthlyInv: "Monatl. Sparplan",
};
