import logo from "../../../assets/img/sagewealth.svg";

import style from "./networkError.module.scss";

const NetworkErrorPage = () => {
  return (
    <div className={style.page}>
      <img src={logo} alt="SageWealth" className={style.logo} />

      <div className={style.container}>
        <div>
          <h2 className={style.textCenter}>
            Our platform can’t be reached at the moment. Please check your
            internet connection and try again.
          </h2>
          <h2 className={style.textCenter}>
            You can also reach us at support@sagewealth.eu
          </h2>
        </div>
        <div className={style.line}></div>
        <div>
          <h2 className={style.textCenter}>
            Unsere Plattform kann im Moment nicht erreicht werden. Bitte
            überprüfe deine Internetverbindung und versuche es erneut.
          </h2>
          <h2 className={style.textCenter}>
            Du kannst uns auch unter support@sagewealth.eu erreichen.
          </h2>
        </div>
      </div>
    </div>
  );
};
export default NetworkErrorPage;
