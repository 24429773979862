import { Card } from "react-bootstrap";

import Logo from "../Common/Logo";

const Sidebar = ({ children }) => {
  return (
    <aside className="pr-0 py-0 py-lg-4 pl-0 pl-lg-4">
      <Card className="d-flex ob-left-nav h-100" bg="secondary">
        <Logo />
        {children}
      </Card>
    </aside>
  );
};

export default Sidebar;
