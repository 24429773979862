import { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useSessionStorage } from "./CommonHooks";

const FormikCustomSelect = (props) => {
  const formik = props.formik;
  const field = props.field;

  // eslint-disable-next-line no-unused-vars
  const [sGet, sSet] = useSessionStorage("ob");

  const handleSelect = (eKey) => {
    sSet(field, eKey);
    formik.setFieldValue(field, eKey);
    if (props.onChange) props.onChange(eKey);
  };

  let newProps = Object.assign({}, props);

  delete newProps.formik;
  delete newProps.field;
  delete newProps.selectedRenderer;
  delete newProps.onChange;

  const Renderer = props.selectedRenderer || TranslatedOption;

  const ref = useRef();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (filter === "") return;

    const nodes = Array.from(
      ref.current
        .querySelector("button")
        .nextElementSibling.querySelectorAll("a")
    );

    const item = nodes.find((x) =>
      x.innerHTML.toLowerCase().startsWith(filter)
    );

    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });

      item.focus();
    } else {
      setFilter("");
    }
  }, [filter]);

  const handleKeypress = useCallback(
    (e) => {
      setFilter((f) => `${f}${e.key}`.toLowerCase());
    },
    [setFilter]
  );

  const handleToggle = (isOpen) => {
    document.removeEventListener("keypress", handleKeypress);
    if (isOpen) {
      document.addEventListener("keypress", handleKeypress);
    } else {
      document.removeEventListener("keypress", handleKeypress);
    }
  };

  return (
    <Dropdown
      className="formik-custom-select"
      {...newProps}
      onSelect={handleSelect}
      onToggle={handleToggle}
      ref={ref}
    >
      <Dropdown.Toggle
        variant="success"
        className={`d-flex align-items-center justify-content-between ${
          formik.errors[field] && formik.touched[field] ? "is-invalid" : ""
        }`}
      >
        <Renderer value={formik.values[field]} />
      </Dropdown.Toggle>

      {props.children}
    </Dropdown>
  );
};

export const TranslatedOption = ({ value, tctx = "constants" }) => {
  const { t } = useTranslation(tctx);

  return <span>{t(value)}</span>;
};

export default FormikCustomSelect;
