export const Currency = Object.freeze({
  EUR: "EUR",
  toString: (curr) => currencies[curr],
  parse: (curr) => {
    switch (curr) {
      case Currency.EUR:
        return Currency.EUR;
      default:
        return null;
    }
  },
});

const currencies = {
  EUR: "€",
};

export const Unit = Object.freeze({
  CENT: "CENT",
  amountFrom: (unit, val) => {
    switch (unit) {
      case Unit.CENT:
        return val / 100;
      default:
        return val;
    }
  },
  parse: (unit) => {
    switch (unit) {
      case Unit.CENT:
        return Unit.CENT;
      default:
        return null;
    }
  },
});
