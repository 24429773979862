import { useTranslation } from "react-i18next";

const Footer = () => {
  return (
    <footer id="main-footer" className="position-fixed d-none flex-column">
      <CopyrightInfo />
    </footer>
  );
};

export const CopyrightInfo = () => {
  const { t } = useTranslation("dashboardLayout");

  return (
    <>
      <span>{t("copyright")}</span>
      <span>Vermittler nach § 2 Abs. 10 KWG der BfV Bank für Vermögen AG</span>
    </>
  );
};

export default Footer;
