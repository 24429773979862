import { useContext, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

import depositImage from "../../../assets/img/dialog/deposit-icon.png";
import depositConfirmImage from "../../../assets/img/dialog/deposit-confirm-icon.png";
import MoneyInput from "../../Common/MoneyInput";
import SageModal from "../../Common/SageModal";
import YesNoModal from "../../Common/YesNoModal";
import { Currency } from "../../../common/money";
import { useSageMutation } from "../../Common/GqlHooks";
import { MAKE_DEPOSIT } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { NotificationStoreContext } from "../../Common/NotificationStore";
import Money from "../../Common/Money";
import { MixpanelEvents } from "../../../common/common";
import { UserStoreContext } from "../../Common/UserStore";

const DepositFunds = ({ disabled, children, iban }) => {
  const currency = Currency.EUR;

  const notifications = useContext(NotificationStoreContext);
  const user = useContext(UserStoreContext);
  const { t } = useTranslation("dashboard");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [depositAmount, setDepositAmount] = useState();
  const [isValid, setIsValid] = useState(false);
  const minDeposit = 25;

  const depositAmountChanged = (values) => {
    const { value } = values;

    if (value === "" || value < minDeposit) {
      setDepositAmount(undefined);
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setDepositAmount(value);
  };

  const handleDeposit = () => {
    mixpanel.track(MixpanelEvents.dashboard.DEPOSIT_AMOUNT_ENTERED, {
      amount: depositAmount,
    });
    setShow(false);
    setShowConfirm(true);
  };

  const handleBack = () => {
    setShow(true);
    setShowConfirm(false);
  };

  const handleCancel = () => {
    setDepositAmount(undefined);
    setIsValid(false);
    setShow(false);
    setShowConfirm(false);
  };

  const showModal = () => {
    mixpanel.track(MixpanelEvents.dashboard.DEPOSIT_MODAL_OPENED);
    setShow(true);
  };

  const [makeDeposit, loading] = useSageMutation(MAKE_DEPOSIT);

  const handleConfirm = () => {
    makeDeposit({
      variables: {
        amount: {
          unit: "CENT",
          currency: currency,
          amount: Math.round(depositAmount * 100),
        },
        description: "Standard Deposit",
      },
    })
      .then(() => {
        handleCancel();
        notifications.addMessage(t("depositInitiated"));
        mixpanel.track(MixpanelEvents.dashboard.DEPOSIT_INITIATED, {
          amount: depositAmount,
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button
        className="mb-2"
        variant="primary"
        size="lg"
        disabled={disabled}
        onClick={showModal}
      >
        {children}
      </Button>

      <SageModal
        show={show}
        title={t("newDeposit")}
        onHide={handleCancel}
        image={depositImage}
        buttons={
          <Button size="lg" onClick={handleDeposit} disabled={!isValid}>
            {t("confirm")}
          </Button>
        }
      >
        <Form validated={false}>
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("toDeposit")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
            <Col xs="auto">
              <MoneyInput
                currency={currency}
                value={depositAmount}
                onValueChange={depositAmountChanged}
                decimalScale={0}
                allowNegative={false}
                className={isValid ? "narrow" : "narrow invalid"}
              />
              <small className="d-block p-0 pt-1 text-secondary text-center">
                <Money
                  variant="text-secondary"
                  value={{
                    amount: minDeposit,
                    currency: Currency.EUR,
                  }}
                />{" "}
                {t("validateWithdraw")}
              </small>
            </Col>
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("toDepositSuffix")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
          </Form.Row>
        </Form>

        <p className="mt-4 mb-1">{t("noteConfirm")}</p>
        <p>
          <strong className="text-numbers">{iban}</strong>
        </p>
      </SageModal>

      <YesNoModal
        title={t("securityInfo")}
        show={showConfirm}
        image={depositConfirmImage}
        onCanceled={handleCancel}
        onBack={handleBack}
        onConfirm={handleConfirm}
      >
        <h5 className="text-dark">
          {t("youAreSet")}{" "}
          <span className="text-success">
            <Money
              variant="text-success"
              value={{
                amount: depositAmount,
                currency: Currency.EUR,
              }}
            />
          </span>{" "}
          {t("inYourInvestment", {
            product_type: user?.product_type?.toLowerCase(),
          })}
        </h5>
        <p className="mt-4 mb-1">{t("depositConfirmFirstNote")}</p>
        <p>{t("depositConfirmSecondNote")}</p>
      </YesNoModal>

      <ContentLoader loading={loading} isInline={false} />
    </>
  );
};

export default DepositFunds;
