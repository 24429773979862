/* eslint-disable */

export default {
  enterEmail:
    "Gib deine E-Mail ein und wir senden dir einen Link, um dein Passwort zurückzusetzen",
  submit: "Senden",
  emailSent:
    "Wir haben dir jetzt eine E-Mail mit einem Link zum Zurücksetzen deines Passworts geschickt (bitte überprüfe auch deinen Spam-Ordner)",
  enterNewPassword: "Bitte gib dein neues Passwort ein",
};
