/* eslint-disable */

export default {
  createAccount: "Create your account",
  setupAccount: "Set up your account and invest in 15 minutes",
  confirmPassword: "Confirm Password",
  submit: "Submit",
  validSamePasswords: "Passwords must be the same",
  minLength: "Contains minimum 8 characters",
  characters: "Contains uppercase and lowercase letter",
  numbers: "Contains number (0-9)",
  specialCharacters: "Contains special character. For example: .+!@#$%()_=^&*",
};
