/* eslint-disable no-prototype-builtins */
import mixpanel from "mixpanel-browser";

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];

    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
};

export const logout = () => {
  mixpanel.reset();
  localStorage.removeItem("user-token");
  sessionStorage.clear();
  window.location.href = "/login";
};

export const globals = {
  minimumInvestment: 1000,
  minimumMonthlyInvestment: 25,
};

const compareCountries = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const flattenObject = (ob) => {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const mapCountriesAndSort = (countries, codes) =>
  codes
    .map((x) => ({
      code: x,
      name: countries.getName(x),
    }))
    .sort(compareCountries);

export const Product = Object.freeze({
  STABILITY: "STABILITY",
  GROWTH: "GROWTH",
});

export const MixpanelEvents = Object.freeze({
  onboarding: {
    SIGN_UP: "Sign up",
    EMAIL_VERIFIED: "Email verified",
    INTRODUCTION_COMPLETED: "Introduction completed",
    PERSONAL_INFORMATION_COMPLETED: "Personal information completed",
    HOME_AND_TAXES_INFORMATION_COMPLETED:
      "Home and taxes information completed",
    YOUR_FINANCIAL_SITUATION_COMPLETED: "Your financial situation completed",
    INVESTMENT_AMOUNT_COMPLETED: "Investment amount completed",
    INVESTMENT_AMOUNT_NOT_ELIGIBLE: "Investment amount not eligible to invest",
    UNDERSTANDING_YOUR_INVESTMENT_COMPLETED:
      "Understanding your investment completed",
    COSTS_BREAKDOWN_COMPLETED: "Costs breakdown completed",
    BANK_DETAILS_COMPLETED: "Bank details completed",
    PROOF_OF_ADDRESS_COMPLETED: "Proof of address completed",
    TERMS_AND_CONDITIONS_COMPLETED: "Terms and conditions completed",
    IDENTITY_VERIFICATION_INITIATED: "Identity verification initiated",
    IDENTITY_VERIFICATION_CHECKING: "Identity verification checking",
  },
  dashboard: {
    DEPOSIT_MODAL_OPENED: "Deposit modal opened",
    DEPOSIT_AMOUNT_ENTERED: "Deposit amount entered",
    DEPOSIT_INITIATED: "Deposit initiated",
    WITHDRAW_MODAL_OPENED: "Withdraw modal opened",
    WITHDRAW_AMOUNT_ENTERED: "Withdraw amount entered",
    WITHDRAW_INITIATED: "Withdraw initiated",
    MONTHLY_SAVINGS_PLAN_INCREASED: "Monthly savings plan increased",
    MONTHLY_SAVINGS_PLAN_DECREASED: "Monthly savings plan decreased",
    MONTHLY_SAVINGS_PLAN_ACTIVATED: "Monthly savings plan activated",
    MONTHLY_SAVINGS_PLAN_DEACTIVATED: "Monthly savings plan deactivated",
  },
});

export const holdings = [
  {
    fundName: "usaLargeComp",
    isin: "IE000OKVTDF7",
    fullName: "ISHARES US VALUE ESG USD A",
    type: "core",
    allocation: {
      STABILITY: "5%",
      GROWTH: "20%",
    },
  },
  {
    fundName: "usaSmallComp",
    isin: "IE00BMDX0L03",
    fullName: "CSIF USA SMALL CAP ESG BLUE",
    type: "core",
    allocation: {
      STABILITY: "5%",
      GROWTH: "20%",
    },
  },
  {
    fundName: "japan",
    isin: "LU2233156749",
    fullName: "AMUN MSCI JAPAN SRI PAB",
    type: "core",
    allocation: {
      STABILITY: "2%",
      GROWTH: "7%",
    },
  },
  {
    fundName: "economyTomorrow",
    isin: "LU1861138961",
    fullName: "AMUNDI MSCI EMER MKT SRI PAB",
    type: "core",
    allocation: {
      STABILITY: "2%",
      GROWTH: "7%",
    },
  },
  {
    fundName: "susRealEstate",
    isin: "LU1861138961",
    fullName: "CSIF NAREIT DVLP GREEN BLUE",
    type: "thematic",
    allocation: {
      STABILITY: "2.5%",
      GROWTH: "8%",
    },
  },
  {
    fundName: "futureHealthcare",
    isin: "LU0292103222",
    fullName: "X MSCI EU HEALTHCARE ESG",
    type: "thematic",
    allocation: {
      STABILITY: "1.5%",
      GROWTH: "6%",
    },
  },
  {
    fundName: "leadDiversity",
    isin: "IE00BD0B9B76",
    fullName: "ISHARES REF INCL & DIVRSTY",
    type: "thematic",
    allocation: {
      STABILITY: "1.5%",
      GROWTH: "6%",
    },
  },
  {
    fundName: "futureFood",
    isin: "IE00BLRPQH31",
    fullName: "RIZE SUSTAINABLE FOOD ETF",
    type: "thematic",
    allocation: {
      STABILITY: "2.5%",
      GROWTH: "8%",
    },
  },
  {
    fundName: "envInnovation",
    isin: "IE00BLRPRR04",
    fullName: "RIZE ENVIRONMENTAL ETF",
    type: "thematic",
    allocation: {
      STABILITY: "2.5%",
      GROWTH: "8%",
    },
  },
  {
    fundName: "disruptTech",
    isin: "LU2023678282",
    fullName: "LYXOR MSCI DISR TECH ESG",
    type: "thematic",
    allocation: {
      STABILITY: "3%",
      GROWTH: "10%",
    },
  },
  {
    fundName: "developBonds",
    isin: "IE00BKRWN659",
    fullName: "ISHARES USD DEV BANK BD USDA",
    type: "fixed",
    allocation: {
      STABILITY: "20%",
      GROWTH: "0%",
    },
  },
  {
    fundName: "globalBonds",
    isin: "LU1981859819",
    fullName: "LYXOR GLOBAL GREEN BOND 1-10",
    type: "fixed",
    allocation: {
      STABILITY: "17%",
      GROWTH: "0%",
    },
  },
  {
    fundName: "europaBonds",
    isin: "LU2356220926",
    fullName: "LYXOR EURO GOV GREEN BOND DR",
    type: "fixed",
    allocation: {
      STABILITY: "15.5%",
      GROWTH: "0%",
    },
  },
  {
    fundName: "corpBonds",
    isin: "IE00028H9QJ8",
    fullName: "X USD CORP GREEN BOND 1C EUR",
    type: "fixed",
    allocation: {
      STABILITY: "20%",
      GROWTH: "0%",
    },
  },
];

export const realReturnsAndCosts = {
  STABILITY: [
    // Advisory and tx
    [
      {
        isCurrency: false,
        value: 0.71,
      },
      {
        isCurrency: false,
        value: 0.41,
      },
    ],
    // Fund costs
    [
      {
        isCurrency: false,
        value: 0.22,
      },
      {
        isCurrency: false,
        value: 0.22,
      },
    ],
    // Est returns
    [
      {
        isCurrency: false,
        value: 4.43,
      },
      {
        isCurrency: false,
        value: 4.43,
      },
    ],
    // Advisory and tx
    [
      {
        isCurrency: false,
        value: 0.93,
      },
      {
        isCurrency: false,
        value: 0.63,
      },
    ],
    // Profit
    [
      {
        isCurrency: false,
        value: 3.5,
      },
      {
        isCurrency: false,
        value: 3.8,
      },
    ],
  ],
  GROWTH: [
    // Advisory and tx
    [
      {
        isCurrency: false,
        value: 0.71,
      },
      {
        isCurrency: false,
        value: 0.41,
      },
    ],
    // Fund costs
    [
      {
        isCurrency: false,
        value: 0.28,
      },
      {
        isCurrency: false,
        value: 0.28,
      },
    ],
    // Est returns
    [
      {
        isCurrency: false,
        value: 7.99,
      },
      {
        isCurrency: false,
        value: 7.99,
      },
    ],
    // Advisory and tx
    [
      {
        isCurrency: false,
        value: 0.99,
      },
      {
        isCurrency: false,
        value: 0.69,
      },
    ],
    // Profit
    [
      {
        isCurrency: false,
        value: 7.0,
      },
      {
        isCurrency: false,
        value: 7.3,
      },
    ],
  ],
};

export const costsBreakdown = {
  STABILITY: [
    {
      item: "regcompl",
      serviceProvider: "Bank für Vermogen",
      tier1: {
        isCurrency: false,
        value: 0.06,
      },
      tier2: {
        isCurrency: false,
        value: 0.06,
      },
    },
    {
      item: "transfeeacc",
      serviceProvider: "Baader Bank",
      tier1: {
        isCurrency: false,
        value: 0.2,
      },
      tier2: {
        isCurrency: false,
        value: 0.2,
      },
    },
    {
      item: "portfmngmt",
      serviceProvider: "SageWealth",
      tier1: {
        isCurrency: false,
        value: 0.45,
      },
      tier2: {
        isCurrency: false,
        value: 0.15,
      },
    },
    {
      item: "prodcost",
      serviceProvider: "ETFter",
      tier1: {
        isCurrency: false,
        value: 0.22,
      },
      tier2: {
        isCurrency: false,
        value: 0.22,
      },
    },
    {
      item: "entexfees",
      serviceProvider: "SageWealth",
      tier1: {
        isCurrency: true,
        value: 0,
      },
      tier2: {
        isCurrency: true,
        value: 0,
      },
    },
  ],
  GROWTH: [
    {
      item: "regcompl",
      serviceProvider: "Bank für Vermogen",
      tier1: {
        isCurrency: false,
        value: 0.06,
      },
      tier2: {
        isCurrency: false,
        value: 0.06,
      },
    },
    {
      item: "transfeeacc",
      serviceProvider: "Baader Bank",
      tier1: {
        isCurrency: false,
        value: 0.2,
      },
      tier2: {
        isCurrency: false,
        value: 0.2,
      },
    },
    {
      item: "portfmngmt",
      serviceProvider: "SageWealth",
      tier1: {
        isCurrency: false,
        value: 0.45,
      },
      tier2: {
        isCurrency: false,
        value: 0.15,
      },
    },
    {
      item: "prodcost",
      serviceProvider: "ETFter",
      tier1: {
        isCurrency: false,
        value: 0.28,
      },
      tier2: {
        isCurrency: false,
        value: 0.28,
      },
    },
    {
      item: "entexfees",
      serviceProvider: "SageWealth",
      tier1: {
        isCurrency: true,
        value: 0,
      },
      tier2: {
        isCurrency: true,
        value: 0,
      },
    },
  ],
};

export const taxLiableCountryCodes = [
  "DE",
  "BE",
  "GB",
  "EE",
  "BG",
  "DK",
  "FI",
  "FR",
  "GR",
  "IE",
  "IT",
  "IS",
  "ET",
  "HR",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "NL",
  "NO",
  "AT",
  "PL",
  "PT",
  "RO",
  "SE",
  "SK",
  "SI",
  "ES",
  "CZ",
  "HU",
  "CY",
];

export const nationalityCountryCodes = [
  "AL",
  "AR",
  "AU",
  "AT",
  "AZ",
  "BE",
  "BF",
  "BD",
  "BG",
  "BA",
  "BN",
  "CA",
  "CH",
  "CL",
  "CN",
  "CG",
  "CO",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EG",
  "ES",
  "EE",
  "FI",
  "FR",
  "GB",
  "GE",
  "GH",
  "GI",
  "GR",
  "HR",
  "HU",
  "IE",
  "IR",
  "IS",
  "IL",
  "IT",
  "IN",
  "ID",
  "JP",
  "KH",
  "KR",
  "KW",
  "LA",
  "LB",
  "LI",
  "LT",
  "LU",
  "LV",
  "MA",
  "MC",
  "MD",
  "MV",
  "MX",
  "MK",
  "MT",
  "ME",
  "MY",
  "NL",
  "NO",
  "NZ",
  "OM",
  "PH",
  "PL",
  "PT",
  "PY",
  "XK",
  "RO",
  "RU",
  "RW",
  "SD",
  "SG",
  "SV",
  "SM",
  "RS",
  "SK",
  "SI",
  "SE",
  "TH",
  "TL",
  "TN",
  "TR",
  "TW",
  "TZ",
  "UA",
  "US",
  "UZ",
  "ZA",
  "VA",
];

export const horizon = Object.freeze({
  [Product.STABILITY]: { SHORT_TERM: 3, MID_TERM: 7, LONG_TERM: 15 },
  [Product.GROWTH]: {
    SHORT_TERM: 7,
    MID_TERM: 15,
    LONG_TERM: 30,
  },
});
