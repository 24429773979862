import { useTranslation } from "react-i18next";

import doneImage from "../../../assets/img/onboarding/completed.png";

const OnboardingComplete = () => {
  const { t } = useTranslation("obdCompleted");

  return (
    <div className="p-3 ob-note d-flex align-items-center justify-content-center h-100 w-100 scrollable-y">
      <div className="d-inline-block obd-complete-notice text-center w-100">
        <img src={doneImage} alt="" width="230" className="" />
        <h5 className="text-success">{t("amazingWork")}</h5>
        <p>{t("note1")}</p>
        <p>
          <b>{t("h1")}</b>
        </p>
        <p>{t("note2")}</p>
        <p>{t("note3")}</p>
        <p className="text-accent">{t("closeWindow")}</p>
      </div>
    </div>
  );
};

export default OnboardingComplete;
