// eslint-disable-next-line import/no-anonymous-default-export
export default {
  yes: "Yes",
  no: "No",
  page: "Page",
  next: "Next",
  previous: "Previous",
  core: "Core - Equity",
  thematic: "Thematic - Equity",
  fixed: "Fixed Income",
  disruptTech: "Disruptive Technology",
  futureFood: "Future of Food",
  usaLargeComp: "USA Large Companies",
  usaSmallComp: "Small and medium sized US companies",
  japan: "Japan",
  economyTomorrow: "Economies of tomorrow",
  susRealEstate: "Sustainable real-estate",
  futureHealthcare: "Future of healthcare",
  leadDiversity: "Leaders in diversity",
  envInnovation: "Environmental innovation",
  developBonds: "Development bank bonds",
  globalBonds: "Global green bonds",
  europaBonds: "European green bonds",
  corpBonds: "Corporate green bonds",
};
