import { Route, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import DashboardExplore from "./components/Dashboard/Explore";
import NotificationToasts from "./components/Common/NotificationToasts";
import DashboardLanding from "./components/Dashboard/Landing/DashboardLanding";
import DashboardSettings from "./components/Dashboard/Settings/DashboardSettings";
import UserStore from "./components/Common/UserStore";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import ForgotPassword from "./components/Auth/ForgotPassword";
import VerifyEmail from "./components/Auth/VerifyEmail";
import Onboarding from "./components/Onboarding/Onboarding";
import OnboardingStore from "./components/Onboarding/OnboardingStore";
import ResetPassword from "./components/Auth/ResetPassword";
import NotFound from "./components/Common/NotFound";
import BackofficeLayout from "./components/Backoffice/BackofficeLayout";
import DashboardLayout from "./components/Dashboard/DashboardLayout";
import DashboardAdvice from "./components/Dashboard/Advice";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

function App() {
  return (
    <>
      <Switch>
        <Route path="/onboarding">
          <OnboardingStore>
            <Onboarding />
          </OnboardingStore>
        </Route>

        <Route path="/login" exact={true}>
          <Login />
        </Route>

        <Route path="/forgot-password" exact={true}>
          <ForgotPassword />
        </Route>

        <Route path="/reset-password/:token" exact={true}>
          <ResetPassword />
        </Route>

        <Route path="/register" exact={true}>
          <Register />
        </Route>

        <Route path="/verify-email/:token" exact={true}>
          <VerifyEmail />
        </Route>

        <Route path="/dashboard" exact={true}>
          <UserStore>
            <DashboardLayout>
              <DashboardLanding />
            </DashboardLayout>
          </UserStore>
        </Route>

        <Route path="/" exact={true}>
          <Redirect to="/dashboard" />
        </Route>

        <Route path="/dashboard/settings" exact={true}>
          <UserStore>
            <DashboardLayout>
              <DashboardSettings />
            </DashboardLayout>
          </UserStore>
        </Route>
        <Route path="/dashboard/explore" exact={true}>
          <UserStore>
            <DashboardLayout removeContainerStyle>
              <DashboardExplore />
            </DashboardLayout>
          </UserStore>
        </Route>

        <Route path="/dashboard/advice" exact={true}>
          <UserStore>
            <DashboardLayout removeContainerStyle>
              <DashboardAdvice />
            </DashboardLayout>
          </UserStore>
        </Route>

        <Route path="/backoffice">
          <BackofficeLayout />
        </Route>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>

      <NotificationToasts />
    </>
  );
}

export default App;
