import { useTranslation } from "react-i18next";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { validatePassword } from "../../common/validations";
import ContentLoader from "../../components/Common/ContentLoader";
import { useSageMutation } from "../../components/Common/GqlHooks";
import { RESET_PASSWORD } from "../../api/graphql";

import AuthLayout from "./AuthLayout";
import PasswordMatcher from "./PasswordMatcher";

const ResetPassword = () => {
  const [formSent, setFormSent] = useState(false);
  const { t } = useTranslation(["register", "forgotPassword", "auth"]);
  const { token } = useParams();

  const validate = (values) => {
    const errors = {};

    if (!values.newPass || !validatePassword(values.newPass)) {
      errors.newPass = true;
    }

    if (!values.newPassConfirm || values.newPass !== values.newPassConfirm) {
      errors.newPassConfirm = true;
    }

    return errors;
  };

  const [reset, loading] = useSageMutation(RESET_PASSWORD);

  const onSubmit = (values) => {
    setFormSent(true);

    reset({
      variables: {
        new_password: values.newPassConfirm,
        token: token,
      },
    })
      .then(() => {
        window.open("/login", "_self");
      })
      .catch((e) => {
        setFormSent(false);
        console.log(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      newPass: "",
      newPassConfirm: "",
    },
    validate,
    onSubmit,
  });

  return (
    <AuthLayout>
      <Col
        className="right-pane d-flex justify-content-center"
        id="auth-right-pane"
      >
        <Row className="justify-content-center w-100 mb-auto mt-auto">
          <Col xs={12} md={8} lg={8}>
            <div className="pt-0 pr-3 pb-3 pl-3">
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="text-center">
                  <Form.Label className="mb-0 auth-title">
                    {t("forgotPassword:enterNewPassword")}
                  </Form.Label>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-1">
                    {t("auth:password")}
                  </Form.Label>
                  <Form.Control
                    className="lg"
                    name="newPass"
                    type="password"
                    value={formik.values.newPass}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={!formik.errors.newPass && formik.touched.newPass}
                    isInvalid={formik.errors.newPass && formik.touched.newPass}
                  />
                  {formik.errors.newPass && formik.values.newPass && (
                    <PasswordMatcher password={formik.values.newPass} />
                  )}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-1">
                    {t("confirmPassword")}
                  </Form.Label>

                  <Form.Control
                    className="lg"
                    name="newPassConfirm"
                    type="password"
                    value={formik.values.newPassConfirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={
                      !formik.errors.newPassConfirm &&
                      formik.touched.newPassConfirm
                    }
                    isInvalid={
                      formik.errors.newPassConfirm &&
                      formik.touched.newPassConfirm
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("validSamePasswords")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  type="submit"
                  size="lg"
                  disabled={!formik.isValid}
                  className="flat mt-4 w-100"
                >
                  {t("forgotPassword:submit")}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Col>
      <ContentLoader loading={loading || formSent} isInline={false} />
    </AuthLayout>
  );
};

export default ResetPassword;
