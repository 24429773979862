import { Table, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { TransactionType } from "../../../common/transactions";
import LocalizedDate from "../../Common/LocalizedDate";
import Money from "../../Common/Money";
import { useSageLazyQuery } from "../../Common/GqlHooks";
import { MY_TRANSACTIONS } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import Paginator from "../../Common/Paginator";

const paginate = (array, page_size, page_number) => {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
};

const MyTransactions = () => {
  const { t } = useTranslation(["settings", "dashboard"]);
  const [getTransactions, loading, data] = useSageLazyQuery(MY_TRANSACTIONS);

  useEffect(() => getTransactions(), [getTransactions]);

  /* Mock Pagination */
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const size = 11;
  useEffect(() => {
    if (!data) return;
    setItems(paginate(data?.getMyAccountTransactions, size, 1));
  }, [data]);

  const handleNext = (page, pageSize) => {
    setItems(paginate(data?.getMyAccountTransactions, pageSize, page));

    if (
      paginate(data?.getMyAccountTransactions, pageSize, page + 1).length === 0
    ) {
      setHasMore(false);
    }
  };

  const handlePrev = (page, pageSize) => {
    setHasMore(true);

    setItems(paginate(data?.getMyAccountTransactions, pageSize, page));
  };
  /* Mock Pagination */

  return (
    <Table hover>
      <thead>
        <tr>
          <th>{t("txDate")}</th>
          <th className="text-left p-0">{t("txType")}</th>
          <th className="text-right">{t("txAmount")}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((t, i) => (
          <TransactionRow
            key={i}
            date={t.created_on}
            type={t.type}
            amount={t.amount}
            status={t.status}
          />
        ))}
        {loading && (
          <tr>
            <td colSpan={4}>
              <ContentLoader loading={true} />
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td className="p-2" colSpan={4}>
            <div className="d-flex align-items-center">
              <Paginator
                hasMore={hasMore}
                pageSize={size}
                onPrev={handlePrev}
                onNext={handleNext}
              />
            </div>
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

const TransactionRow = ({ date, type, amount, status }) => {
  const { t } = useTranslation("settings");
  let variant = "";
  let textVariant = "";
  let amountPrefix = "";

  switch (type) {
    case TransactionType.MONTHLY_DEPOSIT:
      variant = "info";
      textVariant = "primary";
      break;
    case TransactionType.DEPOSIT:
      variant = "primary";
      textVariant = variant;
      break;
    case TransactionType.WITHDRAW:
      amountPrefix = "-";
      variant = "accent";
      textVariant = variant;
      break;
    case TransactionType.MONTHLY_FEE:
      amountPrefix = "-";
      break;
    case TransactionType.MANAGEMENT_FEE:
      amountPrefix = "-";
      break;
    case TransactionType.TRADING_FEE:
      amountPrefix = "-";
      break;
    default:
      break;
  }

  let txVariant = "";

  switch (status) {
    case "FAILED":
      txVariant = "text-danger";
      textVariant = "danger";
      break;
    case "PROCESSING":
      txVariant = "text-gray";
      break;
    default:
      txVariant = "text-primary";
  }

  return (
    <tr>
      <td width="24%">
        <span>
          <LocalizedDate date={date} short className="mr-1" />
        </span>
        {status !== "SUCCESSFUL" && (
          <Badge className={`p-0 tx-badge ${txVariant}`} variant="transparent">
            ({t(`dashboard:tx${status}`)})
          </Badge>
        )}
      </td>
      <td width="24%" className="text-left p-0 py-2">
        <Badge className="m-1" variant={variant}>
          {t(type)}
        </Badge>
      </td>
      <td width="20%" className="text-right">
        <strong>
          <span className={`text-${textVariant}`}>{amountPrefix}</span>
          <Money variant={`text-${textVariant}`} value={amount} />
        </strong>
      </td>
    </tr>
  );
};

export default MyTransactions;
