import * as moment from "moment";
import { useContext } from "react";
import { Col, Form, Dropdown, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import {
  SalutationKind,
  TitleKind,
  MaritalStatusKind,
  EmploymentStatusKind,
  EducationKind,
} from "../../../common/client";
import { IndustriesKind } from "../../../common/industries";
import ReqAsterisk from "../../Common/ReqAsterisk";
import SelectOptions from "../../Common/SelectOptions";
import { OnboardingStoreContext } from "../OnboardingStore";
import FormikFormControl from "../../Common/FormikFormControl";
import FormikCustomSelect from "../../Common/FormikCustomSelect";
import { useSageMutation } from "../../Common/GqlHooks";
import { OB_SAVE_PERSONAL_INFO } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import SageDatePicker from "../../Common/SageDatePicker";
import { useSessionStorage } from "../../Common/CommonHooks";
import { mapCountriesAndSort, MixpanelEvents } from "../../../common/common";

import Step from "./Step";
import StepButtons from "./StepButtons";
import StepContent from "./StepContent";
import StepHeader from "./StepHeader";

const countries = require("country-list");

const PersonalInformation = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const [sGet, sSet] = useSessionStorage("ob");

  const handlePrev = () => {
    obStore.back();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.salutation) errors.salutation = true;
    if (!values.first_name) errors.first_name = true;
    if (!values.last_name) errors.last_name = true;
    if (!values.date_of_birth) errors.date_of_birth = true;
    if (!values.country_of_birth) errors.country_of_birth = true;
    if (!values.city_of_birth) errors.city_of_birth = true;
    if (!values.mobile_phone || values.mobile_phone.length < 10)
      errors.mobile_phone = true;
    if (!values.marital_status) errors.marital_status = true;
    if (!values.education) errors.education = true;

    if (!values.employment_status) {
      errors.employment_status = true;
    } else if (
      (values.employment_status === "EMPLOYED" ||
        values.employment_status === "SELF_EMPLOYED") &&
      !values.industry
    ) {
      errors.industry = true;
    }

    return errors;
  };

  const [submit, loading] = useSageMutation(OB_SAVE_PERSONAL_INFO);

  const onSubmit = (values) => {
    const variables = {
      ...values,
      title: values.title === "" ? "NONE" : values.title,
      mobile_phone:
        values.mobile_phone === "" ? null : "+" + values.mobile_phone,
      work_information: {
        employment_status: values.employment_status,
        industry: values.industry === "" ? null : values.industry,
      },
    };

    delete variables.employment_status;
    delete variables.industry;

    submit({
      variables,
    })
      .then(() => {
        obStore.refresh();
        mixpanel.track(
          MixpanelEvents.onboarding.PERSONAL_INFORMATION_COMPLETED
        );
        obStore.next();
      })
      .catch((e) => console.log(e));
  };

  const handleEmploymentChange = () => {
    formik.setFieldValue("industry", "");
  };

  const countryRenderer = ({ value }) => {
    return <span>{countries.getName(value)}</span>;
  };

  const handleMobilePhoneChange = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    sSet("mobile_phone", e.target.value);
    formik.handleChange(e);
  };

  const handleChangeDob = (d) => {
    formik.setFieldValue("date_of_birth", d);
  };

  const val = (obj, key, def = "") => {
    if (obj) {
      return obj[key] === "" || obj[key] === null ? sGet(key) ?? def : obj[key];
    } else return sGet(key) ?? def;
  };

  const maxDate = () => {
    let date = new moment();
    return date.subtract(18, "years");
  };

  const formik = useFormik({
    initialValues: {
      salutation: val(obStore.me.client, "salutation"),
      title: val(obStore.me.client, "title", "NONE"),
      first_name: val(obStore.me.client, "first_name"),
      last_name: val(obStore.me.client, "last_name"),
      date_of_birth: val(obStore.me.client, "date_of_birth"),
      marital_status: val(obStore.me.client, "marital_status"),
      country_of_birth: val(obStore.me.client, "country_of_birth"),
      city_of_birth: val(obStore.me.client, "city_of_birth"),
      mobile_phone: obStore.me.client.mobile_phone
        ? obStore.me.client.mobile_phone.substring(1)
        : sGet("mobile_phone") || "",
      education: val(obStore.me.client, "education"),
      employment_status: val(
        obStore.me.client.work_information,
        "employment_status"
      ),
      industry: val(obStore.me.client.work_information, "industry"),
    },
    validateOnBlur: false,
    validate,
    onSubmit,
  });

  const handleNext = () => {
    formik.submitForm();
  };

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>{t("pInfoSub")}</StepHeader>

      <StepContent>
        <Form noValidate className="mt-5">
          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label className="required">
                  {t("salutation")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect formik={formik} field="salutation">
                  <SelectOptions kind={SalutationKind} />
                </FormikCustomSelect>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>{t("title")}</Form.Label>
                <FormikCustomSelect formik={formik} field="title">
                  <SelectOptions kind={TitleKind}>
                    <Dropdown.Item eventKey="">---</Dropdown.Item>
                  </SelectOptions>
                </FormikCustomSelect>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("fName")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="first_name" />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("lName")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="last_name" />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("dOB")}
                  <ReqAsterisk />
                </Form.Label>
                <SageDatePicker
                  handleChange={handleChangeDob}
                  defaultValue={formik.values.date_of_birth}
                  maxDate={maxDate()}
                  formik={formik}
                  field="date_of_birth"
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("mStatus")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect formik={formik} field="marital_status">
                  <SelectOptions kind={MaritalStatusKind} />
                </FormikCustomSelect>
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("countryOB")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect
                  selectedRenderer={countryRenderer}
                  formik={formik}
                  field="country_of_birth"
                >
                  <Dropdown.Menu>
                    {mapCountriesAndSort(countries, countries.getCodes()).map(
                      (c) => (
                        <Dropdown.Item key={c.code} eventKey={c.code}>
                          {c.name}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </FormikCustomSelect>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("cityOB")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikFormControl formik={formik} field="city_of_birth" />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("mob")}
                  <ReqAsterisk />
                </Form.Label>
                <InputGroup.Prepend>
                  <InputGroup.Text>+</InputGroup.Text>
                </InputGroup.Prepend>
                <FormikFormControl
                  onChange={handleMobilePhoneChange}
                  formik={formik}
                  field="mobile_phone"
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("edu")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect formik={formik} field="education">
                  <SelectOptions kind={EducationKind} />
                </FormikCustomSelect>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>
                  {t("emStatus")}
                  <ReqAsterisk />
                </Form.Label>
                <FormikCustomSelect
                  onChange={handleEmploymentChange}
                  formik={formik}
                  field="employment_status"
                >
                  <SelectOptions kind={EmploymentStatusKind} />
                </FormikCustomSelect>
              </Form.Group>
            </Col>
            {(formik.values.employment_status === "EMPLOYED" ||
              formik.values.employment_status === "SELF_EMPLOYED") && (
              <Col xs={12} md={4}>
                <Form.Group>
                  <Form.Label>
                    {t("ind")}
                    <ReqAsterisk />
                  </Form.Label>
                  <FormikCustomSelect formik={formik} field="industry">
                    <SelectOptions kind={IndustriesKind} />
                  </FormikCustomSelect>
                </Form.Group>
              </Col>
            )}
          </Form.Row>
        </Form>
      </StepContent>
      <StepButtons onPrev={handlePrev} onNext={handleNext} />
      <ContentLoader loading={loading} isInline={false} />
    </Step>
  );
};

export default PersonalInformation;
