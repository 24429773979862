import { Button, ButtonGroup } from "react-bootstrap";

const TypeFilter = (props) => {
  const { data, typeFilter, setTypeFilter } = props;

  const typeSet = new Set(data?.map((row) => row?.type));
  const typeArray = ["All", ...typeSet];

  return (
    <div className="d-flex align-items-center my-2">
      <p className="my-0 mx-2">Filter by type:</p>
      {data && data.length > 0 ? (
        <ButtonGroup aria-label="Status Filter" size="sm">
          {typeArray.map((type, i) => (
            <Button
              key={i}
              onClick={() => setTypeFilter(type)}
              variant={typeFilter === type ? "primary" : "light"}
            >
              {type}
            </Button>
          ))}
        </ButtonGroup>
      ) : (
        <p className="my-0">Nothing to filter</p>
      )}
    </div>
  );
};

export default TypeFilter;
