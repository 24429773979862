import { useSessionStorage } from "./CommonHooks";
import MoneyInput from "./MoneyInput";

const FormikMoneyInput = (props) => {
  const formik = props.formik;
  const field = props.field;

  // eslint-disable-next-line no-unused-vars
  const [sGet, sSet] = useSessionStorage("ob");

  let newProps = Object.assign({}, props);

  delete newProps.formik;
  delete newProps.field;

  const handleChange = (e) => {
    const val = e.value === "" ? "" : parseInt(e.value);

    sSet(field, val);
    formik.setFieldValue(field, val);
  };

  return (
    <MoneyInput
      value={formik.values[field]}
      onValueChange={handleChange}
      decimalScale={0}
      className={
        formik.errors[field] && formik.touched[field]
          ? "narrow invalid money-input"
          : "narrow monety-input money-input"
      }
      {...newProps}
    />
  );
};

export default FormikMoneyInput;
