import { useIdleTimer } from "react-idle-timer";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotificationStoreContext } from "../../components/Common/NotificationStore";

const useAuthTimeout = () => {
  let history = useHistory();
  const notifications = useContext(NotificationStoreContext);
  const { t } = useTranslation("auth");

  const handleActivity = () => {
    const tm = getRemainingTime();

    if (tm === 0) endSession();
  };

  const endSession = () => {
    console.log("logging out due to inactivity");

    localStorage.removeItem("user-token");
    sessionStorage.clear();

    notifications.addInfo(t("inactivityNotice"));

    history.push("/login");
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleActivity,
    onActive: handleActivity,
    debounce: 1000,
    crossTab: {
      emitOnAllTabs: true,
    },
  });
};

export default useAuthTimeout;
