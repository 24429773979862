/* eslint-disable */

export default {
  intro: "Einführung",
  pInfo: "Persönliche Daten",
  finSit: "Finanzielle Situation",
  invAmount: "Anlagebetrag",
  invHor: "Anlagehorizont",
  yInv: "Dein Investment",
  costs: "Kosten",
  conBankAcc: "Verbinde dein Bankkonto",
  TAC: "Geschäftsbedingungen",
  vInd: "Identitätsprüfung",
  checkIbx: "Wir haben dir eine E-Mail geschickt!",
  bfPrcd: "Bitte klicke den Link in deiner E-mail, um diese zu bestätigen.",
  inCase: "Und vergiss nicht, in den Spam-Ordner zu schauen ;)",
  notRec: "Klicke hier, wenn unsere E-Mail nicht eingegangen ist.",
  verifyEmailResent: "Verifizierungs-E-Mail wurde erneut gesendet",
  mobileObTitle: "Handy-Onboarding ist noch im Aufbau",
  mobileObLineOne:
    "Wir haben dir eine E-Mail zur Verifizierung deiner Adresse geschickt, aber bitte öffne diese auf deinem Desktop-Computer.",
  mobileObLineTwo:
    "Sobald dein Konto eröffnet ist, kannst du dich mit deinem Handy einloggen und deine Investment dort verfolgen.",
  mobileObLineThree: "Vielen Dank für dein Verständnis.",
};
