import React, { useState } from "react";

export const NotificationStoreContext = React.createContext();

const NotificationStore = ({ children }) => {
  const [notifications, setNotifications] = useState({
    messages: [],
    info: [],
    errors: [],
  });

  const addError = (err) => {
    if (notifications.errors.find((e) => e === err)) return;

    setTimeout(() => {
      setNotifications((n) => {
        var errs = n.errors;

        errs.push(err);

        return {
          ...n,
          errors: errs,
        };
      });
    }, 500);
  };

  const addMessage = (msg) => {
    if (notifications.messages.find((m) => m === msg)) return;

    setNotifications((n) => {
      var msgs = n.messages;

      msgs.push(msg);

      return {
        ...n,
        messages: msgs,
      };
    });
  };

  const addInfo = (msg) => {
    if (notifications.info.find((m) => m === msg)) return;

    setNotifications((n) => {
      var msgs = n.info;

      msgs.push(msg);

      return {
        ...n,
        info: msgs,
      };
    });
  };

  const clearInfo = () =>
    setNotifications((n) => ({
      ...n,
      info: [],
    }));

  const clearMessages = () =>
    setNotifications((n) => ({
      ...n,
      messages: [],
    }));

  const clearErrors = () =>
    setNotifications((n) => ({
      ...n,
      errors: [],
    }));

  return (
    <NotificationStoreContext.Provider
      value={{
        ...notifications,
        addError,
        addMessage,
        clearMessages,
        clearErrors,
        addInfo,
        clearInfo,
      }}
    >
      {children}
    </NotificationStoreContext.Provider>
  );
};

export default NotificationStore;
