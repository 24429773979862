import FormikFormCheck from "./FormikFormCheck";

const FormikRadioCard = (props) => {
  const newProps = Object.assign({}, props);

  delete newProps.children;

  const label = <div className="w-100">{props.children}</div>;

  return (
    <div className="card-radio flex-grow-1" role="group">
      <FormikFormCheck
        custom
        inline
        type="radio"
        className="p-0 w-100"
        label={label}
        {...newProps}
      />
    </div>
  );
};

export default FormikRadioCard;
