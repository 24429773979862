import { Accordion, Card } from "react-bootstrap";

const AccordionHeader = ({ eventKey, activeKey, onSelect, children }) => {
  const handleClick = () => {
    if (onSelect) {
      if (eventKey === activeKey) onSelect(null);
      else onSelect(eventKey);
    }
  };

  return (
    <Accordion.Toggle
      onClick={handleClick}
      as={Card.Header}
      eventKey={eventKey}
      className="d-flex justify-content-between align-items-center"
    >
      <span className="title">{children}</span>
      {eventKey === activeKey && (
        <i className="fa fa-chevron-up text-primary"></i>
      )}
      {eventKey !== activeKey && (
        <i className="fa fa-chevron-down text-primary"></i>
      )}
    </Accordion.Toggle>
  );
};

export default AccordionHeader;
