import "./InvestmentCard.scss";
import greenLeaf from "../../assets/img/onboarding/konoha.png";
import purpleLeaf from "../../assets/img/onboarding/konoha-purple.png";

const InvestmentCard = ({ children, variant }) => {
  return (
    <div className={`investment-card ${variant}`}>
      <p>{children}</p>
      <figure>
        <img src={variant === "accent" ? purpleLeaf : greenLeaf} alt="" />
      </figure>
    </div>
  );
};

export default InvestmentCard;
