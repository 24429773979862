import { useEffect } from "react";

const Step = ({ children, variant }) => {
  useEffect(() => {
    document.querySelector("body").scrollTop = 0;
  }, []);

  return <div className={`step px-4 pt-1 pt-lg-4 ${variant}`}>{children}</div>;
};

export default Step;
