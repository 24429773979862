// eslint-disable-next-line import/no-anonymous-default-export
export default {
  verificationSuccess:
    "Email verification successful - For security reasons, please login again as you seemed to have switched devices",
  inactivityNotice: "You have been logged out due to inactivity.",
  email: "Email",
  password: "Password",
  validEmail: "It must be a valid email address",
  validPassword:
    "Password must be combination of lowercase, uppercase, numeric, special and at least 8 characters",
  login: "Login",
  register: "Register",
  betaNoticeTitle: "SageWealth is in beta",
  betaNotice:
    "This means that all our core features and investment system has been tested and approved by our banking partners. However, you may still find some design issues. If you find any, please report them in the chat below",
};
