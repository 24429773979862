const StepHeader = ({ title, children }) => {
  return (
    <>
      <h4 className="text-accent">{title}</h4>
      <p>{children}</p>
      <div className="step-separator mt-4"></div>
    </>
  );
};

export default StepHeader;
