/* eslint-disable */

export default {
  amazingWork: "You are done!",
  note1:
    "Your information has now been submitted, and we are in the process of opening your account.",
  h1: "What Happens now?",
  note2:
    "It will take 6-8 working days for your account to be opened and your money invested.",
  note3: "We will notify you via email when everything is ready!",
  closeWindow: "You can now close this window.",
};
