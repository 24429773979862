import { Form } from "react-bootstrap";

import { useSessionStorage } from "./CommonHooks";

const FormikFormCheck = (props) => {
  const formik = props.formik;
  const field = props.field;

  const [sGet, sSet] = useSessionStorage("ob");

  const newProps = Object.assign({}, props);

  delete newProps.onChange;

  const handleChange = (e) => {
    if (sGet(field) === e.target.value) sSet(field, "");
    else sSet(field, e.target.value);

    formik.setFieldValue(field, e.target.value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <Form.Check
      name={field}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      isInvalid={formik.errors[field] && formik.touched[field]}
      {...newProps}
    >
      {props.children}
    </Form.Check>
  );
};

export default FormikFormCheck;
