export default {
  investmentTitle: "Your investment:",
  perfectTitle: "Perfect for:",
  average1: "Average",
  average2: "return*",
  average3: "(after fees)",
  years1: "10 year",
  years2: "return",
  horizon1: "Investing",
  horizon2: "horizon",
  volatility: "Volatility",
  emotional1: "Emotional",
  emotional2: "Difficulty",
  high: "High",
  low: "Low",
  light: "Light",
  adventurous: "Adventurous",
  pastPerformance:
    "*Past performance doesn’t predict future growth. Target returns and estimated profits are only a reasonable estimate.",
  year: "year",
  years: "years",
  performanceTitle: "Performance",
  performanceSubtitle:
    "Your investment balance will fluctuate slightly over time. Sometimes down, but mostly up.",
  year5Return: "5 year return",
  year10Return: "10 year return",
  drawdown1: "Max",
  drawdown2: "drawdown",
  portfolioBreakdown: "Portfolio breakdown",
  bonds: "Bonds",
  globalCore: "Global Core",
  innovators: "Innovators",
  largeUS: "Large US companies",
  smallMediumUS: "Small and medium US companies",
  japan: "Japan",
  economiesTomorrow: "Economies of tomorrow",
  developmentBonds: "Development bank bonds",
  greenBonds: "Global green bonds",
  europeanBonds: "European green bonds",
  corporateBonds: "Corporate green bonds",
  sustainRealEstate: "Sustainable real-estate",
  futureHealthcare: "Future of healthcare",
  leadersDiversity: "Leaders in diversity",
  futureFood: "Future of food",
  environmentInnovation: "Environmental innovation",
  disruptiveTechnology: "Disruptive technology",
  stabilitySubtitle:
    "First-time investors and protecting your money against inflation",
  growthSubtitle:
    "Investors who can handle volatility and growing wealth long-term",
  stabilityText1:
    "Stability is made of a mixture of sustainable ETF bonds, stocks, real estate, and innovations (e.g. diversity, healthcare, food).",
  stabilityText2:
    "Each ETF meets the EU standards for sustainability (article 8 & 9) and has strong risk-adjusted returns.",
  stabilityText3:
    "The heavy allocation in bonds provides the stability; the stocks provide growth; and the rest is for sound diversification.",
  stabilityText4:
    "In order to ensure optimal balance and diversification over time, your investment is rebalanced annually, and your dividends automatically reinvested.",
  growthText1:
    "Growth is primarily made up of global stocks, with a healthy dose of real estate and commodities (clean energy, timber, and carbon credits).",
  growthText2:
    "Each ETF meets the EU standards for sustainability (article 8 & 9) and has strong risk-adjusted returns.",
  growthText3:
    "The heavy allocation in stocks provide the strong returns, while commodities and real-estate deliver sound diversification.",
  growthText4:
    "In order to ensure optimal balance and diversification over time, your investment is rebalanced annually, and your dividends automatically reinvested.",
};
