/* eslint-disable */

export default {
  dashboard: "Übersicht",
  settings: "Einstellungen",
  logout: "Abmelden",
  copyright:
    "© 2023 Sagefund Digital Wealth Management GmbH. Alle Rechte vorbehalten",
  explore: "Entdecken",
  advice: "Beratung",
};
