import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import "./InfoCard.scss";
import greenLeaf from "../../assets/img/onboarding/konoha.png";

const InfoCard = ({ children, variant, leaf }) => {
  if (leaf) {
    variant = `${variant}`;
  }

  return (
    <Card className={`info-card ${variant}`}>
      {leaf && (
        <figure className="leaf">
          <img src={greenLeaf} alt="" />
        </figure>
      )}
      <Row>
        {React.Children.map(children, (ch, i) => (
          <React.Fragment key={i}>
            {ch && (
              <>
                <Col xs={12} md={true}>
                  {ch}
                </Col>
                <Col className="sep">
                  <div></div>
                </Col>
              </>
            )}
          </React.Fragment>
        ))}
      </Row>
    </Card>
  );
};

export default InfoCard;
