import { gql } from "@apollo/client";

export const CLIENT_INVESTMENT_INFO = gql`
  query {
    me {
      id
      client {
        id
        first_name
        last_name
        status
        accounts {
          product
          earned_percentage
          invested_amount {
            amount
            currency
            unit
          }
          earned_amount {
            amount
            currency
            unit
          }
          earned_percentage
          investment_amount {
            amount
            currency
            unit
          }
          monthly_savings_plan {
            amount {
              amount
              unit
              currency
            }
            day_of_month
          }
        }
        reference_bank_account {
          iban
        }
      }
    }
  }
`;

export const MAKE_DEPOSIT = gql`
  mutation ($amount: MonetaryValueInput!, $description: String!) {
    makeMyDeposit(amount: $amount, description: $description)
  }
`;

export const WITHDRAW = gql`
  mutation ($amount: MonetaryValueInput!, $description: String!) {
    makeMyWithdrawal(amount: $amount, description: $description)
  }
`;

export const UPDATE_MONTHLY_PLAN = gql`
  mutation ($savings_plan: MonthlySavingsPlanInput) {
    updateMyMonthlySavingsPlan(savings_plan: $savings_plan)
  }
`;

export const ACCOUNT_PORTFOLIO_PERFORMANCE = gql`
  query getMyAccountPortfolioPerformance(
    $step: GraphStepKind
    $from: DateTime
  ) {
    getMyAccountPortfolioPerformance(step: $step, from: $from) {
      dates
      values
    }
  }
`;

export const ACCOUNT_TOTAL_EARNINGS = gql`
  query getMyAccountTotalEarnings($step: GraphStepKind, $from: DateTime) {
    getMyAccountTotalEarnings(step: $step, from: $from) {
      dates
      values
    }
  }
`;

export const SETTINGS_PERSONAL_INFO = gql`
  query {
    me {
      id
      email
      two_factor_auth
      client {
        id
        status
        first_name
        last_name
        mobile_phone
        home_addresses {
          state
          street
          street_number
          postal_code
          country
        }
        reference_bank_account {
          iban
        }
        accounts {
          documents {
            type
            name
            description
            date
            uploaded_on
            url
          }
        }
      }
    }
  }
`;

export const SETTINGS_PERSONAL_DOCUMENTS = gql`
  query {
    me {
      id
      client {
        id
        first_name
        last_name
        accounts {
          documents {
            type
            name
            description
            date
            uploaded_on
            url
          }
        }
      }
    }
  }
`;

export const INITIATE_2FA = gql`
  mutation ($action: TwoFactorActionKind!) {
    initiate2FA(action: $action)
  }
`;

export const SETTINGS_ENABLE_2FA = gql`
  mutation ($code: String!) {
    enable2FA(code: $code)
  }
`;

export const SETTINGS_REQ_CHANGE_MOBILE = gql`
  mutation ($new_phone: String!, $tfa_code: String!) {
    requestMyMobilePhoneChange(new_phone: $new_phone, tfa_code: $tfa_code)
  }
`;

export const SETTINGS_CHANGE_MOBILE = gql`
  mutation ($code: String!) {
    changeMyMobilePhone(code: $code)
  }
`;

export const SETTINGS_REQ_CHANGE_EMAIL = gql`
  mutation ($new_email: String!, $tfa_code: String!) {
    requestMyEmailChange(new_email: $new_email, tfa_code: $tfa_code)
  }
`;

export const SETTINGS_CHANGE_EMAIL = gql`
  mutation ($code: String!) {
    changeMyEmail(code: $code)
  }
`;

export const SETTINGS_CHANGE_IBAN = gql`
  mutation ($code: String!, $ref_acc: ReferenceBankAccountInput!) {
    changeMyReferenceAccount(code: $code, ref_acc: $ref_acc)
  }
`;

export const SETTINGS_DISABLE_2FA = gql`
  mutation {
    disable2FA
  }
`;

export const USER_PROFILE_INFO = gql`
  query {
    me {
      id
      email
      is_email_verified
      two_factor_auth
      client {
        id
        status
        first_name
        last_name
        accounts {
          product
        }
        financial_situation {
          monthly_spending {
            amount
          }
          post_tax_monthly_earnings {
            amount
          }
          savings {
            amount
          }
        }
        investment_plan {
          initial_investment {
            amount
          }
          monthly_amount {
            amount
          }
        }
      }
    }
  }
`;

export const MY_TRANSACTIONS = gql`
  query {
    getMyAccountTransactions {
      id
      created_on
      type
      status
      amount {
        amount
        unit
        currency
      }
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ($old_pass: String!, $new_pass: String!) {
    changeMyPassword(old_pass: $old_pass, new_pass: $new_pass)
  }
`;

export const RESET_PASSWORD = gql`
  mutation ($token: String!, $new_password: String!) {
    resetMyPassword(token: $token, new_password: $new_password)
  }
`;

export const REGISTER_CLIENT_USER = gql`
  mutation (
    $email: String!
    $pass: String!
    $first_name: String!
    $last_name: String!
    $language: LanguageKind
    $affiliate: String
    $product: Product
    $additional_data: AdditionalRegistrationDataInput
  ) {
    registerClientUser(
      email: $email
      pass: $pass
      first_name: $first_name
      last_name: $last_name
      language: $language
      affiliate: $affiliate
      product: $product
      additional_data: $additional_data
    )
  }
`;

export const LOGIN = gql`
  mutation ($email: String!, $pass: String!) {
    login(email: $email, pass: $pass) {
      token
      user {
        id
        is_email_verified
        two_factor_auth
      }
    }
  }
`;

export const LOGIN_WITH_2FA = gql`
  mutation ($email: String!, $pass: String!, $code: String!) {
    loginWith2FA(email: $email, pass: $pass, code: $code) {
      token
      user {
        id
        is_email_verified
      }
    }
  }
`;

export const REQUIRE_PASSWORD_RESET = gql`
  mutation ($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const VERIFY_EMAIL = gql`
  mutation ($token: String!) {
    verifyEMail(token: $token)
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation {
    resendMyVerificationEMail
  }
`;

export const ONBOARDING_ME = gql`
  query me {
    me {
      id
      is_email_verified
      email
      client {
        id
        first_name
        middle_name
        last_name
        mobile_phone
        phone
        salutation
        title
        gender
        marital_status
        date_of_birth
        city_of_birth
        country_of_birth
        citizenship
        education
        personal_identity_number
        proof_of_address_url
        work_information {
          employment_status
          industry
        }
        home_addresses {
          street
          postal_code
          town
          state
          country
          street_number
          additional_address_info
        }
        tax_information {
          tax_records {
            country
            tax_number
            type
          }
        }
        financial_situation {
          monthly_spending {
            amount
            currency
            unit
          }
          post_tax_monthly_earnings {
            amount
            currency
            unit
          }
          savings {
            amount
            currency
            unit
          }
        }
        investment_plan {
          product
          day_of_month
          horizon
          initial_investment {
            amount
            currency
            unit
          }
          monthly_amount {
            amount
            currency
            unit
          }
        }
        reference_bank_account {
          name
          iban
        }
        status
        identity_verification {
          status
          identification_url
        }
      }
    }
  }
`;

export const OB_SAVE_PERSONAL_INFO = gql`
  mutation (
    $first_name: String
    $last_name: String
    $mobile_phone: PhoneNumber
    $salutation: SalutationKind
    $title: TitleKind
    $education: EducationKind
    $marital_status: MaritalStatusKind
    $date_of_birth: DateTime
    $city_of_birth: String
    $country_of_birth: CountryCode
    $work_information: WorkInformationInput
  ) {
    updateMyClientInformation(
      first_name: $first_name
      last_name: $last_name
      mobile_phone: $mobile_phone
      salutation: $salutation
      title: $title
      education: $education
      marital_status: $marital_status
      date_of_birth: $date_of_birth
      city_of_birth: $city_of_birth
      country_of_birth: $country_of_birth
      work_information: $work_information
    )
  }
`;

export const OB_SAVE_TAX_INFO = gql`
  mutation (
    $citizenship: CountryCode
    $home_addresses: [AddressInput]
    $tax_information: TaxInformationInput
    $pid: String
  ) {
    updateMyClientInformation(
      home_addresses: $home_addresses
      personal_identity_number: $pid
      citizenship: $citizenship
      tax_information: $tax_information
    )
  }
`;

export const OB_SAVE_FINANCIAL_SITUATION = gql`
  mutation ($financial_situation: FinancialSituationInput) {
    updateMyClientInformation(financial_situation: $financial_situation)
  }
`;

export const OB_SAVE_INVESTMENT_PLAN = gql`
  mutation ($investment_plan: InvestmentPlanInput) {
    updateMyClientInformation(investment_plan: $investment_plan)
  }
`;

export const OB_GET_ESTIMATED_RETURNS = gql`
  query getEstimatedReturns(
    $period_years: Int!
    $initial_investment: MonetaryValueInput!
    $monthly_investment: MonetaryValueInput
    $product: Product
  ) {
    getEstimatedReturns(
      period_years: $period_years
      initial_investment: $initial_investment
      monthly_investment: $monthly_investment
      product: $product
    ) {
      total_invested {
        amount
        currency
        unit
      }
      future_balance {
        amount
        currency
        unit
      }
    }
  }
`;

export const OB_VALIDATE_TIN = gql`
  query validateTIN($tin: String!, $country: CountryCode!) {
    validateTIN(tin: $tin, country: $country)
  }
`;

export const OB_GET_ESTIMATED_COSTS = gql`
  query getEstimatedCosts(
    $period_years: Int!
    $initial_investment: MonetaryValueInput!
    $monthly_investment: MonetaryValueInput
    $product: Product
  ) {
    getEstimatedCosts(
      period_years: $period_years
      initial_investment: $initial_investment
      monthly_investment: $monthly_investment
      product: $product
    ) {
      monthly_advisory_tx_costs {
        amount
        currency
        unit
      }
      monthly_fund_costs {
        amount
        currency
        unit
      }
      total_estimated_returns {
        amount
        currency
        unit
      }
      total_advisory_tx_costs {
        amount
        currency
        unit
      }
    }
  }
`;

export const OB_SAVE_REF_BANK_ACC = gql`
  mutation ($reference_bank_account: ReferenceBankAccountInput) {
    updateMyClientInformation(reference_bank_account: $reference_bank_account)
  }
`;

export const OB_GET_MY_PRELIMINARY_DOCUMENTS = gql`
  query {
    getMyPreliminaryDocuments {
      url
      type
      name
    }
  }
`;

export const OB_SEND_ME_MY_CONTRACTS = gql`
  mutation {
    sendMeMyContracts
  }
`;

export const OB_GENERATE_ADDR_UPLOAD_LINK = gql`
  mutation {
    generateMyProofOfAddressUploadLink {
      proof_of_address_upload_link
    }
  }
`;

export const OB_INITIATE_KYC = gql`
  mutation {
    initiateMyIdentification {
      identification_url
    }
  }
`;

export const OB_SIGN_MY_CONTRACTS = gql`
  mutation {
    signMyContracts
  }
`;

export const OB_UPDATE_MY_PRODUCT = gql`
  mutation ($investment_plan: InvestmentPlanInput) {
    updateMyClientInformation(investment_plan: $investment_plan)
  }
`;

export const GET_HISTORY_PERFORMANCE = gql`
  query {
    getMyAccountHistoricPerformance {
      data {
        date
        percentage_change
      }
      info {
        initial_investment_date
        initial_investment_date_formatted
      }
    }
  }
`;

export const VALIDATE_IBAN = gql`
  query ($iban: String!) {
    validateIBAN(iban: $iban) {
      iban_valid
      sepa_support
    }
  }
`;
