import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

import notVerifiedImage from "../../../assets/img/onboarding/plane-post.png";
import { useSageMutation } from "../../Common/GqlHooks";
import { RESEND_VERIFICATION_EMAIL } from "../../../api/graphql";
import { NotificationStoreContext } from "../../Common/NotificationStore";
import ContentLoader from "../../Common/ContentLoader";

const NotVerifiedNotice = () => {
  const { t } = useTranslation("obdLayout");
  const notifications = useContext(NotificationStoreContext);
  const [resend, loading] = useSageMutation(RESEND_VERIFICATION_EMAIL);

  const handleResend = () => {
    resend()
      .then(() => {
        notifications.addMessage(t("verifyEmailResent"));
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="ob-note d-flex align-items-center justify-content-center h-100">
      <div className="d-inline-block not-ver-notice text-center">
        <img src={notVerifiedImage} alt="" width="230" className="mb-3" />
        <h5 className="text-accent">{t("checkIbx")}</h5>
        <p>
          {t("bfPrcd")}
          {t("inCase")}
        </p>
        <Button
          onClick={handleResend}
          className="inline text-primary modify-deposit mt-2"
          variant="transparent"
        >
          {t("notRec")}
        </Button>
      </div>

      <ContentLoader loading={loading} isInline={false} />
    </div>
  );
};

export default NotVerifiedNotice;
