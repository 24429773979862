import { Container, Form, Nav, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Header from "../Common/Header";
import LanguageSwitch from "../Common/LanguageSwitch";
import "./Auth.scss";

const AuthLayout = ({ children }) => {
  const { t } = useTranslation("auth");

  const token = localStorage.getItem("user-token");

  if (token) {
    window.location.href = "/dashboard";
  }

  return (
    <>
      <Header linkTo="https://sagewealth.eu">
        <Nav className="ml-auto">
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact={true}
            to="/register"
          >
            {t("register")}
          </Nav.Link>
          <Nav.Link
            as={NavLink}
            activeClassName="active"
            exact={true}
            to="/login"
          >
            {t("login")}
          </Nav.Link>
          <Form inline>
            <LanguageSwitch />
          </Form>
        </Nav>
      </Header>

      <Container fluid className="auth-layout">
        <Row>
          <Col className="left-pane d-none d-lg-block"></Col>
          {children}
        </Row>
      </Container>
    </>
  );
};

export default AuthLayout;
