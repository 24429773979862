/* eslint-disable */

export default {
  settings: "Settings",
  myInfo: "My Information",
  editPassword: "Edit Password",
  toEdit:
    "To <strong>edit</strong>, please contact us in the <strong>chat</strong> below.",
  passwordChanged: "Password successfully updated!",
  setNewPass: "Set your new password",
  currentPassword: "Current Password",
  newPassword: "New Password",
  repeatNewPassword: "Repeat new Password",
  updatePassword: "Update Password",
  validPassword: "Password should be at least 4 characters long",
  validSamePasswords: "'Passwords should be the same'",
  name: "Name",
  password: "Password",
  mobile: "Mobile",
  address: "Address",
  refBankAcc: "Reference Bank Account",
  passwordValidationMessage:
    "Password must be combination of lowercase, uppercase, numeric, special and at least 8 characters",
  newPassConfirmValidationMessage: "Passwords should be the same",
  MONTHLY_DEPOSIT: "Monthly Deposit",
  DEPOSIT: "Deposit",
  WITHDRAW: "Withdraw",
  RETURN_FEE: "Fees",
  MONTHLY_FEE: "Monthly Fee",
  MANAGEMENT_FEE: "Management fees",
  TRADING_FEE: "Trading fees",
  txAmount: "Amount",
  txDate: "Date",
  txType: "Transaction Type",
  myInfoTab: "My Information",
  transactionsTab: "Transactions",
  documentsTab: "Documents",
  twoFA: "Two Factor Authentication",
  sixDigitCode:
    "Enter the six digit code you received via your two factor authentication.",
  twoFATermsTitle: "TFA terms and conditions",
  twoFATermsIntro:
    "To activate the two factor authentication, please accept the following terms and conditions:",
  twoFATerms:
    "I confirm that I will not store or keep the two factors in the same place or medium. I will not disclose either factor to third parties or keep them accessible to third parties.",
  twoFATermsAccept: "I accept",
  didntRec: "Didn't receive your code?",
  resend2Fa: "Click here to resend",
  submit: "Submit",
  changeMobile: "Change mobile phone number",
  chooseNewMobile: "Choose a New Mobile Phone Number",
  dontForgetCC: "Make sure you don't forget the country code.",
  req2FaOne: "This action will require two factor authentication.",
  req2FaTwo: "Make sure you still have access to your current mobile phone.",
  phone2FaMsg: "Enter the code you received on your current mobile phone.",
  phoneVerifyTitle: "New phone Verification",
  phoneVerifyMsg:
    "Confirm the change by entering the code you received via your new mobile phone number.",
  // Edit email
  changeEmail: "Change Email",
  chooseNewEmail: "Choose a New Email",
  emailVerifyTitle: "New Email Verification",
  emailVerifyMsg:
    "Confirm the change by entering the code you received via your new email.",
  // Change iban
  changeIban: "New Reference Bank Account  (IBAN)",
  chooseNewIban: "Choose a New Reference Bank Account (IBAN)",
  makeSureIban: "Make sure that new IBAN is in the name of ",
  makeSureIbanSuff: "",
  // Change addr
  changeAddr: "Change address",
};
