import { useTranslation } from "react-i18next";
import { Button, Card, Form } from "react-bootstrap";
import { useContext } from "react";
import mixpanel from "mixpanel-browser";
import { useFormik } from "formik";

import Money from "../../Common/Money";
import InfoCard from "../InfoCard";
import { OnboardingStoreContext } from "../OnboardingStore";
import { Product, MixpanelEvents } from "../../../common/common";
import growthIcon from "../../../assets/img/products/growth-icon.png";
import stabilityIcon from "../../../assets/img/products/stability-icon.png";
import FormikRadioCard from "../../Common/FormikRadioCard";
import { useSageMutation } from "../../Common/GqlHooks";
import { OB_UPDATE_MY_PRODUCT } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";
import Step from "./Step";

const Introduction = ({ step }) => {
  const { t, i18n } = useTranslation(["steps", "obdNav"]);
  const obStore = useContext(OnboardingStoreContext);

  const [submit, loading] = useSageMutation(OB_UPDATE_MY_PRODUCT);

  const onSubmit = (values) => {
    submit({ variables: { investment_plan: { product: values.product } } })
      .then(() => {
        obStore.refresh();
        mixpanel.track(MixpanelEvents.onboarding.INTRODUCTION_COMPLETED);
        obStore.next();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues: {
      product: obStore.product,
    },
    onSubmit,
  });

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>{t("welcome")}</StepHeader>

      <StepContent>
        <h5>{t("selectProduct")}</h5>

        <Form noValidate>
          <div className="my-4">
            <FormikRadioCard
              field="product"
              formik={formik}
              value={Product.STABILITY}
              checked={formik.values.product === Product.STABILITY}
              id="growth"
            >
              <div className="d-flex align-items-center">
                <img
                  src={stabilityIcon}
                  alt="Growth Icon"
                  width="40px"
                  height="40px"
                  style={{ marginRight: 8 }}
                />
                <Card.Text>
                  <b className="stability">SageWealth Stability</b> <br />
                  {t("sfStab")}
                </Card.Text>
              </div>
            </FormikRadioCard>
          </div>

          <div className="my-4">
            <FormikRadioCard
              field="product"
              formik={formik}
              value={Product.GROWTH}
              checked={formik.values.product === Product.GROWTH}
              id="stability"
            >
              <div className="d-flex align-items-center">
                <img
                  src={growthIcon}
                  alt="Growth Icon"
                  width="40px"
                  height="40px"
                  style={{ marginRight: 8 }}
                />
                <Card.Text>
                  <b className="growth">SageWealth Growth</b> <br />
                  {t("sfStabGrowth")}
                </Card.Text>
              </div>
            </FormikRadioCard>
          </div>
        </Form>

        <p>
          {t("visitProducts")}
          <Button
            href={
              i18n.language === "de"
                ? "https://sagewealth.eu/de/produkte"
                : "https://sagewealth.eu/products"
            }
            target="_blank"
            className="inline text-primary text-decoration-none"
            variant="transparent"
          >
            {t("here")}
          </Button>
          .
        </p>

        <div className="d-flex">
          <InfoCard className="">
            <Card.Body>
              <Card.Title>{t("minInitInv")}</Card.Title>
              <Card.Text>
                <Money
                  size="lg"
                  variant="small-decimals"
                  value={{
                    currency: "EUR",
                    amount: 1000,
                  }}
                />
              </Card.Text>
            </Card.Body>
            <Card.Body>
              <Card.Title>{t("minMonthInv")}</Card.Title>
              <Card.Text>
                <Money
                  size="lg"
                  variant="small-decimals"
                  value={{
                    currency: "EUR",
                    amount: 25,
                  }}
                />
                <small className="text-accent d-block">{t("opt")}</small>
              </Card.Text>
            </Card.Body>
          </InfoCard>
        </div>

        <h5>{t("whatKind")}</h5>
        <p>{t("whatKindA")}</p>

        <h5>{t("cantFin")}</h5>
        <p>{t("cantFinA")}</p>
      </StepContent>

      <StepButtons back={false} onNext={handleNext} />
      <ContentLoader loading={loading} isInline={false} />
    </Step>
  );
};

export default Introduction;
