import { PopupButton } from "react-calendly";
import { useTranslation } from "react-i18next";

import marcoImg from "../../../assets/img/advice/marco.jpeg";
import sanaImg from "../../../assets/img/advice/sana.jpeg";

import styles from "./advice.module.scss";

const DashboardAdvice = () => {
  const { t } = useTranslation("advice");

  return (
    <div className={styles.advicePage}>
      <div className={styles.adviceContainer}>
        <div className={styles.marco}>
          <div className={styles.marcoBlockTop}></div>

          <img
            src={marcoImg}
            className={styles.marcoImg}
            alt="Image of marco"
          />

          <div className={`${styles.titleBox} ${styles.marcoTitle}`}>
            <p className={styles.advisorName}>
              Marco Vega
              <br />
              <span className={styles.advisorTitle}>{t("founder")}</span>
            </p>
          </div>
        </div>
        <div className={styles.sana}>
          <div className={styles.sanaBlockTop}></div>

          <div className={styles.sanaBlockBottom}></div>

          <img className={styles.sanaImg} src={sanaImg} alt="Image of sana" />

          <div className={`${styles.titleBox} ${styles.sanaTitle}`}>
            <p className={styles.advisorName}>
              Sana Al-Badri
              <br />
              <span className={styles.advisorTitle}>{t("founder")}</span>
            </p>
          </div>
        </div>

        <div className={styles.adviceBlock2}>
          <h1 className={styles.advisorHeading}>{t("title")}</h1>

          <p className={styles.advisorPara}>
            {t("question1")}
            <br />
            {t("question2")}
            <br />
            <br />
            {t("question3")}
            <br />
            {t("question4")}
          </p>

          <PopupButton
            className={styles.popupButton}
            url="https://calendly.com/d/yh3-749-jm3/sagewealth-financial-advisory?hide_event_type_details=1&hide_gdpr_banner=1&primary_color=543458"
            rootElement={document.getElementById("root")}
            text={t("button")}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdvice;
