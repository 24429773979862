import { useContext } from "react";
import NumberFormat from "react-number-format";

import { Currency } from "../../common/money";

import { LanguageStoreContext } from "./LanguageStore";

const MoneyInput = (props) => {
  let { size, currency } = props;

  if (!size) size = "";
  if (!currency) currency = Currency.EUR;

  const lang = useContext(LanguageStoreContext);

  const prefix = `${Currency.toString(Currency.parse(currency))} `;

  const thousandSep = lang.lang === "en" ? "," : ".";
  const decimalSep = lang.lang === "en" ? "." : ",";

  const newProps = {
    ...props,
    className: `form-control ${size} ${props.className}`,
  };

  delete newProps.size;
  delete newProps.currency;

  return (
    <NumberFormat
      {...newProps}
      placeholder={prefix}
      prefix={prefix}
      thousandSeparator={thousandSep}
      decimalSeparator={decimalSep}
      isAllowed={(values) => {
        if (!values.floatValue) return true;
        return values.floatValue < 20000000;
      }}
    />
  );
};

export default MoneyInput;
