import { useTranslation } from "react-i18next";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import Recaptcha from "react-google-recaptcha";
import { useContext, useState } from "react";
import mixpanel from "mixpanel-browser";

import { validatePassword, validateEmail } from "../../common/validations";
import ContentLoader from "../../components/Common/ContentLoader";
import { useSageMutation } from "../../components/Common/GqlHooks";
import { REGISTER_CLIENT_USER, LOGIN } from "../../api/graphql";
import { LanguageStoreContext } from "../../components/Common/LanguageStore";
import { getCookie, Product, MixpanelEvents } from "../../common/common";
import { ClientStatus } from "../../common/client-status";
import logo from "../../assets/img/logo_white2.png";

import AuthLayout from "./AuthLayout";
import PasswordMatcher from "./PasswordMatcher";

const Register = () => {
  const [formSent, setFormSent] = useState(false);
  const lang = useContext(LanguageStoreContext);
  const { t } = useTranslation(["register", "auth"]);
  const [captchaOk, setCaptchaOk] = useState(false);

  const isGrowth = window.location.toString().includes("growth");

  const validate = (values) => {
    const errors = {};

    if (!values.email || !validateEmail(values.email)) {
      errors.email = true;
    }

    if (!values.newPass || !validatePassword(values.newPass)) {
      errors.newPass = true;
    }

    return errors;
  };

  const [register, loadingRegister] = useSageMutation(REGISTER_CLIENT_USER);
  const [login, loadingLogin] = useSageMutation(LOGIN);

  const onSubmit = (values) => {
    setFormSent(true);

    register({
      variables: {
        email: values.email.trim(),
        pass: values.newPass,
        first_name: "",
        last_name: "",
        language: lang.lang === "en-us" ? "EN" : "DE",
        affiliate: getCookie("ref"),
        product: isGrowth ? Product.GROWTH : Product.STABILITY,
        additional_data: {
          mixpanel_cookie: getCookie("mp_"+process.env.REACT_APP_MIXPANEL_TOKEN+"_mixpanel"),
        }
      },
    })
      .then(() => {
        login({
          variables: {
            email: values.email.trim(),
            pass: values.newPass,
          },
        })
          .then((resp) => {
            // TODO Unstringify token after refactor
            localStorage.setItem(
              "user-token",
              JSON.stringify(resp.data.login.token)
            );

            mixpanel.identify(resp.data.login.user.id);
            mixpanel.people.set({ $email: values.email.trim() });
            mixpanel.track(MixpanelEvents.onboarding.SIGN_UP, {
              product: isGrowth ? Product.GROWTH : Product.STABILITY,
              affiliate: getCookie("ref"),
              // Send utm source
            });

            // TODO Remove after refactor
            const isEmailVerified = resp.data.login.user.is_email_verified;

            localStorage.setItem("user-onboarded", ClientStatus.ONBOARDING);
            localStorage.setItem("user-verified", isEmailVerified);
            localStorage.setItem("user-current-step", "0");

            window.open("/onboarding", "_self");
          })
          .catch((e) => {
            setFormSent(false);
            console.log(e);
          });
      })
      .catch((e) => {
        setFormSent(false);
        console.log(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      newPass: "",
    },
    validate,
    onSubmit,
  });

  return (
    <AuthLayout>
      <Col
        className="right-pane d-flex flex-column align-items-center"
        id="auth-right-pane"
      >
        <Row className="justify-content-center mt-auto mb-auto d-flex">
          <Col xs={12} md={8} lg={8}>
            <div className="pt-0 pr-3 pb-3 pl-3">
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="text-center">
                  <div className="text-center">
                    <img src={logo} alt="SageWealth" width="200" />
                  </div>
                  <Form.Label className="mb-0 mt-4 auth-title">
                    {t("setupAccount")}
                  </Form.Label>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-4">
                    {t("auth:email")}
                  </Form.Label>
                  <Form.Control
                    className="lg"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={!formik.errors.email && formik.touched.email}
                    isInvalid={formik.errors.email && formik.touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t("auth:validEmail")}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="mb-0 mt-1">
                    {t("auth:password")}
                  </Form.Label>
                  <Form.Control
                    className="lg"
                    name="newPass"
                    type="password"
                    value={formik.values.newPass}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isValid={!formik.errors.newPass && formik.touched.newPass}
                    isInvalid={formik.errors.newPass && formik.touched.newPass}
                  />
                  {formik.errors.newPass && formik.values.newPass && (
                    <PasswordMatcher password={formik.values.newPass} />
                  )}
                </Form.Group>

                <div className="mt-4 d-flex justify-content-center">
                  <Recaptcha
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={() => setCaptchaOk(!captchaOk)}
                  />
                </div>

                <Button
                  type="submit"
                  size="lg"
                  disabled={!formik.isValid || !captchaOk}
                  className="flat mt-4 w-100"
                >
                  {t("submit")}
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Col>
      <ContentLoader
        loading={loadingRegister || loadingLogin || formSent}
        isInline={false}
      />
    </AuthLayout>
  );
};

export default Register;
