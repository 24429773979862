export const SalutationKind = Object.freeze({
  MR: "MR",
  MRS: "MRS",
});

export const TitleKind = Object.freeze({
  DR: "DR",
  PROF: "PROF",
});

export const MaritalStatusKind = Object.freeze({
  MARRIED: "MARRIED",
  SINGLE: "SINGLE",
});

export const EmploymentStatusKind = Object.freeze({
  SELF_EMPLOYED: "SELF_EMPLOYED",
  EMPLOYED: "EMPLOYED",
  UNEMPLOYED: "UNEMPLOYED",
  RETIRED: "RETIRED",
  STUDENT: "STUDENT",
});

export const EducationKind = Object.freeze({
  HIGH_SCHOOL: "HIGH_SCHOOL",
  VOCATIONAL_TRAINING: "VOCATIONAL_TRAINING",
  TECHNICAL_COLLEGE: "TECHNICAL_COLLEGE",
  UNDERGRADUATE_DEGREE: "UNDERGRADUATE_DEGREE",
  POSTGRADUATE_DEGREE: "POSTGRADUATE_DEGREE",
  OTHER: "OTHER",
});

export const TaxRecordKind = Object.freeze({
  RESIDENTIAL: "RESIDENTIAL",
  NATIONAL: "NATIONAL",
  OTHER: "OTHER",
});
