import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import { CLIENT_INVESTMENT_INFO } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import Money from "../../Common/Money";
import DayOfMonth from "../../Common/DayOfMonth";
import { useSageLazyQuery } from "../../Common/GqlHooks";
import Percentage from "../../Common/Percentage";
import growthIcon from "../../../assets/img/products/growth-icon.png";
import stabilityIcon from "../../../assets/img/products/stability-icon.png";
import { Product } from "../../../common/common";
import { UserStoreContext } from "../../Common/UserStore";

import DepositFunds from "./DepositFunds";
import WithdrawFunds from "./WithdrawFunds";
import ModifyMonthlyDeposit from "./ModifyMonthlyDeposit";
import StartSavingMoney from "./StartSavingMoney";
import Graph from "./Graph";

import "../Dashboard.scss";

const DashboardLanding = () => {
  const [showModify, setShowModify] = useState(false);
  const user = useContext(UserStoreContext);

  const { t } = useTranslation(["dashboardLayout", "dashboard"]);

  const [query, loading, data, refetch] = useSageLazyQuery(
    CLIENT_INVESTMENT_INFO
  );

  useEffect(() => {
    query();
  }, [query]);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={12} lg={4} xl={3}>
          <span className="col-heading px-1">
            <MyProduct product={user.product_type} />
          </span>

          <Card className="mb-3">
            <Card.Body>
              <ContentLoader loading={loading}>
                <Card.Title>{t("dashboard:investmentValue")}</Card.Title>
                <Card.Text>
                  <Money
                    variant="small-decimals"
                    value={data?.me.client.accounts[0].investment_amount}
                    size="lg"
                  />
                  <br />
                  <CardFooterInfo>
                    {t("dashboard:invested")}{" "}
                    <strong>
                      <Money
                        variant="small-decimals"
                        value={data?.me.client.accounts[0].invested_amount}
                      />
                    </strong>{" "}
                  </CardFooterInfo>
                </Card.Text>
              </ContentLoader>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <ContentLoader loading={loading}>
                <Card.Title className="d-flex">
                  {t("dashboard:totalEarnings")}
                  <Button
                    className="ml-auto inline text-primary modify-deposit"
                    variant="transparent"
                    size="sm"
                    onClick={() =>
                      window.open(
                        "https://medium.com/sagefund/understanding-your-total-earnings-b26da9dac725",
                        "_blank"
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </Button>
                </Card.Title>
                <Card.Text>
                  <Money
                    variant="small-decimals"
                    value={data?.me.client.accounts[0].earned_amount}
                    size="lg"
                  />
                  <CardFooterInfo>
                    <strong>
                      <Percentage
                        size="sm"
                        value={data?.me.client.accounts[0].earned_percentage}
                      />
                    </strong>
                  </CardFooterInfo>
                </Card.Text>
              </ContentLoader>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <ContentLoader loading={loading}>
                {data?.me.client.accounts[0].monthly_savings_plan ? (
                  <>
                    <Card.Title className="d-flex align-items-center">
                      {t("dashboard:monthlyDeposit")}
                      <Button
                        className="ml-auto inline text-primary modify-deposit"
                        variant="transparent"
                        size="sm"
                        onClick={() => setShowModify(true)}
                      >
                        {t("dashboard:modify")}
                      </Button>
                    </Card.Title>
                    <Card.Text>
                      <Money
                        variant="small-decimals"
                        value={
                          data?.me.client.accounts[0].monthly_savings_plan
                            .amount
                        }
                        size="lg"
                      />
                      <br />
                      <CardFooterInfo>
                        {t("dashboard:every")}{" "}
                        <strong>
                          <DayOfMonth
                            value={
                              data?.me.client.accounts[0].monthly_savings_plan
                                .day_of_month
                            }
                          />
                        </strong>{" "}
                        {t("dashboard:ofTheMonth")}
                      </CardFooterInfo>
                    </Card.Text>
                  </>
                ) : (
                  <StartSavingMoney onStartSaving={() => setShowModify(true)} />
                )}
              </ContentLoader>
            </Card.Body>
          </Card>

          <footer className="d-flex flex-column mt-3">
            <DepositFunds
              iban={data?.me.client.reference_bank_account.iban}
              disabled={loading}
            >
              {t("dashboard:depositFunds")}
            </DepositFunds>
            <WithdrawFunds
              investmentValue={data?.me.client.accounts[0].investment_amount}
              iban={data?.me.client.reference_bank_account.iban}
              disabled={loading}
            >
              {t("dashboard:withdrawFunds")}
            </WithdrawFunds>
          </footer>
        </Col>
        <Col lg="auto">
          <span className="d-flex align-items-center col-heading px-1">
            {t("dashboard:portfolioPerformance")}
          </span>

          <Graph />
        </Col>
      </Row>

      <ModifyMonthlyDeposit
        active={!!data?.me.client.accounts[0].monthly_savings_plan}
        show={showModify}
        setShow={setShowModify}
        dayOfMonth={
          data?.me.client.accounts[0].monthly_savings_plan?.day_of_month || 1
        }
        currentSavingsAmount={
          data?.me.client.accounts[0].monthly_savings_plan?.amount
        }
        onMonthlyInvestmentUpdated={refetch}
      />
    </Container>
  );
};

const MyProduct = ({ product }) => {
  return (
    <div className="d-flex align-items-center" style={{ fontWeight: "bold" }}>
      {product && (
        <img
          src={product === Product.GROWTH ? growthIcon : stabilityIcon}
          alt="Product Icon"
          width={25}
          height={25}
          style={{ marginRight: 5 }}
        />
      )}
      <span>SAGEWEALTH {product}</span>
    </div>
  );
};

const CardFooterInfo = ({ children, justify = "end", pre = null }) => {
  return (
    <span className={`pt-1 d-flex justify-content-${justify}`}>
      {pre}
      <small>{children}</small>
    </span>
  );
};

export default DashboardLanding;
