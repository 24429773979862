/* eslint-disable */

export default {
  dashboard: "Dashboard",
  settings: "Settings",
  logout: "Log out",
  copyright:
    "© 2023 Sagefund Digital Wealth Management GmbH. All rights reserved",
  explore: "Explore",
  advice: "Advice",
};
