import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import SageModal from "../../Common/SageModal";
import FormikFormControl from "../../Common/FormikFormControl";
import { validateEmail } from "../../../common/validations";

const validate = (values) => {
  const errors = {};

  if (!values.email || !validateEmail(values.email)) {
    errors.email = true;
  }

  return errors;
};

const EditEmail = ({ onClose, onSubmit }) => {
  const { t } = useTranslation(["settings"]);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit,
  });

  return (
    <SageModal
      className="edit-pass-modal"
      show={true}
      title={t("changeEmail")}
      onHide={onClose}
      buttons={
        <Button
          size="lg"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          {t("submit")}
        </Button>
      }
    >
      <h5>{t("chooseNewEmail")}</h5>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Form.Group>
          <FormikFormControl formik={formik} field="email" type="email" />
        </Form.Group>
      </Form>

      <small className="text-accent d-block">{t("req2FaOne")}</small>
      <small className="text-accent d-block">{t("req2FaTwo")}</small>
    </SageModal>
  );
};

export default EditEmail;
