export const IndustriesKind = Object.freeze({
  AGRICULTURE_FORESTRY_HUNTING: "AGRICULTURE_FORESTRY_HUNTING",
  MINING_QUARRYING: "MINING_QUARRYING",
  GLASS_MANUFACTURE_STONE_PROCESSING: "GLASS_MANUFACTURE_STONE_PROCESSING",
  MOTOR_VEHICLES_OR_PARTS_MANUFACTURING:
    "MOTOR_VEHICLES_OR_PARTS_MANUFACTURING",
  POWER_SUPPLY: "POWER_SUPPLY",
  WATER_SUPPLY_SEWAGE_WASTE_DISPOSAL: "WATER_SUPPLY_SEWAGE_WASTE_DISPOSAL",
  BUILDING_CONSTRUCTION: "BUILDING_CONSTRUCTION",
  MAINTENANCE_REPAIR_TRADE_MOTOR_VEHICLES_AND_SERVICE:
    "MAINTENANCE_REPAIR_TRADE_MOTOR_VEHICLES_AND_SERVICE",
  RETAIL_TRADE: "RETAIL_TRADE",
  STORAGE_AND_PROVISION_TRANSPORT_SERVICES:
    "STORAGE_AND_PROVISION_TRANSPORT_SERVICES",
  GASTRONOMY: "GASTRONOMY",
  INFORMATION_SERVICES: "INFORMATION_SERVICES",
  REAL_ESTATE_AND_HOUSING: "REAL_ESTATE_AND_HOUSING",
  OTHER_FREELANCE_SCIENTIFIC_ACTIVITIES:
    "OTHER_FREELANCE_SCIENTIFIC_ACTIVITIES",
  PROVISION_OF_ECONOMIC_SERVICES: "PROVISION_OF_ECONOMIC_SERVICES",
  EDUCATION_AND_TEACHING: "EDUCATION_AND_TEACHING",
  HEALTHCARE: "HEALTHCARE",
  CREATIVE_ACTIVITIES: "CREATIVE_ACTIVITIES",
  SPORTS_ENTERTAINMENT_RECREATION: "SPORTS_ENTERTAINMENT_RECREATION",
});
