import { useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";

import { LanguageStoreContext } from "./LanguageStore";
import "./LanguageSwitch.scss";

const LanguageSwitch = () => {
  const lang = useContext(LanguageStoreContext);

  return (
    <DropdownButton
      className="lang-switch flat"
      variant="secondary"
      title={
        <span>
          <span className="val">{lang.lang}</span>
        </span>
      }
    >
      <Dropdown.Item onClick={() => lang.change("en")}>EN</Dropdown.Item>
      <Dropdown.Item onClick={() => lang.change("de")}>DE</Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSwitch;
