import { Button, Figure } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import image from "../../../assets/img/dialog/deposit-monthly-icon.png";

const StartSavingMoney = ({ onStartSaving }) => {
  const { t } = useTranslation("dashboard");
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Figure
        className="m-0"
        style={{
          minWidth: "75px",
          maxWidth: "75px",
        }}
      >
        <Figure.Image src={image} />
      </Figure>
      <Button
        className="inline text-primary modify-deposit pb-2"
        variant="transparent"
        size="sm"
        onClick={onStartSaving}
      >
        {t("startSavingMonthly")}
      </Button>
    </div>
  );
};

export default StartSavingMoney;
