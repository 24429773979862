import "./Logo.scss";
import logo from "../../assets/img/sagefund-logo.svg";

const Logo = () => {
  return (
    <div id="sage-logo" className="">
      <img src={logo} alt="SageWealth" />
    </div>
  );
};

export default Logo;
