import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useContext } from "react";
import mixpanel from "mixpanel-browser";

import image from "../../../assets/img/onboarding/postident.png";
import { useSageMutation } from "../../Common/GqlHooks";
import { OB_INITIATE_KYC } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { OnboardingStoreContext } from "../OnboardingStore";
import { MixpanelEvents } from "../../../common/common";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";

const VerifyYourIdentity = ({ onPrev }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const [initiateKyc, loading] = useSageMutation(OB_INITIATE_KYC);

  const startKyc = () => {
    if (obStore.me.client.identity_verification) {
      window.open(
        obStore.me.client.identity_verification.identification_url,
        "_blank"
      );
      return;
    }

    initiateKyc()
      .then((res) => {
        obStore.refresh();
        mixpanel.track(
          MixpanelEvents.onboarding.IDENTITY_VERIFICATION_INITIATED
        );
        window.open(
          res.data.initiateMyIdentification.identification_url,
          "_blank"
        );
      })
      .catch(console.log);
  };

  return (
    <>
      <StepHeader title={t(`vID`)}>{t("vIDSub")}</StepHeader>
      <StepContent>
        <h5>{t("vIdInstructions")}</h5>

        <ol className="paragraph mr-md-5 pr-md-5 pl-4 pl-md-5">
          <li>{t("vIdInstr1")}</li>
          <li>{t("vIdInstr2")}</li>
          <li>{t("vIdInstr3")}</li>
        </ol>

        <div className="pt-5 mt-5 d-flex flex-column align-items-center justify-content-center">
          <img src={image} alt="postident" />
          <Button size="lg" className="mt-4 flat" onClick={startKyc}>
            {t("clickHereToStart")}
          </Button>
        </div>

        <StepButtons onPrev={onPrev} next={false} />
        <ContentLoader loading={loading} isInline={false} />
      </StepContent>
    </>
  );
};

export default VerifyYourIdentity;
