import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { Accordion, Card, Table } from "react-bootstrap";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import AccordionHeader from "../../Common/AccordionHeader";
import {
  costsBreakdown,
  horizon,
  realReturnsAndCosts,
  MixpanelEvents,
} from "../../../common/common";
import InfoCard from "../InfoCard";
import Money from "../../Common/Money";
import { useSageLazyQuery } from "../../Common/GqlHooks";
import { OB_GET_ESTIMATED_COSTS } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import Percentage from "../../Common/Percentage";
import { OnboardingStoreContext } from "../OnboardingStore";

import StepButtons from "./StepButtons";
import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import Step from "./Step";

const Costs = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const [activeKey, setActiveKey] = useState("");

  const tier = () => {
    var investment =
      obStore.me.client.investment_plan.initial_investment.amount / 100;

    if (investment < 100000) {
      return 1;
    }

    return 2;
  };

  const getCostOrReturn = (index) => {
    return realReturnsAndCosts[obStore.product][index][tier() - 1];
  };

  const getCostsBreakdown = () => costsBreakdown[obStore.product];

  const addCostsOrReturns = (indexA, indexB) => {
    const costA = getCostOrReturn(indexA);
    const costB = getCostOrReturn(indexB);

    return {
      ...costA,
      value: costA.value + costB.value,
    };
  };

  const replacePoi = (str) => {
    return str.replace(
      "{poi}",
      horizon[obStore.product][obStore.me.client.investment_plan.horizon]
    );
  };

  const handlePrev = () => {
    obStore.back();
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const validate = () => {
    const errors = {};

    return errors;
  };

  const onSubmit = () => {
    mixpanel.track(MixpanelEvents.onboarding.COSTS_BREAKDOWN_COMPLETED);
    obStore.next();
  };

  const formik = useFormik({
    initialValues: {},
    validate,
    onSubmit,
  });

  const [getEstimatedCosts, loading, data] = useSageLazyQuery(
    OB_GET_ESTIMATED_COSTS
  );

  useEffect(() => {
    const plan = obStore.me.client.investment_plan;

    getEstimatedCosts({
      variables: {
        period_years: horizon[obStore.product][plan.horizon],
        initial_investment: {
          currency: "EUR",
          unit: "CENT",
          amount: plan.initial_investment.amount,
        },
        monthly_investment: plan.monthly_amount
          ? {
              currency: "EUR",
              unit: "CENT",
              amount: plan.monthly_amount.amount,
            }
          : null,
        product: obStore.product,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>{t("costsSub")}</StepHeader>

      <ContentLoader loading={loading} isInline={false}>
        <StepContent>
          <h5>{t("youPay")}</h5>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-between pt-4 mb-4">
            <div className="w-md-30">
              <CardTitle>{t("advisoryAcc")}</CardTitle>
              <InfoCard variant="mt-0">
                <Card.Body>
                  <Card.Text className="my-2 text-center">
                    <Money
                      size="xlg"
                      value={{
                        currency: "EUR",
                        amount:
                          data?.getEstimatedCosts.monthly_advisory_tx_costs
                            .amount / 100,
                      }}
                    />
                  </Card.Text>
                  <Card.Title className="text-center">
                    <Money
                      value={{
                        currency: "EUR",
                        amount:
                          (data?.getEstimatedCosts.monthly_advisory_tx_costs
                            .amount /
                            100) *
                          12,
                      }}
                    />
                    {t("perYear")}{" "}
                    <small className="text-accent">
                      <Cost value={getCostOrReturn(0)} />
                    </small>
                  </Card.Title>
                </Card.Body>
              </InfoCard>
            </div>
            <Plus />
            <div className="w-md-30">
              <CardTitle>{t("fundCosts")}</CardTitle>
              <InfoCard variant="mt-0">
                <Card.Body>
                  <Card.Text className="my-2 text-center">
                    <Money
                      size="xlg"
                      value={{
                        currency: "EUR",
                        amount:
                          data?.getEstimatedCosts.monthly_fund_costs.amount /
                          100,
                      }}
                    />
                  </Card.Text>
                  <Card.Title className="text-center">
                    <Money
                      value={{
                        currency: "EUR",
                        amount:
                          (data?.getEstimatedCosts.monthly_fund_costs.amount /
                            100) *
                          12,
                      }}
                    />
                    {t("perYear")}{" "}
                    <small className="text-accent">
                      (<Cost value={getCostOrReturn(1)} />)
                    </small>
                  </Card.Title>
                </Card.Body>
              </InfoCard>
            </div>
            <Equals />
            <div className="w-md-30">
              <CardTitle>{t("total")} </CardTitle>
              <InfoCard variant="mt-0" leaf>
                <Card.Body>
                  <Card.Text className="my-2 text-center">
                    <Money
                      size="xlg"
                      value={{
                        currency: "EUR",
                        amount:
                          data?.getEstimatedCosts.monthly_advisory_tx_costs
                            .amount /
                            100 +
                          data?.getEstimatedCosts.monthly_fund_costs.amount /
                            100,
                      }}
                    />
                  </Card.Text>
                  <Card.Title className="text-center">
                    <Money
                      value={{
                        currency: "EUR",
                        amount:
                          (data?.getEstimatedCosts.monthly_advisory_tx_costs
                            .amount /
                            100 +
                            data?.getEstimatedCosts.monthly_fund_costs.amount /
                              100) *
                          12,
                      }}
                    />
                    {t("perYear")}{" "}
                    <small className="text-accent">
                      <Cost value={addCostsOrReturns(0, 1)} />
                    </small>
                  </Card.Title>
                </Card.Body>
              </InfoCard>
            </div>
          </div>
          <Accordion>
            <div>
              <AccordionHeader
                activeKey={activeKey}
                onSelect={setActiveKey}
                eventKey="0"
              >
                {t("labelSeeCosts")}
              </AccordionHeader>
              <Accordion.Collapse eventKey="0">
                <p className="py-3 p-md-3">
                  {t("invTier1")}
                  <b>{t(`ctier${tier()}`)}</b>. {t("invTier1Suff")}
                  <Table hover striped className="flat mt-4">
                    <thead>
                      <tr>
                        <th className="w-55">
                          {t("item")}
                          <span className="d-none d-md-block">
                            {t("servprov")}
                          </span>
                        </th>
                        <th>
                          {t("ctier1")}{" "}
                          <span className="d-none d-md-block">(€0-100K)</span>
                        </th>
                        <th>
                          {t("ctier2")}{" "}
                          <span className="d-none d-md-block">(€100K+)</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getCostsBreakdown().map((c, i) => (
                        <tr key={i}>
                          <td>
                            {t(c.item)}
                            <span className="subtitle d-none d-md-block mt-1">
                              {t(c.serviceProvider)}
                            </span>
                          </td>
                          <td>
                            <Cost value={c.tier1} />
                          </td>
                          <td>
                            <Cost value={c.tier2} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </p>
              </Accordion.Collapse>
            </div>
            <div>
              <AccordionHeader
                activeKey={activeKey}
                onSelect={setActiveKey}
                eventKey="1"
              >
                <span className="title">
                  {t("labelSeeHowCosts")} ({replacePoi(t("over10years"))})
                </span>
              </AccordionHeader>
              <Accordion.Collapse eventKey="1">
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-left pt-4 mb-4">
                  <div className="w-md-30">
                    <CardTitle>{t("estReturn")}</CardTitle>
                    <InfoCard variant="mt-0 text-center">
                      <Card.Body>
                        <Card.Text className="my-2">
                          <Money
                            size="xlg"
                            value={{
                              currency: "EUR",
                              amount:
                                data?.getEstimatedCosts.total_estimated_returns
                                  .amount / 100,
                            }}
                          />
                        </Card.Text>
                        <Card.Title>
                          <Cost value={getCostOrReturn(2)} />
                          {t("perYear")}{" "}
                        </Card.Title>
                      </Card.Body>
                    </InfoCard>
                  </div>
                  <Minus />
                  <div className="w-md-30">
                    <CardTitle>{t("advTransCost")}</CardTitle>
                    <InfoCard variant="mt-0" leaf>
                      <Card.Body>
                        <Card.Text className="my-2 text-center">
                          <Money
                            size="xlg"
                            value={{
                              currency: "EUR",
                              amount:
                                data?.getEstimatedCosts.total_advisory_tx_costs
                                  .amount / 100,
                            }}
                          />
                        </Card.Text>
                        <Card.Title className="text-center">
                          <Cost value={getCostOrReturn(3)} />
                          {t("perYear")}{" "}
                        </Card.Title>
                      </Card.Body>
                    </InfoCard>
                  </div>
                  <Equals />
                  <div className="w-md-30">
                    <CardTitle>{t("yProfit")}</CardTitle>
                    <InfoCard variant="mt-0">
                      <Card.Body>
                        <Card.Text className="my-2 text-center">
                          <Money
                            size="xlg"
                            value={{
                              currency: "EUR",
                              amount:
                                data?.getEstimatedCosts.total_estimated_returns
                                  .amount /
                                  100 -
                                data?.getEstimatedCosts.total_advisory_tx_costs
                                  .amount /
                                  100,
                            }}
                          />
                        </Card.Text>
                        <Card.Title className="text-center">
                          <Cost value={getCostOrReturn(4)} />
                          {t("perYear")}{" "}
                        </Card.Title>
                      </Card.Body>
                    </InfoCard>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
          <StepButtons
            onPrev={handlePrev}
            onNext={handleNext}
            canContinue={formik.isValid}
          />
        </StepContent>
      </ContentLoader>
    </Step>
  );
};

const Plus = () => (
  <Sign>
    <i className="fa fa-plus"></i>
  </Sign>
);

const Minus = () => (
  <Sign>
    <i className="fa fa-minus"></i>
  </Sign>
);

const Equals = () => (
  <Sign>
    <span className="ob-equals">=</span>
  </Sign>
);

const Sign = ({ children }) => (
  <span className="obd-sign text-gray mx-3 mb-2 mb-md-0">{children}</span>
);

const Cost = ({ value }) => {
  return (
    <>
      {value.isCurrency && (
        <Money
          value={{
            currency: "EUR",
            amount: value.value,
          }}
        />
      )}

      {!value.isCurrency && <Percentage value={value.value} />}
    </>
  );
};

const CardTitle = ({ children }) => (
  <span className="info-card-title text-center d-block mb-1">{children}</span>
);

export default Costs;
