import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import SageModal from "../../Common/SageModal";
import image from "../../../assets/img/dialog/password-icon.png";
import { validatePassword } from "../../../common/validations";

const validate = (values) => {
  const errors = {};

  if (!values.oldPass) {
    errors.oldPass = true;
  } else if (!validatePassword(values.oldPass)) {
    errors.oldPass = true;
  }

  if (!values.newPass) {
    errors.newPass = true;
  } else if (!validatePassword(values.newPass)) {
    errors.newPass = true;
  }

  if (!values.newPassConfirm) {
    errors.newPassConfirm = true;
  } else if (values.newPass !== values.newPassConfirm) {
    errors.newPassConfirm = true;
  }

  return errors;
};

const EditPassword = ({ show, onClose, onSubmit }) => {
  const { t } = useTranslation("settings");

  const formik = useFormik({
    initialValues: {
      oldPass: "",
      newPass: "",
      newPassConfirm: "",
    },
    validate,
    onSubmit,
  });

  return (
    <SageModal
      className="edit-pass-modal"
      image={image}
      show={show}
      title={t("editPassword")}
      onHide={onClose}
      buttons={
        <Button
          size="lg"
          disabled={!formik.isValid}
          onClick={formik.handleSubmit}
        >
          {t("updatePassword")}
        </Button>
      }
    >
      <h5>{t("setNewPass")}</h5>
      <Form className="mt-3" noValidate>
        <Form.Group>
          <Form.Label className="mb-0">{t("currentPassword")}</Form.Label>
          <Form.Control
            name="oldPass"
            type="password"
            value={formik.values.oldPass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.errors.oldPass && formik.touched.oldPass}
          />
          <Form.Control.Feedback type="invalid">
            {t("passwordValidationMessage")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className="mb-0">{t("newPassword")}</Form.Label>
          <Form.Control
            name="newPass"
            type="password"
            value={formik.values.newPass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={formik.errors.newPass && formik.touched.newPass}
          />
          <Form.Control.Feedback type="invalid">
            {t("passwordValidationMessage")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className="mb-0">{t("repeatNewPassword")}</Form.Label>

          <Form.Control
            name="newPassConfirm"
            type="password"
            value={formik.values.newPassConfirm}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isInvalid={
              formik.errors.newPassConfirm && formik.touched.newPassConfirm
            }
          />
          <Form.Control.Feedback type="invalid">
            {t("newPassConfirmValidationMessage")}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </SageModal>
  );
};

export default EditPassword;
