import { Unit } from "../../../common/money";
import Money from "../../../components/Common/Money";

const MoneyAmount = (props) => (
  <Money
    value={{
      currency: "EUR",
      amount: props.amount,
      unit: Unit.CENT,
    }}
    {...props}
  />
);

export default MoneyAmount;
