import { useTranslation } from "react-i18next";

import SageModal from "../../Common/SageModal";

const EditAddress = ({ onClose }) => {
  const { t } = useTranslation(["settings"]);

  return (
    <SageModal
      className="edit-pass-modal rounded"
      show={true}
      title={t("changeAddr")}
      onHide={onClose}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t("toEdit"),
        }}
        className="chat-note mb-4 p-3"
      ></span>
    </SageModal>
  );
};

export default EditAddress;
