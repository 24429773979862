import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./Paginator.scss";

const Paginator = ({ hasMore, onNext, onPrev, pageSize = 10 }) => {
  const { t } = useTranslation("common");

  const [page, setPage] = useState(1);

  const goBack = () => {
    const newPage = page - 1;

    setPage(newPage);
    onPrev(newPage, pageSize);
  };

  const goForward = () => {
    const newPage = page + 1;

    setPage(newPage);
    onNext(newPage, pageSize);
  };

  return (
    <>
      <Button
        disabled={page <= 1}
        className="mr-auto"
        size="sm"
        variant="secondary flat-normal"
        onClick={goBack}
      >
        <i className="fa fa-arrow-circle-left"></i>
      </Button>
      <strong className="text-secondary paginator-page">
        {t("page")} {page}
      </strong>
      <Button
        disabled={!hasMore}
        size="sm"
        variant="secondary"
        className="ml-auto flat-normal"
        onClick={goForward}
      >
        <i className="fa fa-arrow-circle-right"></i>
      </Button>
    </>
  );
};

export default Paginator;
