import { useTranslation } from "react-i18next";

const useFormatPercent = () => {
  const { i18n } = useTranslation();

  const formatter = new Intl.NumberFormat(
    i18n.language === "en" ? "default" : "de-DE",
    {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }
  );

  const formatPercent = (num) => {
    return formatter.format(num / 100);
  };

  return formatPercent;
};

export default useFormatPercent;
