import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import LanguageSwitch from "../Common/LanguageSwitch";
import { CopyrightInfo } from "../Common/Footer";

const NavigationFooter = () => {
  const { t } = useTranslation("obdNav");

  const logout = () => {
    localStorage.removeItem("user-token");
    sessionStorage.clear();
    window.location.href = "/login";
  };

  return (
    <>
      <Form inline>
        <LanguageSwitch />
      </Form>
      <span
        className="mt-3 d-block notice"
        dangerouslySetInnerHTML={{
          __html: t("cantFin"),
        }}
      ></span>
      <Button
        onClick={logout}
        className="inline text-white modify-deposit mt-1 mb-4"
        variant="transparent"
      >
        {t("saveLog")}
      </Button>
      <div className="copyright pt-4">
        <a
          href="https://sagewealth.eu/impressum"
          rel="noreferrer"
          target="_blank"
          className="text-white d-block mb-2"
        >
          {t("imprint")} <Arrow />
        </a>
        <a
          href="https://sagewealth.eu/datenschutz"
          rel="noreferrer"
          target="_blank"
          className="text-white d-block mb-3"
        >
          {t("pPolicy")} <Arrow />
        </a>

        <CopyrightInfo />
      </div>
    </>
  );
};

const Arrow = () => (
  <img
    data-v-367f5ece=""
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAArCAYAAADhXXHAAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADjSURBVHgB7dfRDYIwEIDhqxMYJ3AENyqO4AZMii6gbMB5jZjwAG1pL9eL3p80JgrHl1hCALAsy7KsvwgRPa0R+RrCzJxrO9gZDb7Txxl4G51zp9RBB9jfEfjLmlmCvdF6wK9GWyesLrJvJ9BQBlQHNhPaHhuBTrSu2rBb0PC9U4PFz4NjFTr/rgObgqrB5kBVYCNQv3JsGyzG73q/cY48tgTaBJuAdolz5bA1UFFsLVQMywEVwXJBpbAXDugC+1rMGYA7GtrXQuc5338pvCw+MfOFseRCfQ1UvLAlwLIsy7Ja9gasmZRPofFrQgAAAABJRU5ErkJggg=="
    alt="arrow"
    width="9"
  ></img>
);

export default NavigationFooter;
