/* eslint-disable */
export default {
  /* Introduction Start */
  intro: "Einführung",
  welcome: "Willkommen zum SageWealth-Onboarding!",
  selectProduct: "Wähle deine Anlagestrategie:",
  sfStab:
    "Ideal für Erstanleger, niedriger Volatilität und zum Schutz vor Inflation.",
  sfStabGrowth:
    "Die ideale Anlagestrategie für langfristige Ziele, die kurzfristige Schwankungen in Kauf nimmt.",
  visitProducts:
    "Wenn du dir nicht sicher bist, was du wählen sollst, schau dir unsere Produktvergleich-Seite ",
  here: "hier an",
  minInitInv: "Mindesterstanlage",
  minMonthInv: "Monatliche Mindestsparrate",
  opt: "Optional",
  hpg: "Historisches Portfoliowachstum",
  bFees: "Vor Gebühren",
  whatKind: "Was für ein Konto eröffne ich?",
  whatKindA:
    "Du verwaltest dein Investment über die SageWealth-Plattform, aber im Hintergrund eröffnest du ein Depot bei unserem Partner, der Baader Bank AG. Darüber hinaus wird SageWealth als dein Finanzberater agieren und als gebundener Vertreter der Bank für Vermögen AG tätig sein.",
  cantFin: "Eine letzte Sache!",
  cantFinA:
    "Wenn  du das Onboarding nicht in einer Sitzung abschließen kannst, mach dir keine Sorgen! Dein Fortschritt wird automatisch gespeichert. Du kannst dich jederzeit in der Seitenleiste abmelden und später weitermachen.",
  next: "Weiter",
  prev: "Zurück",
  pleaseNote:
    "Die vergangene Entwicklung sagt nichts über das zukünftige Wachstum; vergangene Performance ist kein Indikator für zukünftige Performance",
  /* Introduction End */

  /* Personal Info Start */
  pInfo: "Persönlichen Angaben",
  pInfoSub:
    "Und los geht's! Um dein Konto zu eröffnen, sind wir gesetzlich verpflichtet, deine persönlichen Daten zu erfassen.",
  title: "Titel",
  salutation: "Ansprache",
  gender: "Geschlecht",
  fName: "Vorname(n) (offz.)",
  mName: "Zweiter Vorname (offz.)",
  lName: "Nachname(n) (offz.)",
  dOB: "Geburtsdatum",
  mStatus: "Familienstand",
  emStatus: "Beschäftigungsstatus",
  countryOB: "Geburtsland",
  cs: "Nationalität (wie in Ihrem Reisepass)",
  ind: "Industrie",
  cityOB: "Geburtsort",
  mob: "Mobiltelefon (mit Ländervorwahl)",
  edu: "Höchstes Bildungsniveau",
  validIsReq: "Eingabe ist erforderlich",
  validMinAmount: "Der Mindestbetrag beträgt {min}",
  /* Personal Info End */

  /* Address & Taxes Start */
  EstoniaTIN: "Persönliche Identifikationsnummer<br />(Isikukood)",
  SpainTIN: "Steuer-ID<br />(Numero DNI / NIF)",
  ItalyTIN: "Steuer-ID<br />(Codice fiscale)",
  IcelandPID: "Persönliche Identifikationsnummer<br />(Kennitala)",
  PolandPID:
    "Persönliche Identifikationsnummer<br />(Numer identyfikacji podatkowej)",
  addressAndTaxes: "Anschrift & Steuern",
  addressAndTaxesSub:
    "Die Eröffnung eines Anlagekontos bei SageWealth ist wie die Eröffnung eines jeden Bankkontos. Hierfür sind wir gesetzlich verpflichtet, deine persönlichen Daten zu erheben.",
  hAddr: "Hausanschrift",
  street: "Straße",
  number: "Hausnummer",
  additionalAddrInfo:
    "Zusätzliche Adressinformationen<br />(c/o, Wohnblock, etc.)",
  pCode: "Postleitzahl",
  city: "Stadt",
  country: "Land",
  fState: "Bundesland",
  taxes: "Steuern",
  countryTaxes: "Land, in dem du steuerpflichtig bist",
  liableTaxes: "Bist du dazu verpflichtet, US-Steuern zu zahlen?",
  taxID: "Steuer-ID (TIN)",
  polExp: "Bist du eine politisch exponierte Person",
  incCode: "Falscher Code",
  tooltip1:
    "Aufgrund der internationalen US-Steuerbestimmungen können wir keine Konten für US-Staatsangehörige oder Personen, die US-steuerpflichtig sind, eröffnen. Wir bedauern diese Beeinträchtigung sehr.",
  tooltip2:
    "Eine politisch exponierte Person ist ein Politiker oder eine Person im unmittelbaren Umfeld eines Politikers, die bezüglich Geldwäsche strengeren Anforderungen als ein Normalbürger unterliegt. Hierzu gehören auch ranghohe Richter, hochrangige Militärbeamte, religiöse Persönlichkeiten und Botschafter, etc. Leider können wir zur Zeit keine Kunden mit diesem Profil annehmen.",
  findTIN: "Wo kann ich diese Nummer finden?",
  /* Address & Taxes End */

  /* Address & Taxes Start */
  finSit: "Deine finanzielle Situation",
  finSitSub:
    "Bevor du investierst, sind wir als Vermögensberater gesetzlich verpflichtet, deine finanzielle Situation zu betrachten. Auf dieser Grundlage basierend, werden wir auf der nächsten Folie eine Anlageempfehlung vornehmen.",
  howMuchEarn: "Wie viel verdienst du monatlich netto?",
  howMuchEarnSub:
    "Dies beinhaltet sämtliche Geldeingänge auf deinem Konten<br />(Gehalt, Rente, passives Einkommen, etc )",
  howMuchSpend: "Wie viel gibst du jeden Monat aus?",
  howMuchSpendSub:
    "Dies umfasst alle Ausgaben, die von deinen Konten abgehen <br/>(Miete, Lebensmittel, laufende Kosten, Schuldentilgung, etc.)",
  howMuchHave: "Wie hoch ist dein Sparguthaben?",
  howMuchHaveSub:
    "Dies beinhaltet Beträge auf all deinen Bankkonten, auf welche du sofortigen Zugriff hast.",
  startAss: "Bewertung beginnen",
  /* Address & Taxes End */

  /* Investment Amount Start */
  invMoreThanSave:
    "Du möchtest mehr investieren, als du an Ersparnissen mitgeteilt hast. Als regulierte Vermögensberater müssen wir sicherstellen, dass deine Angaben korrekt sind. Bitte ändere deine Anlagesumme oder gehe zurück und passe deinen Sparbetrag an.",
  invAmount: "Anlagebetrag",
  invAmountSub:
    "Super! Du bist finanziell gut aufgestellt und kannst mit dem Investieren beginnen. Bitte beachte hierzu unsere Anlageempfehlung und teile uns mit, wie du anfangs und monatlich investieren möchtest.",
  invAmountSubNeg:
    "Leider erfüllst du nicht die Voraussetzungen, um mit SageWealth zu investieren. Bitte beachte unsere Tipps, damit du demnächst loslegen kannst.",
  initInv: " 1. Initial investment DE",
  weAdviseYouKeep: "Wir empfehlen, zwei Monatsausgaben ",
  forEmergencies: " bei deiner Bank für Notfälle aufzubewahren.",
  thisMeans: "Das bedeutet, dass dir ",
  inSavings:
    " in Ersparnissen zur Verfügung stehen, mit denen du anfangen kannst, zu investieren.",
  toDepo: "Ich möchte folgenden Betrag anlegen",
  toDepo2: "Ich möchte folgenden Betrag anlegen",
  monthInv: "Monatlicher Sparplan",
  monthInvSub:
    "Du verdienst mehr, als du ausgibst und bist daher in einer guten Position, um einen monatlichen Sparplan einzurichten. Du kannst es dir leisten, ",
  monthInvSubMonthly: " monatlich zu investieren.",
  monthInvSub2: "Da du höhere Ausgaben ",
  monthInvSub2Suffix:
    " mehr als Einnahmen hast, empfehlen wir, deine Ausgaben zu reduzieren, bevor du einen monatlichen Sparplan einrichtest. Aber letztlich entscheidest du.",
  monthInvSub3:
    "DE Because you are spending as much as you earn, we recommend reducing...",
  everyMonth1: "Jeden Monat am",
  likeToDeposit: "möchte ich",
  likeToDepositSuffix: "als Sparplan anlegen",
  everyMonth: "Jeden Monat am {d}",
  firstMonthly: "Dein monatlicher Sparplan beginnt am ",
  firstMonthlySub:
    "Hinweis: Ein monatlicher Sparplan kann jederzeit angepasst oder gekündigt werden.",
  ourAdvice: "1. Unsere Empfehlung:",
  adviceMinInvest: "SageWealth hat eine Mindestanlagesumme von ",
  adviceHowever: " Wir empfehlen dir jedoch, 2 Monatsausgaben ",
  adviceAcumm: " für Notfälle anzusparen. Sobald du also ",
  adviceCanStartInvest:
    " angespart hast, kannst du mit dem Investieren beginnen.",
  adviceGoodNews:
    "Die gute Nachricht ist, dass du mehr verdienst als du ausgibst. Das ist eine gute Ausgangslage, um bald mit dem Investieren zu beginnen!",
  adviceSpendingMore:
    "Da du höhere Ausgaben als Einnahmen hast, wird es schwierig werden, genug Geld anzusparen, um mit dem Investieren zu beginnen.",
  pGoBack:
    "Falls unsere Einschätzung falsch ist, bitten wir dich noch einmal zurück zu gehen, um deine Angaben anzupassen.",
  letsPrepare:
    "2. Wir würden dich gerne beim Investieren unterstützen, und haben dazu relevanten Lesestoff vorbereitet:",
  saveProgress: "Fortschritt speichern",
  minimum: "Mindestbetrag",
  /* Investment Amount End */

  /* Investment Horizon Start */
  invHoriz: "Anlagehorizont",
  invHorizSub:
    "SageWealth Stability ist eine Anlage mit geringer Volatilität, sodass du dein Geld in den meisten Marktsituationen bedenkenlos entnehmen kannst. Um jedoch den größten Nutzen aus deiner Anlage zu ziehen, ist es wichtig, langfristig zu investieren.",
  howLong: "Wie lange willst du deine Anlage halten?",
  howLongNote:
    "Bitte beachte, dass du dein Geld unabhängig vom ausgewählten Zeitraum jederzeit entnehmen kannst",
  startTerm: "Kurzfristig",
  shortTerm: "Kurzfristig",
  midTerm: "Mittelfristig",
  longTerm: "Langfristig",
  year: "Jahre",
  estEff: "Mal sehen, wie sich das auf deine geschätzten Erträge auswirkt...",
  est10Year: "Geschätzte Erträge nach {poi} Jahren",
  totalInv: "Gesamtanlage",
  overCourse: "Während des Anlagehorizonts",
  futBalance: "Zukünftige Bilanz",
  profit: "Profit",
  afterFees: "pa nach Kosten",
  howCalc: "Wie wird das berechnet?",
  /* Investment Horizon Start */

  /* Your Investment Start */
  undInv: "Dein Investment im Überblick",
  undInvSub:
    "Als Anlageberater müssen wir sicherstellen, dass unsere Kunden ihre Anlage auch wirklich verstehen. Deshalb haben wir eine kurze Zusammenfassung bereitgestellt. Bitte sorgfältig lesen.",
  undInvDetail: "SageWealth Stability im Detail",
  undInvDetailGrowth: "SageWealth Growth im Detail",
  undInvDetailSub:
    "Wir haben Markowitz' nobelpreisgekrönte Strategie zur Zusammenstellung des Portfolios genutzt. Unser ETF-Portfolio ist das nachhaltigste auf dem Markt und hat eine starke risikoangepassten Performance.",
  sfStabGlob:
    "SageWealth Stability ist ein weltweit diversifiziertes Anlageportfolio, das aus einer Mischung aus Anleihen-, Aktien- und Rohstoff-ETFs besteht. Das Portfolio wurde mit dem Ziel zusammengestellt, Schwankung zu minimieren und dennoch stetig Erträge zu erzielen. Alle Vermögenswerte haben ein hohes Nachhaltigkeitsrating.",
  sfStabGlobGrowth:
    "SageWealth Growth ist ein global diversifiziertes Anlageportfolio, das hauptsächlich aus Aktien-ETFs besteht. Das Portfolio wurde mit der Zielsetzung zusammengestellt, die Rendite zu maximieren. Alle Vermögenswerte wurden so ausgewählt, dass sie ein hohes Nachhaltigkeitsrating aufweisen.",
  seeAllPort: "Alle Portfolio-",
  seeAllPortBtn: "Bestände im Detail sehen",
  moreAdv:
    'Weitere Informationen zu SageWealth Stability findest du im <span class="text-primary">Key Investor Document (KID)</span>',
  thisInvStrat:
    "Diese Anlage ist für Privatkunden geeignet, die ihre Ersparnisse vor Inflation schützen und ihr Vermögen aufbauen möchten. Sie ist für Kunden gedacht, die zumindest ein Grundwissen über die Funktionsweise von Anlagen haben. Die Strategie ist nicht als Altersvorsorge konzipiert, richtet sich nicht an Anleger mit einem kurzfristigen Anlagehorizont (kürzer als 3 Jahre) und auch nicht an Anleger, die keine oder nur geringe Verluste tragen können.",
  thisInvStratGrowth:
    "Diese Anlagestrategie richtet sich an Privatkunden, die ihr Vermögen langfristig steigern wollen. Sie eignet sich für Kunden, die zumindest über ein Grundwissen über Investitionen verfügen. Die Strategie richtet sich an Anleger mit einem langfristigen Anlagehorizont (über 7 Jahre) und nicht an Anleger, die keine oder nur geringe Verluste verkraften können.",
  thisInvStratSub:
    "Für eine Auffrischung der Grundlagen des Investierens, lies",
  thisInvStratSubBtn: "unsere  Einführung",
  theVal:
    "Der Wert deiner Anlage wird im Laufe der Zeit schwanken.<br/>Investitionen werden typischerweise einer Risikoklasse zwischen 1 (sicherheitsorientiert, geringe Rendite) und 7 (risikobereit, höchste Rendite) zugeordnet, diese Anlage erhält eine 4, d. h. sie ist etwas konservativ, erzielt aber dennoch ein stetiges Wachstum. Diese Strategie ist nur für Anleger geeignet, die Verluste (bis hin zum vollständigen Verlust des investierten Geldes) tragen können.",
  theValGrowth:
    "Der Wert deiner Anlage wird regelmäßig schwanken.<br/>Anlagen werden in der Regel mit einer Risikobewertung zwischen 1 (sicherheitsorientiert, geringe Rendite) und 7 (risikobereit, höchste Rendite) eingestuft, diese Anlage wird mit 6 bewertet, was bedeutet, dass sie auf hohe Renditen und hohe Volatilität ausgelegt ist. Diese Strategie ist für Anleger geeignet, die finanzielle Verluste verkraften können (bis hin zum Totalverlust des investierten Geldes).",
  pleaseRead: "Bitte lies und akzeptiere die aufgeführten Bedingungen",
  iConfirm:
    "Ich bestätige, dass SageWealth %p für meine Anlageziele geeignet ist und meine Kenntnisse ausreichend sind, um die damit verbundenen Chancen und Risiken einschätzen zu können.",
  labWhatIsSfG: "Was ist SageWealth Growth?",
  labWhatIsSfS: "Was ist SageWealth Stability?",
  labWhoIsFor: "Für wen ist es gedacht? ",
  labWhatAreRisks: "Was sind die Risiken?",
  accept: "AKZEPTIEREN",
  resumeOnboarding: "Resume Onboarding",
  fundName: "Abkürzung",
  type: "Art",
  allocation: "Allokation",
  close: "Schließen",
  /* Your Investment End */

  /* Costs Start */
  costs: "Kosten",
  costsSub:
    "Dein SageWealth-Konto wird am 5ten Werktag eines jeden Monats belastet. Deine Gebühren werden als Prozentsatz deines gesamten Anlagebetrags berechnet und passen sich an Veränderungen im Anlagebetrag an. Alle Gebühren sind inkl. der Steuer.",
  youPay: "Bei deiner Investitionshöhe zahlst du monatlich:",
  advisoryAcc: "Verwaltungs- und Transaktionskosten",
  monthly: "Montatlich",
  perYear: " Jährlich",
  fundCosts: "Fondskosten (TER of ETFs)",
  total: "Monatliche Gesamtkosten",
  noAddFees: "No additional or hidden fees!",
  invTier1: "Mit deiner Anlagesumme befindest du dich derzeit in ",
  invTier1Suff:
    "Wenn sich deine Anlagegröße ändert, wechselst du evtl. in eine andere Stufe. Du wirst sofort benachrichtigt, wenn das passiert. Kostenänderungen werden automatisch berechnet.",
  invTier1Link: "Kostenrechner",
  estReturn: "Geschätzte Rendite",
  returnsAlrdy: "Renditen berücksichtigen bereits Fondskosten",
  advTransCost: "Gesamtkosten",
  over10years: "in {poi} Jahren",
  yProfit: "Dein Profit",
  labelSeeCosts: "Siehe Kostenaufstellung & Stufen",
  item: "Artikel",
  servprov: "Dienstleister",
  ctier1: "Stufe 1",
  ctier2: "Stufe 2",
  ctier3: "Stufe 3",
  regcompl: "Haftungsdach",
  transfeeacc: "Transaktions- und Kontoführungskosten",
  portfmngmt: "Portfolio-Verwaltung und Beratungsplattform",
  prodcost: "Fondskosten",
  entexfees: "Einstiegs- und Ausstiegskosten",
  ETFter: "ETF-Kosten",
  labelSeeHowCosts: "Auswirkung auf den Ertrag",
  /* Costs End */

  /* Connect Bank Account Start */
  cBankAcc: "Checkout: Deine Bankverbindung",
  cBankAccSub:
    "DE: We use SEPA to manage your transactions. All you have to do is provide your IBAN, and we make sure your deposits and withdraws happen automatically.",
  nameBankAcc: "Name des Kontoinhabers",
  iban: "IBAN",
  permission:
    'Ich erteile der "Bank für Vermögen (BfV)" im Auftrag von "SageWealth" die Erlaubnis, Gelder per SEPA von meinem Konto einzuziehen. Zugleich weise ich mein Kreditinstitut an, die auf mein Konto gezogenen Lastschriften einzulösen. Die SEPA-ID der "BfV" lautet DE79ZZZ00000666392.',
  notice:
    "Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.",
  IAccept: "ICH AKZEPTIERE",
  iniInv: "Ihre Investition",
  invToday: "Heute investiert",
  initialInvestment: "Anlagebetrag",
  monthlyAdd: "Monatliche Sparrate",
  accountOwner: "Kontoinhaber*in",
  bank: "Bank",
  bic: "BIC",
  sepaWarning:
    "Wir konnten nicht überprüfen, ob deine Bank SEPA unterstützt. Wenn du Zugang zu einem anderen Konto hast, empfehlen wir dir, stattdessen dieses Konto zu verwenden. ",
  /* Connect Bank Account End */

  /* Terms & Conditions Start */
  TAC: "Checkout: Allgemeine Geschäftsbedingungen",
  tacSub:
    'Bitte lies die folgenden Geschäftsbedingungen und die verlinkten rechtlichen Dokumente (die ebenfalls an deine E-Mail gesendet wurden). Durch Klicken auf "SENDEN" unterschreibst du diese Dokumente rechtsgültig, wodurch dein Konto eröffnet und dein Geld überwiesen wird.',
  pReadLabel: "Bitte die folgenden Bedingungen lesen und akzeptieren",
  baaderTerms: `Hiermit beantrage ich die Eröffnung eines Wertpapierdepots (mit Verrechnungskonto) bei der "Baader Bank AG" und bestätige die Richtigkeit der von mir gemachten Angaben. Ich bestätige, dass ich den Kontoeröffnungsvertrag (und die beigefügten vorvertraglichen Informationen) erhalten und abgespeichert habe. Des weiteren verstehe ich, dass dieser Kontoeröffnungsantrag den Bedingungen des `,
  baaderTermsBtn: "Kontoeröffnungsvertrages",
  baaderTermsSuff: ` unterliegt. Sollten sich meine Angaben ändern, bin ich verpflichtet, "Sagefund Digital Wealth Management GmbH" unverzüglich zu informieren und ggf. entsprechende Nachweise zu erbringen. Ich stimme hiermit ausdrücklich zu, dass die "Baader Bank AG" bereits vor Ablauf der gesetzlichen Widerrufsfrist mit der Ausführung der aus dem Depotvertrag geschuldeten Leistung beginnen darf. Hiermit bestätige ich, dass die Bank Orders außerhalb eines regulierten Marktes oder eines multilateralen Handelssystems auch außerbörslich ausführen darf.`,
  vermogenTerms: `Ich erkläre mich mit der Geltung der Vertragsbedingungen einverstanden. Ich habe die allgemeinen Geschäftsbedingungen und Informationen der "Bank für Vermögen AG" zur Kenntnis genommen. Gleichzeitig bestätige ich den Empfang und die Aufbewahrung der `,
  vermogenTermsBtn1: "Geeignetheitserklärung",
  vermogenTermsBtn2: "des Vermögensverwaltungsvertrags",
  vermogenTermsAnd: " und ",
  vermogenTermsBtn3: "des Kundeninformationspakets",
  vermogenTermsSuff: ` der "Bank für Vermögen AG".`,
  telephoneTerms: `Ich bin damit einverstanden, dass die telefonische und elektronische Kommunikation mit "Sagefund Digital Wealth Management GmbH", "Baader Bank AG" und "Bank für Vermögen AG" aufgezeichnet wird. Aufzeichnungen werden bis zu 7 Jahre lang gespeichert. Ich entbinde die "Baader Bank AG" gegenüber der "Bank für Vermögen AG" und "Sagefund Digital Wealth Management GmbH" von den Pflichten des Bankgeheimnisses und willige in die Übermittlung meiner Daten wie in Abschnitt 10-a des Kontoeröffnungsvertrags beschrieben ein. Ich weiß, dass ich diese  Einwilligung jederzeit mit Wirkung für die Zukunft durch Mitteilung an service@baaderbank.de widerrufen kann.`,
  ownTerms:
    "Ich handle gemäß § 10 Abs. 1 Nr. 2 Geldwäschegesetz in eigenem wirtschaftlichen Interesse und nicht auf fremde Veranlassung.",
  ownTermsSub: "",
  acceptAndSubmit: "Senden",
  downloadPSD: "Download PSD",
  /* Terms & Conditions Start */

  /* Verify Identity Start */
  vID: "Identitätsprüfung",
  vAddress: "Verifiziere deine Adresse",
  vIDSub:
    "Die Vorschriften verlangen, dass wir mit allen Neukunden einen Live-Videogespräch führen müssen, um deren Identität zu überprüfen.",
  vAddrSub:
    "Wir sind verpflichtet, deine Adresse zu verifizieren. Bitte lade eines der folgenden Dokumente hoch (PDF- und Bildformate werden unterstützt).",
  vIdInstructions: "Anweisungen:",
  vIdInstr1:
    "Bitte halte einen Personalausweis oder Reisepass bereit (der Führerschein ist nicht zulässig)",
  vIdInstr2:
    "Klicke auf den grünen Button unten, um einen Live-Identifikationsanruf mit unserem Partner POSTIDENT zu starten. Dies öffnet dann eine neue Website",
  vIdInstr3:
    "Du hast die Wahl, die App herunterzuladen oder den Identifizierungsanruf in deinem Browser durchzuführen. Wir empfehlen dir, das Gerät mit der besten Videoqualität zu verwenden",
  inOrder:
    "Um das Videogespräch erfolgreich zu absolvieren, benötigst du folgende Unterlagen:",
  IDPass: "Personalausweis oder Reisepass",
  tooltipIdPass: "Add me",
  note1: "Hinweis: Ein Führerschein zählt nicht als Identitätsnachweis!",
  note2:
    "Hinweis: Wenn du gerade nicht in der Lage bist, ein Videogespräch zu führen, kannst du den Prozess jederzeit ein anderes Mal fortsetzen.",
  labelPID: "Personalausweis Reisepass",
  letsGo: "LOS GEHT'S",
  clickHereToStart: "Klicken sie hier um zu Starten",
  clickHereToContinue: "Click here to continue",
  verFailed:
    "Verification failed, please follow the instructions and try again",
  proofAddr: "Gültige Dokumente:",
  preferred: "Bevorzugt",
  pref1: "Meldebescheinigung",
  pref2: "Wohnsitzbestätigung",
  pref3: "Aufenthaltsbestätigung",
  lessPreferred: "Weniger bevorzugt (darf nicht älter als 6 Monate sein)",
  lPref1: "Bank-, Versicherungs- oder Bausparkassenauszug",
  lPref2:
    "Rechnung eines Versorgungsunternehmens (Strom-, Wasser-, Gas-, Telefon- oder Internetrechnung)",
  lPref3: "Steuerbescheid",
  lPref4: "Steuerbescheinigung",
  lPref5: "Sozialversicherungsbescheid",
  lPref6: "Rentenversicherung",
  dropFiles: "Datei hier ablegen",
  fileTypes: "PDF- und Bildformate",
  filesOr: "oder",
  labelAdd: "Adressnachweis hochladen",
  uploadedFiles: "hochgeladene Datei",
  /* Verify Identity End */
};
