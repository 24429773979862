/* eslint-disable */
export default {
  NONE: "",
  DR: "Doctor",
  PROF: "Professor",

  MR: "Mr",
  MRS: "Mrs",

  male: "Male",
  female: "Female",
  OTHER: "Other",

  MARRIED: "Married",
  SINGLE: "Not Married",

  SELF_EMPLOYED: "Self Employed",
  EMPLOYED: "Employed",
  UNEMPLOYED: "Unemployed",
  RETIRED: "Retired",
  STUDENT: "Student",

  HIGH_SCHOOL: "High School",
  VOCATIONAL_TRAINING: "Vocational Training",
  TECHNICAL_COLLEGE: "Technical College",
  UNDERGRADUATE_DEGREE: "Undergraduate Degree",
  POSTGRADUATE_DEGREE: "Postgraduate Degree",

  AGRICULTURE_FORESTRY_HUNTING: "Agriculture, forestry, hunting",
  MINING_QUARRYING: "Mining and quarrying",
  GLASS_MANUFACTURE_STONE_PROCESSING:
    "Manufacture of glass and glassware, stone processing",
  MOTOR_VEHICLES_OR_PARTS_MANUFACTURING:
    "Manufacture of motor vehicles and motor vehicle parts",
  POWER_SUPPLY: "Power supply",
  WATER_SUPPLY_SEWAGE_WASTE_DISPOSAL:
    "Water supply, sewage and waste disposal and removal of environmental pollution",
  BUILDING_CONSTRUCTION: "Building construction, building construction",
  MAINTENANCE_REPAIR_TRADE_MOTOR_VEHICLES_AND_SERVICE:
    "Maintenance / repair / trade in motor vehicles and service",
  RETAIL_TRADE: "Retail trade (without trade in motor vehicles)",
  STORAGE_AND_PROVISION_TRANSPORT_SERVICES:
    "Storage and provision of transport services",
  GASTRONOMY: "Gastronomy",
  INFORMATION_SERVICES: "Information services",
  REAL_ESTATE_AND_HOUSING: "Real estate and Housing",
  OTHER_FREELANCE_SCIENTIFIC_ACTIVITIES:
    "Other freelance / scientific activities",
  PROVISION_OF_ECONOMIC_SERVICES:
    "Provision of economic services for companies & individuals",
  EDUCATION_AND_TEACHING: "Education and teaching",
  HEALTHCARE: "Healthcare",
  CREATIVE_ACTIVITIES: "Creative, artistic and entertaining activities",
  SPORTS_ENTERTAINMENT_RECREATION:
    "Other service activities - sports, entertainment and recreation",
};
