import { Button, Form, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useState } from "react";

import logo from "../../assets/img/logo_white2.png";
import { useSageMutation } from "../Common/GqlHooks";
import ContentLoader from "../Common/ContentLoader";
import { validateEmail } from "../../common/validations";
import { REQUIRE_PASSWORD_RESET } from "../../api/graphql";

import AuthLayout from "./AuthLayout";

const ForgotPassword = () => {
  const [reqSuccess, setReqSuccess] = useState(false);
  const { t } = useTranslation(["forgotPassword", "auth"]);

  const validate = (values) => {
    const errors = {};

    if (!values.email || !validateEmail(values.email)) {
      errors.email = true;
    }

    return errors;
  };

  const [requestReset, loading] = useSageMutation(REQUIRE_PASSWORD_RESET);

  const onSubmit = (values) => {
    requestReset({
      variables: {
        email: values.email.trim(),
      },
    })
      .then(() => {
        setReqSuccess(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit,
  });

  return (
    <AuthLayout>
      <Col
        className="right-pane d-flex flex-column align-items-center justify-content-center"
        id="auth-right-pane"
      >
        <Row className="justify-content-center w-100">
          <Col xs={12} md={8} lg={8}>
            <div className="p-3">
              {reqSuccess && (
                <div className="text-center">
                  <img src={logo} alt="SageWealth" width="200" />
                  <h5 className="mt-4">{t("emailSent")}</h5>
                </div>
              )}

              {!reqSuccess && (
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group className="text-center">
                    <img src={logo} alt="SageWealth" width="200" />
                    <Form.Label className="mb-0 mt-4 auth-title">
                      {t("enterEmail")}
                    </Form.Label>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="mb-0 mt-3">
                      {t("auth:email")}
                    </Form.Label>
                    <Form.Control
                      className="lg"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isValid={!formik.errors.email && formik.touched.email}
                      isInvalid={formik.errors.email && formik.touched.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {t("auth:validEmail")}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    size="lg"
                    disabled={!formik.isValid}
                    className="flat w-100"
                  >
                    {t("submit")}
                  </Button>
                </Form>
              )}
            </div>
          </Col>
        </Row>
      </Col>

      <ContentLoader loading={loading} isInline={false} />
    </AuthLayout>
  );
};

export default ForgotPassword;
