import * as moment from "moment";
import { Form } from "react-bootstrap";
// eslint-disable-next-line import/no-named-as-default
import DateRangePicker from "react-bootstrap-daterangepicker";

const SageDatePicker = (props) => {
  const formik = props.formik;
  const field = props.field;

  const handleChange = (e) => {
    const d = new moment(e.target.value, "DD.MM.YYYY")
      .utcOffset(0, true)
      .format();

    props.handleChange(d);
  };

  const getDate = (val) => {
    if (val === "") return new moment().format("DD.MM.YYYY");

    return new moment(val).format("DD.MM.YYYY");
  };

  return (
    <DateRangePicker
      onHide={(e) => console.log(e)}
      onApply={handleChange}
      initialSettings={{
        singleDatePicker: true,
        showDropdowns: true,
        maxDate: props.maxDate,
        buttonClasses: "btn flat",
        autoApply: true,
        locale: {
          format: "DD.MM.YYYY",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          weekLabel: "W",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          firstDay: 1,
        },
      }}
    >
      <Form.Control
        type="text"
        defaultValue={getDate(props.defaultValue)}
        className="form-control"
        isInvalid={formik.errors[field] && formik.touched[field]}
        readOnly
      />
    </DateRangePicker>
  );
};

export default SageDatePicker;
