import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";

import { NotificationStoreContext } from "../../components/Common/NotificationStore";
import { MixpanelEvents } from "../../common/common";
import { VERIFY_EMAIL } from "../../api/graphql";
import { useSageMutation } from "../../components/Common/GqlHooks";

const VerifyEmail = () => {
  const [verifyEmail] = useSageMutation(VERIFY_EMAIL);
  const { token } = useParams();
  const { t } = useTranslation("auth");
  let history = useHistory();
  const notifications = useContext(NotificationStoreContext);

  useEffect(() => {
    verifyEmail({
      variables: {
        token: token,
      },
    })
      .then(() => {
        console.log("success");
        mixpanel.track(MixpanelEvents.onboarding.EMAIL_VERIFIED);

        const token = localStorage.getItem("user-token");

        if (!token) {
          notifications.addMessage(t("verificationSuccess"));
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        const token = localStorage.getItem("user-token");

        if (!token) {
          history.push("/login");
          return;
        }

        history.push("/onboarding");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyEmail]);

  return <div></div>;
};

export default VerifyEmail;
