import { Col, Container, Nav, Row } from "react-bootstrap";
import Switch from "react-bootstrap/esm/Switch";
import { BrowserRouter, Route, NavLink } from "react-router-dom";

import ClientDetails from "./ClientDetails/ClientDetails";
import Clients from "./Clients/Clients";
import Dashboard from "./Dashboard/Dashboard";
import CashMovement from "./CashMovement/CashMovement";
import "./Backoffice.scss";

const BackofficeLayout = () => {
  return (
    <Container fluid>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Row>
          <Col
            xs={2}
            className="bg-secondary p-2"
            style={{
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <h4 className="text-white p-3">
              <Nav.Link
                as={NavLink}
                to="/backoffice"
                exact
                className="p-0 text-white"
              >
                SageWealth
              </Nav.Link>
              <small className="d-block text-primary ml-5">BackOffice</small>
            </h4>
            <Nav
              variant="pills"
              defaultActiveKey="/backoffice/clients"
              className="flex-column mt-5"
            >
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/backoffice"
                exact
              >
                Dashboard
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/backoffice/clients"
                exact
              >
                Client Overview
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/backoffice/cash-movement"
                exact
              >
                Cash movement
              </Nav.Link>
            </Nav>
          </Col>
          <Col
            className="bg-lighter pt-2 px-2"
            style={{
              height: "100vh",
              overflowY: "auto",
            }}
          >
            <Switch className="p-0">
              <Route exact path="/backoffice">
                <Dashboard />
              </Route>
              <Route exact path="/backoffice/clients">
                <Clients />
              </Route>
              <Route exact path="/backoffice/client/:id">
                <ClientDetails />
              </Route>
              <Route exact path="/backoffice/cash-movement">
                <CashMovement />
              </Route>
            </Switch>
          </Col>
        </Row>
      </BrowserRouter>
    </Container>
  );
};

export default BackofficeLayout;
