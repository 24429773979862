import * as moment from "moment";
import { useContext } from "react";

import "moment/locale/en-in";
import "moment/locale/de";
import { LanguageStoreContext } from "../../Common/LanguageStore";

const NextInvestmentDate = ({ investmentDay, initialPeriod = null }) => {
  const lang = useContext(LanguageStoreContext);

  const nextInvestmentDate = () => {
    let date = new moment();

    if (initialPeriod) {
      date = new moment(date.add(initialPeriod));
    }

    if (date.date() > investmentDay) {
      date = new moment(date.add(1, "month"));
    }

    date = new moment(date.date(investmentDay)).locale(lang.lang);

    return lang.lang === "de"
      ? date.format("dddd, [den] D[.] MMMM YYYY[.]")
      : date.format("dddd, MMMM D, YYYY[.]");
  };

  return <span>{nextInvestmentDate()}</span>;
};

export default NextInvestmentDate;
