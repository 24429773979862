export default {
  founder: "Gründer und Finanzberater",
  title: "Als deine Finanzberater sind wir immer für dich da",
  question1:
    "Fragst du dich, ob es der richtige Zeitpunkt ist, mehr zu investieren?",
  question2: "Haben sich deine Lebensziele geändert?",
  question3: "Lassen uns darüber reden!",
  question4:
    "Über den unten stehenden Link kannst du einen Termin mit uns vereinbaren.",
  button: "Sprich mit deinem Berater",
};
