import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { Form, Dropdown, Card } from "react-bootstrap";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import { OnboardingStoreContext } from "../OnboardingStore";
import Money from "../../Common/Money";
import { Currency } from "../../../common/money";
import InvestmentCard from "../InvestmentCard";
import FormikMoneyInput from "../../Common/FormikMoneyInput";
import FormikCustomSelect from "../../Common/FormikCustomSelect";
import DayOfMonth from "../../Common/DayOfMonth";
import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import { useSageLazyQuery, useSageMutation } from "../../Common/GqlHooks";
import {
  OB_SAVE_INVESTMENT_PLAN,
  OB_GET_ESTIMATED_RETURNS,
} from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import NextInvestmentDate from "../../Dashboard/Landing/NextInvestmentDate";
import InfoCard from "../InfoCard";
import FormikRadioCard from "../../Common/FormikRadioCard";
import { MixpanelEvents, horizon } from "../../../common/common";

import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import StepButtons from "./StepButtons";

const InvestmentAmountPathOne = ({
  minimumInvestment,
  minimumMonthlyInvestment,
  twoMonths,
  effectiveSavings,
  earnSpendDiff,
  savings,
}) => {
  const obStore = useContext(OnboardingStoreContext);
  const [sGet] = useSessionStorage("ob");
  const { t } = useTranslation([
    "steps",
    "obdNav",
    "constants",
    "common",
    "dashboard",
  ]);

  const spendingMore = () => earnSpendDiff < 0;
  const spendingLess = () => earnSpendDiff > 0;
  const spendingAll = () => earnSpendDiff === 0;

  const validate = (values) => {
    const errors = {};

    if (!values.initial_investment) errors.initial_investment = true;
    if (!values.horizon) errors.horizon = true;
    else if (
      values.initial_investment < minimumInvestment ||
      values.initial_investment > 5000000
    ) {
      errors.initial_investment = true;
    } else if (values.initial_investment > savings) {
      errors.initial_investment = true;
    }

    if (values.day_of_month && !values.monthly_amount) {
      errors.monthly_amount = true;
    }

    if (values.monthly_amount && !values.day_of_month) {
      errors.day_of_month = true;
    }

    if (
      values.monthly_amount &&
      (values.monthly_amount < minimumMonthlyInvestment ||
        values.monthly_amount > 5000)
    ) {
      errors.monthly_amount = true;
    }

    return errors;
  };

  const [submit, loading] = useSageMutation(OB_SAVE_INVESTMENT_PLAN);

  const onSubmit = (values) => {
    const variables = {
      investment_plan: {
        day_of_month: parseInt(values.day_of_month),
        horizon: formik.values.horizon,
        initial_investment: values.initial_investment
          ? {
              currency: "EUR",
              unit: "CENT",
              amount: values.initial_investment * 100,
            }
          : null,
        monthly_amount: values.monthly_amount
          ? {
              currency: "EUR",
              unit: "CENT",
              amount: values.monthly_amount * 100,
            }
          : null,
      },
    };

    submit({
      variables,
    })
      .then(() => {
        obStore.refresh();
        mixpanel.track(MixpanelEvents.onboarding.INVESTMENT_AMOUNT_COMPLETED);
        obStore.next();
      })
      .catch((e) => console.log(e));
  };

  const convertAmount = (amount) => (amount ? amount / 100 : amount);

  const formik = useFormik({
    initialValues: {
      initial_investment: convertAmount(
        cachedVal(
          sGet,
          obStore.me.client.investment_plan?.initial_investment,
          "amount"
        )
      ),
      monthly_amount: convertAmount(
        cachedVal(
          sGet,
          obStore.me.client.investment_plan?.monthly_amount,
          "amount"
        )
      ),
      day_of_month: cachedVal(
        sGet,
        obStore.me.client.investment_plan,
        "day_of_month"
      ),
      horizon: cachedVal(sGet, obStore.me.client.investment_plan, "horizon"),
    },
    validate,
    onSubmit,
  });

  const renderer = ({ value }) => {
    return value === "" ? "" : <DayOfMonth value={parseInt(value)} />;
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const [getEstimatedReturns, loadingReturns, estimatedReturns] =
    useSageLazyQuery(OB_GET_ESTIMATED_RETURNS);

  useEffect(() => {
    if (!formik.values.horizon || !formik.values.initial_investment) return;

    if (
      formik.values.initial_investment < minimumInvestment ||
      formik.values.initial_investment > savings
    ) {
      // formik.setFieldValue("horizon", null);
      return;
    }

    if (
      formik.values.monthly_amount &&
      formik.values.monthly_amount < minimumMonthlyInvestment
    ) {
      // formik.setFieldValue("horizon", null);
      return;
    }

    getEstimatedReturns({
      variables: {
        period_years: horizon[obStore.product][formik.values.horizon],
        initial_investment: formik.values.initial_investment
          ? {
              currency: "EUR",
              unit: "CENT",
              amount: formik.values.initial_investment * 100,
            }
          : null,
        monthly_investment: formik.values.monthly_amount
          ? {
              currency: "EUR",
              unit: "CENT",
              amount: formik.values.monthly_amount * 100,
            }
          : null,
        product: obStore.product,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.horizon,
    formik.values.monthly_amount,
    formik.values.day_of_month,
    formik.values.initial_investment,
    getEstimatedReturns,
  ]);

  const handleInvestmentPlanChange = () => {
    // formik.setFieldValue("horizon", formik.values.horizon);
  };

  useEffect(() => {
    if (!estimatedReturns) return;

    const elem = document.querySelector(".step");
    elem.scrollTop = elem.scrollHeight;
    // elem.scrollTo(0, window.innerHeight);
  }, [estimatedReturns]);

  const monthlyValid = () => {
    if (!formik.values.day_of_month) return true;

    return (
      formik.values.monthly_amount &&
      formik.values.monthly_amount >= minimumMonthlyInvestment
    );
  };

  return (
    <>
      <StepHeader title={t(`obdNav:invAmount`)}>{t("invAmountSub")}</StepHeader>
      <StepContent>
        <Form noValidate>
          <h5>{t("invAmount")}</h5>
          <InvestmentCard>
            {t("weAdviseYouKeep")}
            (
            <Money
              decimals={0}
              value={{
                currency: Currency.EUR,
                amount: twoMonths,
              }}
            />
            ){t("forEmergencies")}
            <br />
            {t("thisMeans")}
            <b>
              <Money
                decimals={0}
                value={{
                  currency: Currency.EUR,
                  amount: Math.abs(effectiveSavings),
                }}
              />
            </b>
            {t("inSavings")}
          </InvestmentCard>

          <Form.Group className="d-flex flex-column flex-md-row align-items-left align-items-md-center mt-4 lg inline">
            <h4 className="mr-3">{t("toDepo")}</h4>
            <FormikMoneyInput
              allowNegative={false}
              formik={formik}
              field="initial_investment"
              onChange={handleInvestmentPlanChange}
              className="my-3"
            />

            {!!formik.values.initial_investment &&
              formik.values.initial_investment < minimumInvestment && (
                <span className="ml-md-3 notice text-danger d-block">
                  <Money
                    variant="text-danger"
                    decimals={0}
                    value={{
                      currency: Currency.EUR,
                      amount: minimumInvestment,
                    }}
                  />{" "}
                  {t("minimum")}
                </span>
              )}
          </Form.Group>

          {!!formik.values.initial_investment &&
            formik.values.initial_investment > savings && (
              <span className="text-danger notice">{t("invMoreThanSave")}</span>
            )}

          {spendingLess() && (
            <>
              <h5>{t("monthInv")}</h5>
              <InvestmentCard>
                {t("monthInvSub")}
                <b>
                  <Money
                    decimals={0}
                    value={{
                      currency: Currency.EUR,
                      amount: Math.abs(earnSpendDiff),
                    }}
                  />
                </b>
                {t("monthInvSubMonthly")}
              </InvestmentCard>
            </>
          )}

          {spendingMore() && (
            <>
              <h5>{t("monthInv")}</h5>
              <InvestmentCard variant="accent">
                {t("monthInvSub2")}
                <b>
                  <Money
                    decimals={0}
                    value={{
                      currency: Currency.EUR,
                      amount: Math.abs(earnSpendDiff),
                    }}
                  />
                </b>
                {t("monthInvSub2Suffix")}
              </InvestmentCard>
            </>
          )}

          {spendingAll() && (
            <>
              <h5>{t("monthInv")}</h5>
              <InvestmentCard variant="accent">
                {t("monthInvSub3")}
              </InvestmentCard>
            </>
          )}

          <Form.Group className="d-flex flex-column flex-md-row align-items-left align-items-md-center mt-4 mb-0 lg inline">
            <div>
              <h4 className="mr-3">{t("everyMonth1")}</h4>
              <div>&nbsp;</div>
            </div>
            <div>
              <FormikCustomSelect
                selectedRenderer={renderer}
                formik={formik}
                field="day_of_month"
                onChange={handleInvestmentPlanChange}
              >
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="">---</Dropdown.Item>
                  <Dropdown.Item eventKey={1}>
                    <DayOfMonth value={1} />
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={15}>
                    <DayOfMonth value={15} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </FormikCustomSelect>
              <div>&nbsp;</div>
            </div>
            <div>
              <h4 className="mx-md-3">{t("likeToDeposit")}</h4>
              <div>&nbsp;</div>
            </div>
            <div>
              <FormikMoneyInput
                allowNegative={false}
                formik={formik}
                field="monthly_amount"
                onChange={handleInvestmentPlanChange}
              />
              {!monthlyValid() && (
                <small className="p-0 pt-1 text-secondary text-danger">
                  <Money
                    variant="text-danger"
                    value={{
                      amount: minimumMonthlyInvestment,
                      currency: Currency.EUR,
                    }}
                  />{" "}
                  {t("dashboard:validateDeposit")}
                </small>
              )}

              {monthlyValid() && <span>&nbsp;</span>}
            </div>
            <div>
              <h4 className="ml-md-3">{t("likeToDepositSuffix")}</h4>
              &nbsp;
            </div>
          </Form.Group>

          {formik.values.day_of_month && (
            <div className="next-monthly mt-0">
              {t("firstMonthly")}
              <strong className="text-secondary">
                <NextInvestmentDate
                  investmentDay={formik.values.day_of_month}
                  initialPeriod={{
                    weeks: 2,
                  }}
                />
              </strong>
              <small className="text-gray d-block mt-1 p-0">
                {t("firstMonthlySub")}
              </small>
            </div>
          )}

          {!!formik.values.initial_investment && (
            <>
              <h5>{t("howLong")}</h5>
              <p>{t("howLongNote")}</p>
              <div className="d-flex flex-column flex-md-row justify-content-md-between mt-4">
                <FormikRadioCard
                  field="horizon"
                  formik={formik}
                  value="SHORT_TERM"
                  checked={formik.values.horizon === "SHORT_TERM"}
                  id="3yrs"
                >
                  <Card.Title className="mb-0">
                    {horizon[obStore.product].SHORT_TERM}+ {t("year")}
                  </Card.Title>
                </FormikRadioCard>
                <FormikRadioCard
                  field="horizon"
                  formik={formik}
                  value="MID_TERM"
                  checked={formik.values.horizon === "MID_TERM"}
                  id="5yrs"
                >
                  <Card.Title className="mb-0">
                    {horizon[obStore.product].MID_TERM}+ {t("year")}
                  </Card.Title>
                </FormikRadioCard>
                <FormikRadioCard
                  field="horizon"
                  formik={formik}
                  value="LONG_TERM"
                  checked={formik.values.horizon === "LONG_TERM"}
                  id="10yrs"
                >
                  <Card.Title className="mb-0">
                    {horizon[obStore.product].LONG_TERM}+ {t("year")}
                  </Card.Title>
                </FormikRadioCard>
              </div>

              {estimatedReturns && formik.values.horizon && (
                <>
                  <p className="mt-4">{t("estEff")}</p>
                  <InfoCard variant="mt-2">
                    <Card.Body>
                      <Card.Title>{t("totalInv")}</Card.Title>
                      <Card.Text>
                        <Money
                          size="lg"
                          variant="small-decimals"
                          value={
                            estimatedReturns.getEstimatedReturns.total_invested
                          }
                        />
                      </Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Title>{t("futBalance")}</Card.Title>
                      <Card.Text>
                        <Money
                          size="lg"
                          variant="small-decimals"
                          value={
                            estimatedReturns.getEstimatedReturns.future_balance
                          }
                        />
                      </Card.Text>
                    </Card.Body>
                    <Card.Body>
                      <Card.Title>{t("profit")}</Card.Title>
                      <Card.Text>
                        <Money
                          size="lg"
                          variant="small-decimals"
                          value={{
                            currency: "EUR",
                            amount:
                              estimatedReturns.getEstimatedReturns
                                .future_balance.amount /
                                100 -
                              estimatedReturns.getEstimatedReturns
                                .total_invested.amount /
                                100,
                          }}
                        />
                      </Card.Text>
                    </Card.Body>
                  </InfoCard>
                  <small className="p-1 p-md-0 mt-2 d-block text-gray">
                    {t("pleaseNote")}
                  </small>
                </>
              )}
            </>
          )}
        </Form>
      </StepContent>

      <StepButtons onPrev={() => obStore.back()} onNext={handleNext} />

      <ContentLoader loading={loadingReturns} isInline={false} />
      <ContentLoader loading={loading} isInline={false} />
    </>
  );
};

export default InvestmentAmountPathOne;
