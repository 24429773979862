import { useTranslation } from "react-i18next";

const useFormatCurrency = () => {
  const { i18n } = useTranslation();

  const formatter = new Intl.NumberFormat(
    i18n.language === "en" ? "default" : "de-DE",
    {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }
  );

  const formatPercent = (num) => {
    return formatter.format(num);
  };

  return formatPercent;
};

export default useFormatCurrency;
