import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import * as moment from "moment";

import Money from "../../../components/Common/Money";
import ContentLoader from "../../../components/Common/ContentLoader";
import { useBOfficeGet } from "../BackofficeHooks";

import TypeFilter from "./TypeFilter";
import DateFilter from "./DateFilter";

const CashMovement = () => {
  const [data, setData] = useState([]);
  const [typeFilter, setTypeFilter] = useState("All");

  const [backOfficeFetch, loading] = useBOfficeGet();

  const columns = [
    {
      name: "ID",
      selector: "clientId",
      cell: (row) => <small>{row.clientId}</small>,
    },
    {
      name: "Created On",
      selector: "createdOn",
      wrap: true,
      sortable: true,
      format: (row) => moment(row.createdOn).format("DD.MM.YYYY"),
    },
    {
      name: "First Name",
      selector: "firstName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      wrap: true,
      sortable: true,
      format: (row) => <Money value={row.amount} />,
      sortFunction: (a, b) => {
        return a.amount.amount - b.amount.amount;
      },
    },
    {
      name: "Type",
      selector: "type",
      wrap: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      wrap: true,
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      wrap: true,
      sortable: true,
    },
  ];

  const initialDateFrom = useMemo(() => {
    return moment().subtract(30, "days").format("YYYY-MM-DD");
  }, []);

  const initialDateTo = useMemo(() => {
    return moment().format("YYYY-MM-DD");
  }, []);

  const fetchCashMovement = async (dateFrom, dateTo) => {
    const dateFromLocal = dateFrom ? dateFrom : initialDateFrom;
    const dateToLocal = dateTo ? dateTo : initialDateTo;

    const result = await backOfficeFetch(
      `/backoffice/cash_movement?from=${dateFromLocal}&to=${dateToLocal}`
    );

    if (!result) return;

    setTypeFilter("All");
    setData(result.data);
  };

  const handleRowClick = (row) => {
    window.open(`/backoffice/client/${row.clientId}`, "_blank");
  };

  const flitteredData = useMemo(() => {
    if (typeFilter === "All") {
      return data;
    }

    return data.filter((row) => row.type === typeFilter);
  }, [data, typeFilter]);

  useEffect(() => {
    fetchCashMovement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentLoader loading={loading}>
        <DateFilter
          initialDateFrom={initialDateFrom}
          initialDateTo={initialDateTo}
          onFilter={fetchCashMovement}
        />
        <TypeFilter
          data={data}
          typeFilter={typeFilter}
          setTypeFilter={setTypeFilter}
        />
        <DataTable
          striped
          highlightOnHover
          pointerOnHover
          defaultSortField="createdOn"
          defaultSortAsc={false}
          title="Cash Movement"
          columns={columns}
          data={flitteredData}
          onRowClicked={handleRowClick}
          responsive
        />
      </ContentLoader>
    </>
  );
};

export default CashMovement;
