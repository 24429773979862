import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Card, Table } from "react-bootstrap";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import FormikRadioCard from "../../Common/FormikRadioCard";
import AccordionHeader from "../../Common/AccordionHeader";
import SageModal from "../../Common/SageModal";
import { holdings, Product, MixpanelEvents } from "../../../common/common";
import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import { LanguageStoreContext } from "../../Common/LanguageStore";
import { OnboardingStoreContext } from "../OnboardingStore";

import StepButtons from "./StepButtons";
import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import Step from "./Step";

const UnderstandingYourInvestment = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const langStore = useContext(LanguageStoreContext);
  const [activeKey, setActiveKey] = useState("0");
  const [show, setShow] = useState(false);
  const [sGet] = useSessionStorage("ob");
  const [introUrl, setIntroUrl] = useState("");

  useEffect(() => {
    switch (langStore.lang) {
      case "de":
        setIntroUrl(
          "https://medium.com/sagefund/wichtige-investitionsbedingungen-und-risiken-1b7b6bc8c573"
        );
        break;
      default:
        setIntroUrl(
          "https://medium.com/sagefund/key-investment-terms-risks-777fb1298a35"
        );
    }
  }, [langStore.lang]);

  const handlePrev = () => {
    obStore.back();
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.terms_accepted) errors.terms_accepted = true;

    return errors;
  };

  const onSubmit = () => {
    mixpanel.track(
      MixpanelEvents.onboarding.UNDERSTANDING_YOUR_INVESTMENT_COMPLETED
    );
    obStore.next();
  };

  const formik = useFormik({
    initialValues: {
      terms_accepted: cachedVal(sGet, null, "terms_accepted") === "yes",
    },
    validate,
    onSubmit,
  });

  const handleChange = () => {
    formik.setFieldValue("terms_accepted", !formik.values.terms_accepted);
  };

  return (
    <Step variant="extra padding-bottom">
      <StepHeader title={t(`obdNav:${step.title}`)}>
        {t("undInvSub")}
      </StepHeader>
      <StepContent>
        <Accordion defaultActiveKey="0">
          <div className="mt-5">
            <AccordionHeader
              activeKey={activeKey}
              onSelect={setActiveKey}
              eventKey="0"
            >
              {t(
                obStore.product === Product.GROWTH
                  ? "labWhatIsSfG"
                  : "labWhatIsSfS"
              )}
            </AccordionHeader>
            <Accordion.Collapse eventKey="0">
              <p className="p-3">
                {t(
                  obStore.product === Product.GROWTH
                    ? "sfStabGlobGrowth"
                    : "sfStabGlob"
                )}
                <br />
                <br />
                <span className="acc-btn-cont d-flex align-items-center">
                  <span className="text-gray">{t("seeAllPort")}&nbsp;</span>
                  <Button
                    className="inline text-primary text-decoration-none"
                    variant="transparent"
                    onClick={() => setShow(true)}
                  >
                    {t("seeAllPortBtn")}
                  </Button>
                </span>
              </p>
            </Accordion.Collapse>
          </div>
          <div>
            <AccordionHeader
              activeKey={activeKey}
              onSelect={setActiveKey}
              eventKey="1"
            >
              <span className="title">{t("labWhoIsFor")}</span>
            </AccordionHeader>
            <Accordion.Collapse eventKey="1">
              <p className="p-3">
                {t(
                  obStore.product === Product.GROWTH
                    ? "thisInvStratGrowth"
                    : "thisInvStrat"
                )}
                <br />
                <br />
                <span className="acc-btn-cont d-flex align-items-center">
                  <span className="text-gray">
                    {t("thisInvStratSub")}&nbsp;
                    <Button
                      href={introUrl}
                      target="_blank"
                      className="inline text-primary text-decoration-none"
                      variant="transparent"
                    >
                      {t("thisInvStratSubBtn")}
                    </Button>
                  </span>
                </span>
              </p>
            </Accordion.Collapse>
          </div>
          <div>
            <AccordionHeader
              activeKey={activeKey}
              onSelect={setActiveKey}
              eventKey="2"
            >
              <span className="title">{t("labWhatAreRisks")}</span>
            </AccordionHeader>
            <Accordion.Collapse eventKey="2">
              <p
                className="p-3"
                dangerouslySetInnerHTML={{
                  __html: t(
                    obStore.product === Product.GROWTH
                      ? "theValGrowth"
                      : "theVal"
                  ),
                }}
              ></p>
            </Accordion.Collapse>
          </div>
        </Accordion>
        <div className="d-block d-md-none">
          <h5>{t("pleaseRead")}</h5>
          <FormikRadioCard
            field="terms_accepted"
            formik={formik}
            value="yes"
            checked={formik.values.terms_accepted}
            id="10yrs"
            type="checkbox"
            onChange={handleChange}
          >
            <Card.Text>{t("iConfirm")}</Card.Text>
          </FormikRadioCard>
        </div>
        <StepButtons
          onPrev={handlePrev}
          onNext={handleNext}
          canContinue={formik.isValid}
        >
          <div className="mb-2 mb-lg-4 d-none d-md-block">
            <h5>{t("pleaseRead")}</h5>
            <FormikRadioCard
              field="terms_accepted"
              formik={formik}
              value="yes"
              checked={formik.values.terms_accepted}
              id="10yrs"
              type="checkbox"
              onChange={handleChange}
            >
              <Card.Text>
                {t("iConfirm").replace("%p", obStore.product.toLowerCase())}
              </Card.Text>
            </FormikRadioCard>
          </div>
        </StepButtons>
      </StepContent>
      <HoldingsInDetail
        product={obStore.product}
        show={show}
        onClose={() => setShow(false)}
      />
    </Step>
  );
};

const HoldingsInDetail = ({ show, onClose, product }) => {
  const { t } = useTranslation(["steps", "common"]);

  return (
    <SageModal
      show={show}
      title={t(
        product === Product.GROWTH ? "undInvDetailGrowth" : "undInvDetail"
      )}
      onHide={onClose}
      buttons={
        <>
          <Button size="lg" variant="danger" onClick={onClose}>
            {t("close")}
          </Button>
        </>
      }
      dialogClassName="modal-80h"
    >
      <div className="costs-table-wrap">
        <StepHeader
          title={t(
            product === Product.GROWTH ? "undInvDetailGrowth" : "undInvDetail"
          )}
        >
          <div className="w-75">{t("undInvDetailSub")}</div>
        </StepHeader>
        <Table hover striped className="flat mt-4 d-none d-md-block">
          <thead>
            <tr>
              <th>{t("type")}</th>
              <th>{t("fundName")}</th>
              <th>{t("allocation")}</th>
              <th>ISIN</th>
            </tr>
          </thead>
          <tbody>
            {holdings.map((h, i) => {
              return !!h.allocation[product] &&
                h.allocation[product] !== "0%" ? (
                <tr key={i}>
                  <td>{t(`common:${h.type}`)}</td>
                  <td>
                    {t(`common:${h.fundName}`)}
                    <span className="subtitle d-block mt-1">{h.fullName}</span>
                  </td>
                  <td>{h.allocation[product]}</td>
                  <td>{h.isin}</td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>

        <Table hover striped className="flat mt-4 d-block d-md-none">
          <tbody>
            {holdings.map((h, i) => {
              return !!h.allocation[product] &&
                h.allocation[product] !== "0%" ? (
                <tr key={i}>
                  <td>
                    <b className="text-accent">{t("type")}</b>
                    <div className="mb-3 mt-1">{t(`common:${h.type}`)}</div>

                    <b className="text-accent">{t("fundName")}</b>
                    <div className="mb-3 mt-1">
                      {t(`common:${h.fundName}`)}
                      <span className="subtitle d-block mt-1">
                        {h.fullName}
                      </span>
                    </div>

                    <b className="text-accent">{t("allocation")}</b>
                    <div className="mb-3 mt-1">{h.allocation[product]}</div>

                    <b className="text-accent">ISIN</b>
                    <div className="mt-1">{h.isin}</div>
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </Table>
      </div>
    </SageModal>
  );
};

export default UnderstandingYourInvestment;
