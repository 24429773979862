import { useState } from "react";
import NumberFormat from "react-number-format";

import SageModal from "./SageModal";

import "./CodeVerifyModal.scss";

const CodeVerifyModal = ({
  show,
  title,
  message,
  onConfirm,
  onCanceled,
  children,
}) => {
  const [val, setVal] = useState("");

  const handleFocus = () => setVal("");

  const handleChange = (values) => {
    setVal(values.value);

    if (values.value.length < 6) {
      return;
    }

    onConfirm(values.value);
  };

  return (
    <SageModal
      show={show}
      title={title}
      onHide={onCanceled}
      className="rounded"
    >
      <div className="verify-container d-flex flex-column align-items-center ml-auto mr-auto">
        <p className="text-center">{message}</p>

        <NumberFormat
          autoFocus
          format="### ###"
          mask="_"
          allowEmptyFormatting={true}
          className="text-center form-control lg verify-control no-validate text-lg mt-4"
          onValueChange={handleChange}
          onFocus={handleFocus}
          value={val}
        />

        {children}
      </div>
    </SageModal>
  );
};

export default CodeVerifyModal;
