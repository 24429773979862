import { useContext, useState } from "react";
import { Button, Col, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";

import depositImage from "../../../assets/img/dialog/deposit-monthly-icon.png";
import depositConfirmImage from "../../../assets/img/dialog/deposit-confirm-icon.png";
import depositConfirmDeactiveteImage from "../../../assets/img/dialog/deposit-monthly-deactivate-icon.png";
import MoneyInput from "../../Common/MoneyInput";
import SageModal from "../../Common/SageModal";
import YesNoModal from "../../Common/YesNoModal";
import { Currency } from "../../../common/money";
import { useSageMutation } from "../../Common/GqlHooks";
import { UPDATE_MONTHLY_PLAN } from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { NotificationStoreContext } from "../../Common/NotificationStore";
import Money from "../../Common/Money";
import DayOfMonth from "../../Common/DayOfMonth";
import { MixpanelEvents } from "../../../common/common";

import NextInvestmentDate from "./NextInvestmentDate";

const ModifyMonthlyDeposit = ({
  currentSavingsAmount = undefined,
  dayOfMonth = 1,
  show,
  setShow,
  active,
  onMonthlyInvestmentUpdated,
}) => {
  const currency = Currency.EUR;

  const notifications = useContext(NotificationStoreContext);
  const { t } = useTranslation("dashboard");
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDeactivateConfirm, setShowDeactivateConfirm] = useState(false);
  const [amount, setAmount] = useState();
  const [day, setDay] = useState(dayOfMonth);

  const [isValid, setIsValid] = useState(false);
  const minAmount = 25;

  const withdrawAmountChanged = (values) => {
    const { value } = values;

    if (value === "" || value < minAmount) {
      setAmount(undefined);
      setIsValid(false);
      return;
    }

    setIsValid(true);
    setAmount(value);
  };

  const handleDeposit = () => {
    setShow(false);
    setShowConfirm(true);
  };

  const handleDeactivate = () => {
    setShow(false);
    setShowDeactivateConfirm(true);
  };

  const handleBack = () => {
    setShow(true);
    setShowConfirm(false);
    setShowDeactivateConfirm(false);
  };

  const handleCancel = () => {
    setIsValid(false);
    setShow(false);
    setShowConfirm(false);
    setShowDeactivateConfirm(false);
    setAmount(undefined);
  };

  const [updateMonthlyPlan, loading] = useSageMutation(UPDATE_MONTHLY_PLAN);

  const handleConfirm = () => {
    updateMonthlyPlan({
      variables: {
        savings_plan: {
          amount: {
            unit: "CENT",
            currency: currency,
            amount: Math.round(amount * 100),
          },
          day_of_month: day,
        },
      },
    })
      .then(() => {
        handleCancel();
        onMonthlyInvestmentUpdated();
        notifications.addMessage(t("monthlyInvestmentUpdated"));

        if (currentSavingsAmount !== undefined) {
          let cSavings = currentSavingsAmount.amount / 100;

          if (amount > cSavings) {
            mixpanel.track(
              MixpanelEvents.dashboard.MONTHLY_SAVINGS_PLAN_INCREASED,
              {
                old_amount: cSavings,
                new_amount: parseInt(amount),
              }
            );
          }
          if (amount < cSavings) {
            mixpanel.track(
              MixpanelEvents.dashboard.MONTHLY_SAVINGS_PLAN_DECREASED,
              {
                old_amount: cSavings,
                new_amount: parseInt(amount),
              }
            );
          }
        } else {
          mixpanel.track(
            MixpanelEvents.dashboard.MONTHLY_SAVINGS_PLAN_ACTIVATED,
            {
              new_amount: parseInt(amount),
            }
          );
        }
      })
      .catch((e) => console.log(e));
  };

  const handleConfirmDeactivate = () => {
    updateMonthlyPlan({
      variables: null,
    })
      .then(() => {
        handleCancel();
        onMonthlyInvestmentUpdated();
        notifications.addMessage(t("monthlyInvestmentDeactivated"));
        mixpanel.track(
          MixpanelEvents.dashboard.MONTHLY_SAVINGS_PLAN_DEACTIVATED
        );
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <SageModal
        show={show}
        title={t("modifyMonthly")}
        onHide={handleCancel}
        image={depositImage}
        buttons={
          <>
            <Button
              disabled={!active}
              size="lg"
              variant="secondary"
              onClick={handleDeactivate}
            >
              {t("deactivate")}
            </Button>

            <Button
              size="lg"
              variant="primary"
              onClick={handleDeposit}
              disabled={!isValid}
            >
              {t("update")}
            </Button>
          </>
        }
      >
        <Form validated={false}>
          <Form.Row className="align-items-center">
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("wouldLikeDeposit")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
            <Col xs="auto">
              <MoneyInput
                currency={currency}
                value={amount}
                onValueChange={withdrawAmountChanged}
                decimalScale={0}
                allowNegative={false}
                className={isValid ? "narrow " : "narrow invalid"}
              />
              <small className="d-block p-0 pt-1 text-secondary text-center">
                <Money
                  variant="text-secondary"
                  value={{
                    amount: minAmount,
                    currency: Currency.EUR,
                  }}
                />{" "}
                {t("validateWithdraw")}
              </small>
            </Col>
          </Form.Row>
          <Form.Row className="align-items-center pt-3">
            <Col xs="auto">
              <h5 className="text-dark m-0">{t("everyMonth")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
            <Col xs="auto">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <DayOfMonth value={day} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onSelect={() => setDay(1)} active={day === 1}>
                    <DayOfMonth value={1} />
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => setDay(15)}
                    active={day === 15}
                  >
                    <DayOfMonth value={15} />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
            <Col>
              <h5 className="text-dark m-0">{t("everyMonthSuffix")}</h5>
              <small className="d-block p-0 pt-1">&nbsp;</small>
            </Col>
          </Form.Row>
        </Form>

        {active && (
          <>
            <p className="mt-4 mb-1">
              {t("currentlyDepositing")}
              <strong>
                {" "}
                <Money variant="text-dark" value={currentSavingsAmount} />{" "}
              </strong>
              {t("onThe")}
              <strong className="text-dark">
                {" "}
                <DayOfMonth value={day} />{" "}
              </strong>
              {t("eachMonth")}
            </p>
            <p>
              {t("nextInvestment")}{" "}
              <strong className="text-dark">
                {" "}
                <NextInvestmentDate investmentDay={day} />
              </strong>
            </p>
          </>
        )}
      </SageModal>

      <YesNoModal
        title={t("securityInfo")}
        show={showConfirm}
        image={depositConfirmImage}
        onCanceled={handleCancel}
        onBack={handleBack}
        onConfirm={handleConfirm}
      >
        <h5 className="text-dark">
          {t("youAreSet")}
          <span className="text-accent">
            {" "}
            {t("deposit")}{" "}
            <Money
              variant="text-accent"
              value={{
                amount: amount,
                currency: Currency.EUR,
              }}
            />{" "}
          </span>
          {t("everyMonth")} <DayOfMonth value={day} />
        </h5>
        <p>{t("monthlyNote")}</p>
      </YesNoModal>

      <YesNoModal
        title={t("securityInfo")}
        show={showDeactivateConfirm}
        image={depositConfirmDeactiveteImage}
        onCanceled={handleCancel}
        onBack={handleBack}
        onConfirm={handleConfirmDeactivate}
      >
        <h5 className="text-dark">{t("deactivateConfirmTitle")}</h5>

        <p>{t("deactivateConfirmFirstNote")}</p>
        <p>{t("deactivateConfirmSecondNote")}</p>
      </YesNoModal>

      <ContentLoader loading={loading} isInline={false} />
    </>
  );
};

export default ModifyMonthlyDeposit;
