/* eslint-disable */

export default {
  notice: "Notiz",
  dashboard: "Dashboard",
  beingOnboarded: "Deine Angaben werden noch bearbeitet",
  overview: "Überblick",
  investmentValue: "Anlagewert",
  pending: "Ausstehend",
  totalEarnings: "Gesamtertrag",
  startSavingMonthly: "Sparplan anlegen",
  monthlyDeposit: "Monatliche Sparrate",
  every: "Am",
  ofTheMonth: "jeden Monats",
  modify: "Bearbeiten",
  depositFunds: "Einzahlung",
  withdrawFunds: "Auszahlung",
  portfolioPerformance: "Profitentwicklung",
  growth: "Profit der letzten",
  last: "letzten",
  _30Days: "30 Tage",
  _6Months: "6 Monate",
  _12Months: "12 Monate",
  _SinceBuy: "Seit Kauf",
  withdraw: "Auszahlung",
  invested: "investiert",
  pastPerformance: "Historische Wertentwicklung",
  yourProfits: "Dein Gewinn",
  profitsSince: "Gewinn seit",
  hereInvested: "Seit der Investition",

  /* Deposit dialog Start */
  validateDeposit: "Mindestbetrag",
  newDeposit: "Anlegen",
  toDeposit: "Ich würde gerne weitere",
  toDepositSuffix: "investieren",
  noteConfirm: "Das Geld wird von deinem Referenzkonto abgehoben:",
  fundsArrive:
    "Das Geld wird innerhalb von 5 Arbeitstagen auf Ihrem SageWealth-Konto eingehen",
  /* Deposit dialog End */

  /* Deposit Confirm dialog Start */
  securityInfo: "Sicherheitsbestätigung",
  youAreSet: "Du möchtest",
  youAreSetToWith: "Du möchtest",
  inYourInvestment: "in SageWealth {{product_type}} investieren",
  deposit: "einzahlen",
  back: "Zurück",
  confirm: "Bestätigen.",
  depositConfirmFirstNote:
    "Hinweis: Durch die Bestätigung wird ein SEPA-Lastschrifteinzug initiiert.",
  depositConfirmSecondNote: "Dies kann bis zu 5 Arbeitstage dauern.",
  /* Deposit Confirm dialog End */

  /* Monthly Deposit dialog Start */
  modifyMonthly: "Sparplan",
  everyMonthSuffix: "eines jeden Monats.",
  everyMonth: "am",
  wouldLikeDeposit: "Ich würde gerne",
  onThe: "am",
  eachMonth: "eines jeden Monats",
  currentlyDepositing: "Du investierst derzeit",
  deactivate: "Deaktivieren",
  update: "Aktualisieren",
  validDepositAmount: "Mindestbetrag",
  nextInvestment: "Die nächste Investition erfolgt am",
  monthlyNote:
    "Hinweis: Durch die Bestätigung wird ein Dauerauftrag für ein SEPA-Lastschriftmandat erteilt.",
  deactivateConfirmTitle: "Du willst deinen monatlichen Sparplan deaktivieren",
  deactivateConfirmFirstNote:
    "Hinweis: Durch die Bestätigung wird dein laufender SEPA-Lastschrifteinzug aufgehoben.",
  deactivateConfirmSecondNote: "Änderungen erfolgen innerhalb von 24 Stunden.",
  /* Monthly Deposit dialog End */

  /* Withdraw Deposit dialog Start */
  withdrawFrom: "Abheben",
  wouldWithdraw: "Du möchtest",
  fromSagefund: "abheben",
  currentBalance: "Du hast derzeit",
  balanceTransferred: "Das Geld wird auf dein Referenzkonto überwiesen:",
  iban: "IBAN",
  validateWithdraw: "Mindestbetrag",
  /* Withdraw Deposit dialog End */

  /* Withdraw Confirm Deposit dialog Start */
  withdrawConfirmFirstNote:
    "Hinweis: Durch die Bestätigung wird eine Auszahlung initiiert.",
  withdrawConfirmSecondNote: "Dies kann bis zu 5 Arbeitstage dauern.",
  /* Withdraw Confirm Deposit dialog End */

  // Error messages
  error: "Fehler!",
  success: "Geschafft!",

  incorrectCredentials: "E-Mail oder Passwort ist nicht gültig.",

  // Success messages
  depositInitiated: "Neue Investition getätig",
  withdrawalInitiated: "Neue Entnahme getätigt",
  monthlyInvestmentDeactivated: "Sparlan wurde deaktiviert",
  monthlyInvestmentUpdated: "Sparplan wurde aktualisiert",

  // Transactions
  txREQUESTED: "ANGEFORDERT",
  txPENDING: "AUSSTEHEND",
  txFAILED: "fehlgeschlagen",
  txPROCESSING: "in Bearbeitung",
  txSUCCESSFUL: "erfolgreich",
};
