import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { NotificationStoreContext } from "./NotificationStore";
import NotificationToast from "./NotificationToast";

const NotificationToasts = () => {
  const { t } = useTranslation("dashboard");
  const notifications = useContext(NotificationStoreContext);

  return (
    <div
      style={{
        position: "fixed",
        top: 110,
        right: 0,
        zIndex: 1070,
      }}
    >
      {notifications.errors.map((e, i) => (
        <NotificationToast
          title={t("error")}
          key={i}
          error
          handleHide={() => notifications.clearErrors()}
        >
          {t(e) !== "" ? t(e) : e}
        </NotificationToast>
      ))}
      {notifications.messages.map((m, i) => (
        <NotificationToast
          title={t("success")}
          key={i}
          handleHide={() => notifications.clearMessages()}
        >
          {m}
        </NotificationToast>
      ))}
      {notifications.info.map((m, i) => (
        <NotificationToast
          title={t("notice")}
          key={i}
          info
          handleHide={() => notifications.clearInfo()}
        >
          {m}
        </NotificationToast>
      ))}
    </div>
  );
};

export default NotificationToasts;
