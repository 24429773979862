// eslint-disable-next-line import/no-anonymous-default-export
export default {
  verificationSuccess:
    "E-Mail-Verifizierung erfolgreich - Bitte meld dich aus Sicherheitsgründen erneut an, da du wohl das Gerät gewechselt hast",
  inactivityNotice: "Du wurdest aufgrund von Inaktivität abgemeldet.",
  email: "E-Mail",
  password: "Passwort",
  validEmail: "Die Email-Adresse muss gültig sein",
  validPassword:
    "Das Passwort muss eine Kombination aus Kleinbuchstaben, Großbuchstaben, Ziffern, Sonderzeichen und mindestens 8 Zeichen bestehen",
  login: "Anmelden",
  register: "Registrieren",
  betaNoticeTitle: "SageWealth befindet sich im Beta-Stadium",
  betaNotice:
    "Dies bedeutet, dass alle unsere Kernfunktionen und unser Investment-System von unseren Bankpartnern getestet und genehmigt wurden. Dennoch kann es sein, dass du noch einige Design-Fehler findest. Wenn du welche findest, melde sie doch einfach unten im",
};
