/* eslint-disable */

export default {
  notice: "Notice",
  dashboard: "Dashboard",
  beingOnboarded: "You are still being onboarded",
  overview: "Overview",
  investmentValue: "Investment Value",
  pending: "Pending",
  totalEarnings: "Total Earnings",
  startSavingMonthly: "Start monthly savings",
  monthlyDeposit: "Monthly Deposit",
  every: "Every",
  ofTheMonth: "of the Month",
  modify: "Modify",
  depositFunds: "Deposit Funds",
  withdrawFunds: "Withdraw Funds",
  portfolioPerformance: "Profit Performance",
  growth: "Earnings last",
  last: "Last",
  _30Days: "30 Days",
  _6Months: "6 Months",
  _12Months: "12 Months",
  _SinceBuy: "Since Buy",
  withdraw: "withdraw",
  invested: "invested",
  pastPerformance: "Past performance",
  yourProfits: "Your profits",
  profitsSince: "Profits since",
  hereInvested: "Since investing",

  /* Deposit dialog Start */
  validateDeposit: "minimum",
  newDeposit: "Invest",
  toDeposit: "I would like to invest an additional",
  toDepositSuffix: "",
  noteConfirm:
    "Funds will be transferred from your associated reference bank account:",
  fundsArrive:
    "Funds will arrive on your SageWealth account within 5 working days.",
  /* Deposit dialog End */

  /* Deposit Confirm dialog Start */
  securityInfo: "Security confirmation",
  youAreSet: "You are set to invest",
  youAreSetToWith: "You are set to",
  deposit: "invest",
  inYourInvestment: "in SageWealth {{product_type}}",
  back: "Back",
  confirm: "Confirm",
  depositConfirmFirstNote:
    "Note: Confirming this will initiate a SEPA direct debit transfer.",
  depositConfirmSecondNote: "This can take up to 5 working days.",

  /* Deposit Confirm dialog End */

  /* Monthly Deposit dialog Start */
  modifyMonthly: "Monthly deposit",
  everyMonthSuffix: "",
  everyMonth: "every month, on the",
  wouldLikeDeposit: "I would like to invest",
  currentlyDepositing: "You are currently depositing",
  onThe: "on the",
  eachMonth: "of each month.",
  deactivate: "Deactivate",
  update: "Update",
  validDepositAmount: "minimum",
  nextInvestment: "Next investment would take place on",
  monthlyNote:
    "Note: Confirming this will create a recurring SEPA direct debit mandate. ",
  deactivateConfirmTitle: "You are set to deactivate your monthly deposits",
  deactivateConfirmFirstNote:
    "Note: Confirming this will cancel your recurring SEPA direct debit mandate.",
  deactivateConfirmSecondNote: "Changes will take place within 24 hours.",

  /* Monthly Deposit dialog End */

  /* Withdraw Deposit dialog Start */
  withdrawFrom: "Withdrawal",
  wouldWithdraw: "I would like to withdraw",
  fromSagefund: "",
  currentBalance: "You currently have",
  balanceTransferred:
    "Funds will be transferred to your associated reference bank account:",
  iban: "IBAN",
  validateWithdraw: "minimum",
  /* Withdraw Deposit dialog End */

  /* Withdraw Confirm Deposit dialog Start */
  withdrawConfirmFirstNote:
    "Note: Confirming this will initiate the withdrawal.",
  withdrawConfirmSecondNote: "This can take up to 5 working days.",
  /* Withdraw Confirm Deposit dialog End */

  // Error messages
  error: "Error!",
  success: "Success!",

  incorrectCredentials: "Email or password is not correct.",

  // Success messages
  depositInitiated: "New investment initiated",
  withdrawalInitiated: "New withdrawal initiated",
  monthlyInvestmentDeactivated: "Monthly deposit plan deactivated",
  monthlyInvestmentUpdated: "Monthly deposit plan updated",

  // Transactions
  txREQUESTED: "REQUESTED",
  txPENDING: "PENDING",
  txFAILED: "failed",
  txPROCESSING: "processing",
  txSUCCESSFUL: "successful",
};
