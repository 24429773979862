import React, { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import mixpanel from "mixpanel-browser";

import { OnboardingStoreContext } from "../OnboardingStore";
import { MixpanelEvents } from "../../../common/common";

import NotVerifiedNotice from "./NotVerifiedNotice";
import OnboardingComplete from "./OnboardingComplete";

const Steps = ({ children }) => {
  const state = useContext(OnboardingStoreContext);

  const [kycDone, setKycDone] = useState(false);
  const [kycStatus, setKycStatus] = useState("");

  useEffect(() => {
    if (!state.me.client.identity_verification) return;

    const setStatus = (status) => {
      mixpanel.track(MixpanelEvents.onboarding.IDENTITY_VERIFICATION_CHECKING);
      state.setCurrentStep(99);
      setKycDone(true);
      setKycStatus(status);
    };

    switch (state.me.client.identity_verification.status) {
      case "VERIFIED":
        setStatus("VERIFIED");
        break;
      case "FAILED":
        setStatus("FAILED");
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const KycDone = () => {
    return (
      kycDone &&
      kycStatus === "VERIFIED" && (
        <Col className="h-100 p-0" lg={12}>
          <OnboardingComplete />
        </Col>
      )
    );
  };

  return (
    <div className="h-100 ob-content pt-3 pb-2">
      {state.me?.is_email_verified &&
        (!kycDone || (kycDone && kycStatus === "FAILED")) && (
          <div className="h-100 p-0 ml-0 ml-lg-4">
            {React.Children.toArray(children).map((v, i) => {
              if (i + 1 !== state.currentStep) return null;

              return React.cloneElement(v, {
                step: state.steps
                  .flatMap((x) => x.steps)
                  .find((x) => x.id === state.currentStep),
              });
            })}
          </div>
        )}

      {!state.me?.is_email_verified && (
        <Col className="h-100 p-0" lg={12}>
          <NotVerifiedNotice />
        </Col>
      )}
      <KycDone />
    </div>
  );
};

export default Steps;
