import React, { useState, useEffect } from "react";
import i18n from "i18next";

const defaultLang = "en";

export const LanguageStoreContext = React.createContext({});

const LanguageStore = ({ children }) => {
  const [state, setState] = useState({
    lang: defaultLang,
  });

  useEffect(() => {
    const searchQuery = new URLSearchParams(window.location.search);
    const queryLang = searchQuery.get("ref");

    if (queryLang === "nadineprimo") {
      localStorage.setItem("user-lang", "de");
    }

    let lng = localStorage.getItem("user-lang");

    switch (lng) {
      case null:
        lng = "en";
        break;
      case "en-us":
        lng = "en";
        break;
      default:
        break;
    }

    change(lng);
  }, []);

  const change = (lang) => {
    setState((s) => ({
      ...s,
      lang: lang,
    }));

    i18n.changeLanguage(lang);
    localStorage.setItem("user-lang", lang);
  };

  return (
    <LanguageStoreContext.Provider
      value={{
        ...state,
        change,
      }}
    >
      {children}
    </LanguageStoreContext.Provider>
  );
};

export default LanguageStore;
