export const validatePassword = (pass) => {
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9 ])(?=.{8,})"
  );

  return regex.test(pass);
};

export const validateEmail = (email) => {
  const regex = /^\w+([+.-]?\w+)*@\w+([+.-]?\w+)*(\.\w+)+$/;

  return regex.exec(email);
};
