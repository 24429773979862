import { Figure, Modal } from "react-bootstrap";

const SageModal = (props) => {
  return (
    <Modal
      keyboard={false}
      animation={false}
      centered
      backdrop="static"
      size="lg"
      {...props}
    >
      <Modal.Header className="d-flex align-items-center" closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          {props.image && (
            <Figure
              className="mt-auto mb-auto pr-4 mr-4 d-none d-sm-none d-md-block"
              style={{
                minWidth: "150px",
                maxWidth: "150px",
              }}
            >
              <Figure.Image src={props.image} />
            </Figure>
          )}

          <div className="p-1 mt-auto mb-auto">{props.children}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>{props.buttons}</Modal.Footer>
    </Modal>
  );
};

export default SageModal;
