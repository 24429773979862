/* eslint-disable */

export default {
  createAccount: "Konto erstellen",
  setupAccount: "Eröffne dein SageWealth-Konto in 15 Minuten",
  confirmPassword: "Password bestätigen",
  submit: "Registrieren",
  validSamePasswords: "Passwörter müssen identisch sein",
  minLength: "Enthält mindestens 8 Zeichen",
  characters: "Enthält Groß- und Kleinbuchstaben",
  numbers: "Enthält eine Ziffer (0-9)",
  specialCharacters: "Enthält Sonderzeichen. Zum Beispiel: .+!@#$%()_=^&*",
};
