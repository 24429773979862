import { Col, Container, Row, Nav } from "react-bootstrap";
import "./Settings.scss";
import { Redirect, Route, Switch } from "react-router";
import { useTranslation } from "react-i18next";
import { BrowserRouter, NavLink } from "react-router-dom";

import MyInformation from "./MyInformation";
import MyDocuments from "./MyDocuments";
import MyTransactions from "./MyTransactions";

const DashboardSettings = () => {
  const { t } = useTranslation(["settings", "dashboardLayout"]);

  return (
    <Container className="settings-layout">
      <Row>
        <Col xs={12} lg={8} className="mr-auto ml-auto">
          <h2 className="my-4">{t("settings")}</h2>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Nav
              variant="tabs"
              defaultActiveKey="/dashboard/settings/profile"
              className="flex-row mb-3"
            >
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/dashboard/settings/profile"
                exact
              >
                {t("myInfoTab")}
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/dashboard/settings/transactions"
                exact={true}
              >
                {t("transactionsTab")}
              </Nav.Link>
              <Nav.Link
                activeClassName="active"
                as={NavLink}
                to="/dashboard/settings/documents"
                exact={true}
              >
                {t("documentsTab")}
              </Nav.Link>
            </Nav>

            <Switch>
              <Route exact path="/dashboard/settings/profile">
                <MyInformation />
              </Route>
              <Route exact path="/dashboard/settings/transactions">
                <MyTransactions />
              </Route>
              <Route exact path="/dashboard/settings/documents">
                <MyDocuments />
              </Route>
              <Redirect
                from="/dashboard/settings"
                to="/dashboard/settings/profile"
              />
            </Switch>
          </BrowserRouter>
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardSettings;
