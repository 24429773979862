import { Container, Spinner } from "react-bootstrap";
import "./ContentLoader.scss";

const ContentLoader = ({ children, loading, size = 1, isInline = true }) => {
  const inlineLoader = (
    <>
      {loading && <Loader count={size} />}

      {!loading && children}
    </>
  );

  const overlayLoader = (
    <>
      {loading && <OverlayLoader />}

      {!loading && children}
    </>
  );

  return isInline ? inlineLoader : overlayLoader;
};

const Loader = ({ count }) => {
  const elems = [];

  for (let i = 0; i < count; i++) {
    elems.push(
      <div key={i} className="cl-wrapper-cell d-flex">
        <div className="image"></div>
        <div className="text w-100">
          <div className="text-line w-100"> </div>
          <div className="text-line w-75"></div>
          <div className="text-line"></div>
          <div className="text-line w-50"></div>
        </div>
      </div>
    );
  }

  return elems;
};

const OverlayLoader = () => {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center position-fixed"
      style={{
        zIndex: 1060,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "rgba(246, 246, 246, 0.7)",
      }}
    >
      <Spinner animation="border" size="xl" variant="dark" />
    </Container>
  );
};

export default ContentLoader;
