import { useState } from "react";
import { Toast } from "react-bootstrap";

const NotificationToast = ({
  children,
  title,
  error = false,
  info = false,
  handleHide,
}) => {
  const [show, setShow] = useState(true);

  let icon = error ? (
    <i className="fa fa-exclamation-circle pr-4 text-danger"></i>
  ) : (
    <i className="fa fa-check-circle pr-4 text-success"></i>
  );

  if (info) {
    icon = <i className="fa fa-info pr-4 text-info"></i>;
  }

  const handleClose = () => {
    setShow(false);
    handleHide(children);
  };

  return (
    <Toast
      className="err-toast"
      autohide
      show={show}
      delay={10000}
      onClose={handleClose}
    >
      <Toast.Body className="d-flex p-4 align-items-center">
        {icon}
        <span className="pr-5">
          {title}
          <br />
          <small>{children}</small>
        </span>
        <i className="fa fa-times ml-auto close" onClick={handleClose}></i>
      </Toast.Body>
    </Toast>
  );
};

export default NotificationToast;
