import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import {
  ACCOUNT_TOTAL_EARNINGS,
  GET_HISTORY_PERFORMANCE,
} from "../../../api/graphql";
import { GraphStep } from "../../../common/graph-step";
import useFormatCurrency from "../../../hooks/useFormatCurrency";
import useFormatPercent from "../../../hooks/useFormatPercent";
import ContentLoader from "../../Common/ContentLoader";
import { useSageLazyQuery } from "../../Common/GqlHooks";
import arrowUp from "../../../assets/img/charts/arrow-up.svg";
import arrowDown from "../../../assets/img/charts/arrow-down.svg";

import "./GrowthGraph.scss";

const PerformanceType = { history: "history", personal: "personal" };

const Graph = () => {
  const { t } = useTranslation("dashboard");
  const [type, setType] = useState(PerformanceType.history);
  const graphOverlay = useRef();
  const chartContainer = useRef();

  const animationSpeed = 250;

  const formatPercent = useFormatPercent();
  const formatCurrency = useFormatCurrency();

  const [getHistoryPerformance, historyLoading, historyPerformance] =
    useSageLazyQuery(GET_HISTORY_PERFORMANCE);

  const [getPersonalPerformance, personalLoading, personalPerformance] =
    useSageLazyQuery(ACCOUNT_TOTAL_EARNINGS);

  useEffect(() => {
    getHistoryPerformance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (historyPerformance && investmentStartDate) {
      let step;
      const from = moment(investmentStartDate * 1000);
      const today = moment();

      const differenceInDays = today.diff(from, "days");

      if (differenceInDays <= 30) {
        step = GraphStep.DAY;
      }

      if (differenceInDays <= 120) {
        step = GraphStep.FORTNIGHT;
      }

      if (differenceInDays <= 210) {
        step = GraphStep.WEEK;
      }

      if (differenceInDays >= 210) {
        step = GraphStep.MONTH;
      }

      getPersonalPerformance({
        variables: {
          step: step,
          from: from.format("YYYY-MM-DD"),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyPerformance]);

  const dateFromSecToMillisec = (date) => {
    return date * 1000;
  };

  function calculateDistanceToGraphAnnotation() {
    // get the offset of the chart drawing area
    // this is essentially the width of the y-axis + padding
    // apex charts does this for us so we just parse out the translate-X value from the SVG attribute
    const yaxisWidth = parseInt(
      document
        .querySelector(".apexcharts-graphical")
        .getAttribute("transform")
        .match(/translate\((\d+).*\)/)[1]
    );

    // get the position where user invested
    // we use apex charts to position an annotation line here
    // and then get the x-coordinate
    const annotationsPositionX = parseInt(
      document
        .querySelector(".apexcharts-point-annotation-marker")
        .getAttribute("cx")
    );

    // update the correct position of our own investing range annotation
    // using the dimensions we got from apex
    return annotationsPositionX + yaxisWidth + "px";
  }

  const updatePerformanceType = (type) => {
    chartContainer.current.style.opacity = 0;

    setTimeout(() => {
      chartContainer.current.style.opacity = 1;
      setType(type);
    }, animationSpeed);
  };

  const loading =
    (historyLoading && personalLoading) ||
    !historyPerformance ||
    !personalPerformance;

  const isHistoryGraph = type === PerformanceType.history;

  const isPersonalGraph = type === PerformanceType.personal;

  const investmentStartDate =
    historyPerformance?.getMyAccountHistoricPerformance?.info
      ?.initial_investment_date;

  const personalInvestmentStartDate =
    personalPerformance?.getMyAccountTotalEarnings?.dates?.[0];

  const personalInvestmentStartDateValue =
    personalPerformance?.getMyAccountTotalEarnings?.values?.[0];

  const historySeries = useMemo(() => {
    return historyPerformance?.getMyAccountHistoricPerformance?.data?.map(
      (value) => {
        const seriesItem = [
          dateFromSecToMillisec(value.date),
          value.percentage_change,
        ];

        return seriesItem;
      }
    );
  }, [historyPerformance]);

  const personalStartDatePercentage = useMemo(() => {
    let returnValue = 0;
    const x = dateFromSecToMillisec(investmentStartDate);

    historySeries?.every((arrayItem, index) => {
      const dateIndex = 0;
      const percentageIndex = 1;

      if (arrayItem[dateIndex] < x) return true;

      const x1 = historySeries[index - 1][dateIndex];
      const x2 = historySeries[index][dateIndex];
      const y1 = historySeries[index - 1][percentageIndex];
      const y2 = historySeries[index][percentageIndex];

      // formula for leaner interpolation
      returnValue = y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);

      return false;
    });

    return returnValue;
  }, [historySeries, investmentStartDate]);

  const personalSeries = personalPerformance?.getMyAccountTotalEarnings?.values;

  const personalLabels = personalPerformance?.getMyAccountTotalEarnings?.dates;

  const historyAnnotation = {
    x: dateFromSecToMillisec(investmentStartDate),
    y: personalStartDatePercentage,
    marker: {
      size: 5,
      fillColor: "#F46561",
      strokeColor: "#fff",
    },
    borderColor: "transparent",
    label: {
      offsetY: 120,
      offsetX: -50,
      borderWidth: 0,
      text: t("hereInvested"),
      textAnchor: "end",
      style: {
        background: "transparent",
        color: "#777",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Poppins, sans-serif",
        cssClass: "apexcharts-xaxis-annotation-point-youinvestedhere",
      },
    },
    image: {
      path: arrowUp,
      width: 48,
      height: 100,
      offsetX: -24,
      offsetY: 55,
    },
  };

  const personalAnnotation = {
    x: new Date(personalInvestmentStartDate).getTime(),
    y: personalInvestmentStartDateValue,
    marker: {
      size: 5,
      fillColor: "#F46561",
      strokeColor: "#fff",
    },
    borderColor: "transparent",
    label: {
      offsetY: -110,
      offsetX: 0,
      borderWidth: 0,
      text: t("hereInvested"),
      textAnchor: "start",
      style: {
        background: "transparent",
        color: "#777",
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: "Poppins, sans-serif",
        cssClass: "apexcharts-xaxis-annotation-point-youinvestedhere",
      },
    },
    image: {
      path: arrowDown,
      width: 48,
      height: 100,
      offsetX: 0,
      offsetY: -60,
    },
  };

  const options = {
    chart: {
      background: "transparent",
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      events: {
        mounted: function () {
          graphOverlay.current.style.left =
            calculateDistanceToGraphAnnotation();
        },
        updated: function () {
          graphOverlay.current.style.left =
            calculateDistanceToGraphAnnotation();
        },
      },
    },
    labels: isPersonalGraph ? personalLabels : [],
    annotations: {
      points: [isHistoryGraph ? historyAnnotation : personalAnnotation],
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      colors: ["#29cc7b"],
      width: 4,
    },
    colors: ["#29cc7b"],
    markers: {
      size: isHistoryGraph ? 0 : 5,
    },
    title: {
      text: "",
      align: "left",
    },
    yaxis: {
      labels: {
        formatter: isHistoryGraph
          ? formatPercent
          : (value) => formatCurrency(value / 100),
        minWidth: 30,
        offsetX: -10,
      },
      show: true,
      showAlways: true,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      crosshairs: {
        show: false,
      },
      labels: {
        show: isHistoryGraph ? true : false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      enabled: isHistoryGraph ? false : true,
      x: {
        show: false,
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const label = w.config.labels[dataPointIndex];
        const value = series[seriesIndex][dataPointIndex];

        return (
          '<div class="chart-tooltip p-2 bg-transparent outline text-secondary">' +
          "<strong>" +
          moment(label).format("DD[.]MM[.]YYYY") +
          ":</strong> " +
          formatCurrency(value / 100) +
          "</div>"
        );
      },
    },
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          annotations: {
            points: [
              {
                x: isHistoryGraph
                  ? dateFromSecToMillisec(investmentStartDate)
                  : new Date(personalInvestmentStartDate).getTime(),
                y: isHistoryGraph
                  ? personalStartDatePercentage
                  : personalInvestmentStartDateValue,
                marker: {
                  size: 5,
                  fillColor: "#F46561",
                  strokeColor: "#fff",
                },
                borderColor: "transparent",
                label: {
                  borderWidth: 0,
                  text: t("hereInvested"),
                  textAnchor: isHistoryGraph ? "end" : "middle",
                  style: {
                    background: "#fff0",
                    color: "#777",
                    fontSize: "8px",
                    fontWeight: 700,
                    fontFamily: "Poppins, sans-serif",
                    cssClass:
                      "apexcharts-xaxis-annotation-point-youinvestedhere",
                  },
                },
              },
            ],
          },
          grid: {
            strokeDashArray: 0,
          },
          stroke: {
            show: true,
            width: 3,
            curve: "smooth",
            lineCap: "round",
          },
          markers: {
            size: 0,
          },
        },
      },
    ],
  };

  return (
    <Card className="growth-card">
      <Card.Body>
        <ContentLoader loading={loading} size={2}>
          <div style={{ paddingBottom: "0.5rem" }}>
            <div style={{ position: "relative" }}>
              <div ref={graphOverlay} id="date-range-invested"></div>
              {historyPerformance && personalPerformance && (
                <div ref={chartContainer} id="apexChartContainer">
                  <ReactApexChart
                    options={options}
                    series={[
                      {
                        data: isHistoryGraph ? historySeries : personalSeries,
                      },
                    ]}
                    type="line"
                  />
                </div>
              )}
            </div>
          </div>
        </ContentLoader>

        <div className="d-flex growth-footer">
          <Button
            onClick={() => updatePerformanceType(PerformanceType.history)}
            variant={isHistoryGraph ? "secondary" : "lighter"}
            active={isHistoryGraph}
            className="flat w-100"
            size="sm"
            disabled={!historyPerformance && !personalPerformance}
          >
            {t("pastPerformance")}
          </Button>

          <Button
            onClick={() => updatePerformanceType(PerformanceType.personal)}
            variant={isPersonalGraph ? "secondary" : "lighter"}
            active={isPersonalGraph}
            className="flat ml-3 w-100"
            size="sm"
            disabled={!historyPerformance && !personalPerformance}
          >
            {t("yourProfits")}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Graph;
