import { useContext, useEffect, useState } from "react";

import { LanguageStoreContext } from "./LanguageStore";

const DayOfMonth = ({ value }) => {
  const lang = useContext(LanguageStoreContext);

  const [suffix, setSuffix] = useState("");

  useEffect(() => {
    if (value === 1) {
      setSuffix(lang.lang === "en" ? "st" : "sten");
    } else {
      setSuffix(lang.lang === "en" ? "th" : "ten");
    }
  }, [lang, value]);

  return (
    <>
      {value}
      {suffix}
    </>
  );
};

export default DayOfMonth;
