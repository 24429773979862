import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { Card, Form } from "react-bootstrap";
import { useFormik } from "formik";
import mixpanel from "mixpanel-browser";

import { cachedVal, useSessionStorage } from "../../Common/CommonHooks";
import FormikRadioCard from "../../Common/FormikRadioCard";
import { useSageLazyQuery, useSageMutation } from "../../Common/GqlHooks";
import {
  OB_GET_MY_PRELIMINARY_DOCUMENTS,
  OB_SEND_ME_MY_CONTRACTS,
  OB_SIGN_MY_CONTRACTS,
} from "../../../api/graphql";
import ContentLoader from "../../Common/ContentLoader";
import { MixpanelEvents } from "../../../common/common";
import { OnboardingStoreContext } from "../OnboardingStore";

import StepButtons from "./StepButtons";
import StepHeader from "./StepHeader";
import StepContent from "./StepContent";
import Step from "./Step";

const TermsAndConditions = ({ step }) => {
  const { t } = useTranslation(["steps", "obdNav", "constants"]);
  const obStore = useContext(OnboardingStoreContext);
  const [sGet] = useSessionStorage("ob");

  const handlePrev = () => {
    obStore.back();
  };

  const handleNext = () => {
    formik.submitForm();
  };

  const [sendContracts] = useSageMutation(OB_SEND_ME_MY_CONTRACTS);

  const [getDocuments, loadingDocs, docs] = useSageLazyQuery(
    OB_GET_MY_PRELIMINARY_DOCUMENTS
  );

  useEffect(
    () => {
      getDocuments();
      sendContracts();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const documentLink = (type) =>
    docs?.getMyPreliminaryDocuments.find((x) => x.type === type).url;

  const validate = (values) => {
    const errors = {};

    if (!values.baader_terms) errors.baader_terms = true;
    if (!values.vermogen_terms) errors.vermogen_terms = true;
    if (!values.telephone_terms) errors.telephone_terms = true;
    if (!values.own_terms) errors.own_terms = true;
    return errors;
  };

  const [submit, loading] = useSageMutation(OB_SIGN_MY_CONTRACTS);

  const onSubmit = () => {
    submit()
      .then(() => {
        mixpanel.track(
          MixpanelEvents.onboarding.TERMS_AND_CONDITIONS_COMPLETED
        );
        obStore.refresh();
        obStore.next();
      })
      .catch(console.log);
  };

  const formik = useFormik({
    initialValues: {
      baader_terms: cachedVal(sGet, null, "baader_terms") === "yes",
      vermogen_terms: cachedVal(sGet, null, "vermogen_terms") === "yes",
      telephone_terms: cachedVal(sGet, null, "telephone_terms") === "yes",
      own_terms: cachedVal(sGet, null, "own_terms") === "yes",
    },
    validate,
    onSubmit,
  });

  const handleChange = (field) => () => {
    formik.setFieldValue(field, !formik.values[field]);
  };

  return (
    <Step variant="padding-bottom">
      <StepHeader title={t(`steps:${step.title}`)}>{t("tacSub")}</StepHeader>

      <ContentLoader loading={loadingDocs} isInline={false}>
        <StepContent>
          <h5>{t("pReadLabel")}</h5>

          <div className="px-1">
            <Form noValidate>
              <Form.Row className="align-items-end mt-4 mb-0">
                <Form.Group>
                  <FormikRadioCard
                    field="baader_terms"
                    formik={formik}
                    value="yes"
                    checked={formik.values.baader_terms}
                    id="baad"
                    type="checkbox"
                    onChange={handleChange("baader_terms")}
                  >
                    <Card.Text>
                      {t("baaderTerms")}
                      <a
                        rel="noreferrer"
                        href={documentLink("PRELIMINARY_CUSTODIAN_CONTRACT")}
                        target="_blank"
                        className="inline text-primary"
                      >
                        {t("baaderTermsBtn")}
                      </a>
                      {t("baaderTermsSuff")}
                    </Card.Text>
                  </FormikRadioCard>
                </Form.Group>
              </Form.Row>
              <Form.Row className="align-items-end mt-2 mb-0">
                <Form.Group>
                  <FormikRadioCard
                    field="vermogen_terms"
                    formik={formik}
                    value="yes"
                    checked={formik.values.vermogen_terms}
                    id="verm"
                    type="checkbox"
                    onChange={handleChange("vermogen_terms")}
                  >
                    <Card.Text>
                      {t("vermogenTerms")}
                      <a
                        rel="noreferrer"
                        href={documentLink("PRELIMINARY_SUITABILITY_DOCUMENT")}
                        target="_blank"
                        className="inline text-primary"
                      >
                        {t("vermogenTermsBtn1")}
                      </a>
                      ,{" "}
                      <a
                        rel="noreferrer"
                        href={documentLink(
                          "PRELIMINARY_LIABILITY_PARTNER_CONTRACT"
                        )}
                        target="_blank"
                        className="inline text-primary"
                      >
                        {t("vermogenTermsBtn2")}
                      </a>
                      {t("vermogenTermsAnd")}
                      <a
                        rel="noreferrer"
                        href={documentLink(
                          "PRELIMINARY_LIABILITY_PARTNER_CLIENT_INFO"
                        )}
                        target="_blank"
                        className="inline text-primary"
                      >
                        {t("vermogenTermsBtn3")}
                      </a>
                      {t("vermogenTermsSuff")}
                    </Card.Text>
                  </FormikRadioCard>
                </Form.Group>
              </Form.Row>
              <Form.Row className="align-items-end mt-2 mb-0">
                <Form.Group>
                  <FormikRadioCard
                    field="telephone_terms"
                    formik={formik}
                    value="yes"
                    checked={formik.values.telephone_terms}
                    id="tel"
                    type="checkbox"
                    onChange={handleChange("telephone_terms")}
                  >
                    <Card.Text>{t("telephoneTerms")}</Card.Text>
                  </FormikRadioCard>
                </Form.Group>
              </Form.Row>

              <Form.Row className="mt-2 mb-0 d-flex">
                <Form.Group className="w-100">
                  <FormikRadioCard
                    field="own_terms"
                    formik={formik}
                    value="yes"
                    checked={formik.values.own_terms}
                    id="own"
                    type="checkbox"
                    onChange={handleChange("own_terms")}
                  >
                    <Card.Text>{t("ownTerms")}</Card.Text>
                    <Card.Text>{t("ownTermsSub")}</Card.Text>
                  </FormikRadioCard>
                </Form.Group>
              </Form.Row>
            </Form>
          </div>

          <StepButtons
            onPrev={handlePrev}
            onNext={handleNext}
            nextLabel={t("acceptAndSubmit")}
            canContinue={formik.isValid}
          />

          {/* <ContentLoader loading={loading} isInline={false} /> */}
        </StepContent>
      </ContentLoader>
      <ContentLoader loading={loading} isInline={false} />
    </Step>
  );
};

export default TermsAndConditions;
