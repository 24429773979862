/* eslint-disable */

export default {
  intro: "Introduction",
  pInfo: "Personal Information",
  finSit: "Financial Situation",
  invAmount: "Investment Amount",
  invHor: "Investment Horizon",
  yInv: "Your Investment",
  costs: "Costs",
  conBankAcc: "Connect Your Bank Account",
  TAC: "Terms & Conditions",
  vInd: "Verify Identity",
  checkIbx: "We’ve sent you an email!",
  bfPrcd: "Please click the link in the email to confirm your address.",
  inCase: "And don’t forget to check your spam folder ;)",
  notRec: "Click here if our email hasn’t arrived",
  verifyEmailResent: "Verification email has been resent",
  mobileObTitle: "Mobile onboarding is still under construction",
  mobileObLineOne:
    "We have sent you an email to verify your address, but please open it on your desktop computer.",
  mobileObLineTwo:
    "Once your account is opened, you can use your mobile to login and track your investments.",
  mobileObLineThree: "Thanks for your understanding.",
};
