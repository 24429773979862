import DataTable from "react-data-table-component";
import * as moment from "moment";
import { Button, ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useEffect, useState } from "react";

import { ClientStatus } from "../../../common/client-status";
import Money from "../../../components/Common/Money";
import { Unit } from "../../../common/money";
import ContentLoader from "../../../components/Common/ContentLoader";
import { useBOfficeGet, useBOfficePost } from "../BackofficeHooks";

const Clients = () => {
  const [status, setStatus] = useState("ALL");
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);

  const columns = [
    {
      name: "ID",
      selector: "id",
      cell: (row) => <small>{row.id}</small>,
    },
    {
      name: "BB ID",
      selector: "bbId",
      wrap: true,
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      wrap: true,
      sortable: true,
    },
    {
      name: "First Name",
      selector: "firstName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastName",
      wrap: true,
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      wrap: true,
      sortable: true,
    },
    {
      name: "Cash Balance",
      selector: "cashBalance",
      right: true,
      sortable: true,
      wrap: true,
      format: (row) => (
        <Money
          value={{
            currency: "EUR",
            amount: row.cashBalance,
            unit: Unit.CENT,
          }}
        />
      ),
    },
    {
      name: "Next Order Sch.",
      selector: "nextOrderSchDate",
      right: true,
      wrap: true,
      sortable: true,
      format: (row) => <ADate value={row.nextOrderSchDate} />,
    },
    {
      name: "Next Cash Tx. Sch.",
      selector: "nextCashTxSchDate",
      right: true,
      wrap: true,
      sortable: true,
      format: (row) => <ADate value={row.nextCashTxSchDate} />,
    },
    {
      name: "Acc Created On",
      selector: "accCreationDate",
      sortable: true,
      right: true,
      wrap: true,
      format: (row) => <ADate value={row.accCreationDate} />,
    },
    {
      name: "",
      selector: "id",
      cell: (row) => <RowActions clientId={row.id} onAction={handleAction} />,
    },
  ];

  const [clients, loading] = useBOfficeGet();
  const [approve] = useBOfficePost();

  const loadClients = () => {
    return clients("/backoffice/clients").then((resp) => {
      if (!resp) return;

      setInitialData(resp.data.clients);
      setData(resp.data.clients);
    });
  };

  const handleAction = (action, params) => {
    approve(`/backoffice/client/${params}/approve`)
      .then((resp) => {
        if (!resp) {
          alert("An error occurred");
          return;
        }

        alert(resp.data.status);

        loadClients().then(() => handleStatusFilter(status));
      })
      .catch(() => alert("An error occurred"));
  };

  const handleStatusFilter = (filter) => {
    setStatus(filter);

    if (filter === "ALL") {
      setData(initialData);
      return;
    }

    setData(initialData.filter((x) => x.status === filter));
  };

  const handleRowClick = (c) => {
    window.open(`/backoffice/client/${c.id}`, "_blank");
  };

  useEffect(() => {
    loadClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentLoader loading={loading}>
        <DataTable
          striped
          highlightOnHover
          pointerOnHover
          defaultSortField="accCreationDate"
          defaultSortAsc={false}
          title="Clients"
          columns={columns}
          actions={
            <StatusFilter active={status} onFilter={handleStatusFilter} />
          }
          data={data}
          onRowClicked={handleRowClick}
          responsive
        />
      </ContentLoader>
    </>
  );
};

const RowActions = ({ clientId, onAction }) => {
  return (
    <DropdownButton size="sm" id="dropdown-basic-button" title="">
      <Dropdown.Item onClick={() => onAction("approve", clientId)}>
        Approve
      </Dropdown.Item>
    </DropdownButton>
  );
};

const StatusFilter = ({ active, onFilter }) => {
  const activeVariant = (status) => (status === active ? "primary" : "light");

  return (
    <div className="d-flex flex-column my-1">
      <ButtonGroup aria-label="Status Filter" size="sm">
        {[
          "ALL",
          ClientStatus.ONBOARDING,
          ClientStatus.APPROVAL_PENDING,
          ClientStatus.APPROVED,
          ClientStatus.ONBOARDED,
          ClientStatus.REJECTED,
        ].map((s, i) => (
          <Button
            key={i}
            onClick={() => onFilter(s)}
            variant={activeVariant(s)}
          >
            {s}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

const ADate = ({ value }) => {
  if (!value) return <span>---</span>;
  return <span>{moment(value).format("DD.MM.YYYY")}</span>;
};

export default Clients;
