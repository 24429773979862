import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";

import { ClientStatus } from "../../common/client-status";
import Header from "../Common/Header";
import { flattenObject } from "../../common/common";
import usePendoOnboarding from "../../hooks/usePendoOnbaording";

import Navigation from "./Navigation";
import Steps from "./Steps/Steps";
import Sidebar from "./Sidebar";
import Introduction from "./Steps/Introduction";
import NavigationFooter from "./NavigationFooter";
import PersonalInformation from "./Steps/PersonalInformation";
import { OnboardingStoreContext } from "./OnboardingStore";
import HomeAndTaxesInformation from "./Steps/HomeAndTaxesInformation";
import YourFinancialSituation from "./Steps/YourFinancialSituation";
import InvestmentAmountLayout from "./Steps/InvestmentAmountLayout";
import UnderstandingYourInvestment from "./Steps/UnderstandingYourInvestment";
import Costs from "./Steps/Costs";
import Checkout from "./Steps/Checkout";
import TermsAndConditions from "./Steps/TermsAndConditions";
import IdentityVerification from "./Steps/IdentityVerification";

import "./Onboarding.scss";

const Onboarding = () => {
  const state = useContext(OnboardingStoreContext);
  const [loaded, setLoaded] = useState(false);

  usePendoOnboarding();

  useEffect(() => {
    const determineProgress = () => {
      if (state.me.client.proof_of_address_url) {
        state.setCurrentStep(10);
      } else if (
        state.me.client.reference_bank_account &&
        state.me.client.investment_plan &&
        state.me.client.investment_plan.initial_investment.amount > 0
      ) {
        state.setCurrentStep(9);
      } else if (
        state.me.client.investment_plan &&
        state.me.client.investment_plan.initial_investment.amount > 0
      ) {
        state.setCurrentStep(6);
      } else if (state.me.client.financial_situation) {
        state.setCurrentStep(5);
      } else if (state.me.client.tax_information) {
        state.setCurrentStep(4);
      } else if (state.me.client.first_name) {
        state.setCurrentStep(3);
      }
      setLoaded(true);
    };

    if (!state.me) return;

    mixpanel.identify(state.me.id);
    mixpanel.people.set(flattenObject(state.me.client));

    if (state.me.client.status === ClientStatus.ONBOARDED) {
      window.location.href = "/dashboard";
      return;
    }

    if (!loaded) {
      determineProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.me]);

  return (
    <>
      {loaded && (
        <Container fluid id="ob-main-wrapper" className="p-0">
          <Row className="h-100">
            <Col className="h-100 d-flex ob-left-nav-cont pr-0">
              <Sidebar>
                <Navigation>
                  <NavigationFooter />
                </Navigation>
              </Sidebar>
            </Col>
            <Col
              xs={12}
              lg={8}
              xl={6}
              className="ob-wrapper h-100 pt-4 pt-lg-0"
            >
              <Header variant="d-lg-none" linkTo="/onboarding">
                <Navigation variant="mt-1">
                  <NavigationFooter />
                </Navigation>
              </Header>
              <ProgressBar
                now={state.currentStep}
                max={10}
                srOnly
                variant="success"
                className="d-lg-none no-border position-fixed"
                style={{
                  top: "40px",
                  height: "4px",
                  left: "0",
                  right: "0",
                  zIndex: "1029",
                }}
              />

              <Steps>
                <Introduction />
                <PersonalInformation />
                <HomeAndTaxesInformation />
                <YourFinancialSituation />
                <InvestmentAmountLayout />
                <UnderstandingYourInvestment />
                <Costs />
                <Checkout />
                <TermsAndConditions />
                <IdentityVerification />
              </Steps>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Onboarding;
